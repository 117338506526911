/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import {
  Col, Button, Table, Input, Row, Form, InputNumber, notification, Checkbox, Upload, Drawer, Space, Divider, Popconfirm, Spin,
} from 'antd';
import {
  EditOutlined, DeleteOutlined, DownloadOutlined, InboxOutlined, LoadingOutlined,
} from '@ant-design/icons';
import {
  uploadFile, editApplicationStepReport, editApplicationCostsDocument, editApplicationCosts,
} from '../../../../../../../utils/http';
import getMaterialsColumns from './getMaterialsColumns';
import MaterialsContract from './MaterialsContract';
import MaterialsAct from './MaterialsAct';
import MaterialsYPD from './MaterialsYPD';
import MaterialsTN from './MaterialsTN';
import MaterialsSF from './MaterialsSF';
import MaterialsPP from './MaterialsPP';
import MaterialsDS from './MaterialsDS';
import calculateTotal from '../../../../../../../utils/calculateTotal';
import { downloadFile } from '../../../../../../../utils/downloadFile';
import MaterialsTovN from './MaterialsTovN';


const { Dragger } = Upload;
class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectInEdit: null,
      dataSource: this.props.dataSource,
      loading: false,
      materialContractInEdit: null,
      materialActInEdit: null,
      materialYPDInEdit: null,
      materialTovNInEdit: null,
      materialTNInEdit: null,
      materialSFInEdit: null,
      materialPPInEdit: null,
      materialDSInEdit: null,
      checkboxValue: this.props.checkboxValue,
      financialPaperPolicyFile: this.props.financialPaperPolicyFile,
      financialPaperAccountFile: this.props.financialPaperAccountFile,
      deliveryBill: null,
    };
    this.formRef = React.createRef();
  }

  onAddClick = () => {
    this.setState({ objectInEdit: {} });
  }

  onEditClick = (objectInEdit) => {
    this.setState({ objectInEdit });
  }

  onCloseDrawer = () => {
    this.setState({ objectInEdit: null });
  }

  onDeleteClick = (object) => {
    this.setState({ loading: true });
    editApplicationCosts({ id: object.id, isDeleted: true })
      .then(() => this.setState((prevState) => ({
        loading: false,
        dataSource: prevState.dataSource.filter((d) => d.id !== object.id),
      })));
  }

  getTotalRow = (dataSource) => {
    const { isPaper } = this.props;
    const fields = ['actualCosts', 'paperCosts', 'deviations'];
    if (isPaper) {
      fields.push('grantSum');
    }
    const obj = {
      id: 'isTotal',
      isTotal: true,
      name: 'ОБЩИЙ ИТОГ',
      numberOfPages: 0,
    };
    dataSource.forEach((row) => {
      if (row.materialContract) {
        obj.numberOfPages += row.materialContract.pages;
      }
      if (row.materialPP && row.materialPP.length > 0) {
        obj.numberOfPages += row.materialPP.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialDS && row.materialDS.length > 0) {
        obj.numberOfPages += row.materialDS.reduce((a, c) => a + c.pages, 0);
      }
      if (row.materialAct) {
        obj.numberOfPages += row.materialAct.pages;
      }
      if (row.materialYPD) {
        obj.numberOfPages += row.materialYPD.pages;
      }
      if (row.materialTN) {
        obj.numberOfPages += row.materialTN.pages;
      }
      if (row.materialTovN) {
        obj.numberOfPages += row.materialTovN.pages;
      }
      if (row.materialSF) {
        obj.numberOfPages += row.materialSF.pages;
      }
    });
    fields.forEach((f) => {
      const sumArray = dataSource.map((c) => ({ major: c[`${f}Major`], minor: c[`${f}Minor`] }));
      const total = calculateTotal(sumArray);

      obj[`${f}Major`] = total.major;
      obj[`${f}Minor`] = total.minor;
    });
    return obj;
  }

  onRemoveFile = (filedWithObjectInState) => {
    this.setState((prevState) => ({
      [filedWithObjectInState]: { ...prevState[filedWithObjectInState], file: null },
    }));
  }

  onUploadFile = (info, filedWithObjectInState) => {
    if (info.file.status === 'uploading' && !info.event) {
      if (info.file.type !== 'application/pdf') {
        notification.error({ message: 'Ошибка! Загрузите, пожалуйста, файл в формате PDF' });
        return;
      }
      this.setState({ loading: true });
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => this.setState((prevState) => ({
          loading: true,
          [filedWithObjectInState]: { ...prevState[filedWithObjectInState], file },
        })));
    }
  }

  onChangeCheckBox = (e) => {
    const { checkboxName, applicationId } = this.props;
    this.setState({ loading: true });
    editApplicationStepReport({ id: applicationId, [checkboxName]: e.target.checked })
      .then(() => {
        this.setState({ checkboxValue: e.target.checked, loading: false });
        notification.success({ message: 'Сохранено' });
      });
  }

  onChangeExcludingVAT = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        excludingVAT: e.target.checked,
      },
    }));
  }

  onChangeisNotDeliveryBill = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNotDeliveryBill: e.target.checked,
      },
    }));
  }

  onSaveModal = (filedWithObjectInState) => {
    const object = this.state[filedWithObjectInState];

    object.formRef.current?.validateFields()
      .then((values) => {
        const { file } = object;
        console.log(object);
        if (!file) {
          notification.error({ message: 'Не сохранено! Загрузите файл!' });
        }
        this.setState({ loading: true });
        const fieldName = filedWithObjectInState.split('InEdit')[0];
        editApplicationCostsDocument({
          ...values,
          file: file.id,
        })
          .then((id) => {
            notification.success({ message: 'Сохранено!' });
            this.setState((prevState) => ({
              loading: false,
              [filedWithObjectInState]: null,
              objectInEdit: {
                ...prevState.objectInEdit,
                [fieldName]: fieldName === 'materialPP' || fieldName === 'materialDS'
                  ? values.id
                    ? (prevState.objectInEdit[fieldName] || []).map((o) => (o.id === values.id ? { ...values, file, id } : o))
                    : [...(prevState.objectInEdit[fieldName] || []), { ...values, file, id }]
                  : { ...values, file, id },
              },
            }));
          });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: 'Не сохранено! Заполните все обязательные поля!' });
      });
  }

  onDeleteDocument = (fieldName, id) => {
    let filteredObjects = [];
    if (fieldName === 'materialPP' || fieldName === 'materialDS') {
      const grouped = groupBy(this.state.objectInEdit[fieldName], 'id');
      const groupedKeys = Object.keys(grouped);
      groupedKeys.forEach((key) => {
        if ((key === id && grouped[key].length > 1) || key !== id) {
          filteredObjects.push(grouped[key][0]);
        }
      });
    } else {
      filteredObjects = null;
    }
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        [fieldName]: filteredObjects,
      },
    }));
  }

  onChangeIsNoAdditionalAgreement = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isNoAdditionalAgreement: e.target.checked,
      },
    }));
  }

  onChangeIsDeliveryBill = (e) => {
    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        isDeliveryBill: e.target.checked,
      },
    }));
  }

  onSaveDrawer = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const {
          isMaterial, isEquipment, isOther, isPaper, applicationId,
        } = this.props;
        const { objectInEdit } = this.state;
        const {
          materialContract, materialPP, materialDS, materialAct, materialYPD, materialTN, materialTovN, materialSF, isNoAdditionalAgreement, isDeliveryBill, deliveryBill, isNotDeliveryBill,
          excludingVAT,
        } = objectInEdit;

        let error = false;

        if (!materialContract) {
          notification.error({ message: 'Не сохранено! Заполните карточку Договор!' });
          error = true;
        }

        if (!isNoAdditionalAgreement && (!materialDS || materialDS.length === 0)) {
          notification.error({ message: 'Не сохранено! Заполните карточку Дополнительное соглашение!' });
          error = true;
        }

        if (isNoAdditionalAgreement && materialDS?.length > 0) {
          notification.error({ message: 'При отмеченном пункте "Дополнительное соглашение не заключалось" не должно быть загружено Дополнительное соглашение' });
          error = true;
        }

        if (materialPP.length === 0) {
          notification.error({ message: 'Не сохранено! Заполните карточку Платежное поручение!' });
          error = true;
        }

        if (!isDeliveryBill && !materialYPD) {
          notification.error({ message: 'Не сохранено! Заполните карточку УПД!' });
          error = true;
        }

        if (!(materialAct)) {
          notification.error({ message: 'Не сохранено! Заполните карточки акта!' });
          error = true;
        }

        if (!materialTN && !isNotDeliveryBill) {
          notification.error({ message: 'Не сохранено! Заполните карточки транспортной накладной!' });
          error = true;
        }

        if (isDeliveryBill && !materialTovN) {
          notification.error({ message: 'Не сохранено! Заполните карточки товарной накладной!' });
          error = true;
        }

        if (isDeliveryBill && !materialSF && !excludingVAT) {
          notification.error({ message: 'Не сохранено! Заполните карточки счет-фактуры!' });
          error = true;
        }

        if (error) {
          return;
        }


        this.setState({ loading: true });
        editApplicationCosts({
          ...values,
          applicationStepReport_id: applicationId,
          isMaterial,
          isEquipment,
          isOther,
          isPaper,
          materialContract: materialContract.id,
          materialPP: materialPP ? materialPP.map((o) => o.id).join(',') : '',
          materialDS: materialDS ? materialDS.map((o) => o.id).join(',') : '',
          materialAct: materialAct ? materialAct.id : null,
          materialYPD: materialYPD ? materialYPD.id : null,
          materialTN: materialTN ? materialTN.id : null,
          materialSF: materialSF ? materialSF.id : null,
          isNoAdditionalAgreement,
          isDeliveryBill,
          deliveryBill,
          isNotDeliveryBill,
          excludingVAT,
        }).then((id) => {
          const editApplicationCostsDocuments = [{ id: materialContract.id }, ...materialPP.map((p) => ({ id: p.id }))];
          if (materialDS && materialDS.length > 0) {
            editApplicationCostsDocuments.push(...materialDS.map((p) => ({ id: p.id })));
          }
          if (materialAct) {
            editApplicationCostsDocuments.push({ id: materialAct.id });
          }
          if (materialYPD) {
            editApplicationCostsDocuments.push({ id: materialYPD.id });
          }
          if (materialTN) {
            editApplicationCostsDocuments.push({ id: materialTN.id });
          }
          if (materialSF) {
            editApplicationCostsDocuments.push({ id: materialSF.id });
          }
          editApplicationCostsDocument(editApplicationCostsDocuments.map((o) => ({ id: o.id, applicationCost_id: id })))
            .then(() => {
              this.setState((prevState) => ({
                objectInEdit: null,
                loading: false,
                dataSource: values.id
                  ? prevState.dataSource.map((d) => (d.id === values.id ? {
                    ...values,
                    applicationStepReport_id: applicationId,
                    isMaterial,
                    isEquipment,
                    isOther,
                    isPaper,
                    materialContract,
                    materialPP,
                    materialDS,
                    materialAct,
                    materialYPD,
                    materialTN,
                    materialTovN,
                    materialSF,
                    isNoAdditionalAgreement,
                    isDeliveryBill,
                    deliveryBill,
                    isNotDeliveryBill,
                    excludingVAT,
                  } : d))
                  : [...prevState.dataSource, {
                    ...values,
                    applicationStepReport_id: applicationId,
                    isMaterial,
                    isEquipment,
                    isOther,
                    isPaper,
                    materialContract,
                    materialPP,
                    materialDS,
                    materialAct,
                    materialYPD,
                    materialTN,
                    materialTovN,
                    materialSF,
                    isNoAdditionalAgreement,
                    isDeliveryBill,
                    id,
                    deliveryBill,
                    isNotDeliveryBill,
                    excludingVAT,
                  }],
              }));
              notification.success({ message: 'Сохранено!' });
            });
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: 'Не сохранено! Заполните все обязательные поля!' });
      });
  }

  onUploadMainFile = (info, fieldName) => {
    this.setState({ loading: true });
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          const { applicationId } = this.props;
          editApplicationStepReport({ id: applicationId, [fieldName]: file.id })
            .then(() => {
              this.setState({ [fieldName]: file, loading: false });
              notification.success({ message: 'Сохранено!' });
            });
        });
    }
  }

  onRemoveMainFile = (fieldName) => {
    const { applicationId } = this.props;
    editApplicationStepReport({ id: applicationId, [fieldName]: null })
      .then(() => {
        this.setState({ [fieldName]: null });
        notification.success({ message: 'Сохранено!' });
      });
  }

  onInputChange = () => {
    const {
      actualCostsMajor, actualCostsMinor, paperCostsMajor, paperCostsMinor,
    } = this.formRef.current.getFieldsValue();

    if (!actualCostsMajor || !actualCostsMinor || !paperCostsMajor || !paperCostsMinor) {
      return;
    }

    const floatActual = parseFloat(`${actualCostsMajor}.${actualCostsMinor}`);
    const floatPaper = parseFloat(`${paperCostsMajor}.${paperCostsMinor}`);

    const diff = floatActual - floatPaper;
    const major = diff.toFixed(2).split('.')[0];
    const minor = diff.toFixed(2).split('.')[1];

    this.formRef.current.setFieldsValue({
      deviationsMajor: parseInt(major, 10),
      deviationsMinor: parseInt(minor, 10),
    });

    this.setState((prevState) => ({
      objectInEdit: {
        ...prevState.objectInEdit,
        deviationsMajor: parseInt(major, 10),
        deviationsMinor: parseInt(minor, 10),
      },
    }));
  }

  render() {
    const {
      dataSource, objectInEdit, loading, materialContractInEdit, materialActInEdit, materialYPDInEdit, materialTNInEdit, materialSFInEdit, materialPPInEdit,
      materialDSInEdit, checkboxValue, financialPaperPolicyFile, financialPaperAccountFile, materialTovNInEdit,
    } = this.state;
    const { title, isPaper, isFormDisabled } = this.props;
    return (
      <Col>
        {loading && (
        <Spin
          style={{ position: 'fixed', fontSize: '60px', top: '30%' }}
          indicator={(<LoadingOutlined style={{ fontSize: '100px', margin: '-80px 0px 0px -60px' }} spin />)}
          tip="Загрузка..."
        >
          <div />
        </Spin>
        )}
        <Table
          dataSource={[...dataSource, { id: 'add', isAdd: true }, this.getTotalRow(dataSource)]}
          columns={getMaterialsColumns(this.onEditClick, this.onDeleteClick, this.onAddClick, isPaper, checkboxValue || isFormDisabled || loading)}
          pagination={false}
          rowKey="id"
          bordered
        />
        <Checkbox defaultChecked={checkboxValue} disabled={isFormDisabled || loading} style={{ marginTop: '5px', color: '#1890ff' }} onChange={this.onChangeCheckBox}>{title}</Checkbox>
        {isPaper && (
        <Col style={{ marginTop: '20px' }}>
          <span style={{ color: '#212121' }}>
            Прикрепите пожалуйста скан-копию в формате pdf выписку из учетной политики медицинского учреждения об утвержденном методе распределения указанных расходов между видами деятельности
          </span>
          {!financialPaperPolicyFile && (
          <Dragger
            showUploadList={false}
            onChange={(f) => this.onUploadMainFile(f, 'financialPaperPolicyFile')}
            disabled={loading || isFormDisabled}
            multiple
            action={null}
            style={{ width: '100%' }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {financialPaperPolicyFile && (
          <Row style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(financialPaperPolicyFile.urlLink, financialPaperPolicyFile.name)} /></Col>
            {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveMainFile('financialPaperPolicyFile')} /></Col>}
            <Col>{financialPaperPolicyFile.name}</Col>
          </Row>
          )}
        </Col>
        )}
        {isPaper && (
        <Col style={{ marginTop: '20px' }}>
          <span style={{ color: '#212121' }}>
            Прикрепите пожалуйста скан-копию в формате pdf справки бухгалтера о распределении накладных и общехозяйственных расходов между источниками финансирования за отчетный период
          </span>
          {!financialPaperAccountFile && (
          <Dragger
            showUploadList={false}
            onChange={(f) => this.onUploadMainFile(f, 'financialPaperAccountFile')}
            disabled={loading || isFormDisabled}
            multiple
            action={null}
            style={{ width: '100%' }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          )}
          {financialPaperAccountFile && (
          <Row style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(financialPaperAccountFile.urlLink, financialPaperAccountFile.name)} /></Col>
            {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveMainFile('financialPaperAccountFile')} /></Col>}
            <Col>{financialPaperAccountFile.name}</Col>
          </Row>
          )}
        </Col>
        )}
        {objectInEdit && (
        <Drawer
          title="Редактирование расхода"
          width="75vw"
          visible
          onClose={this.onCloseDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={(
            <Space>
              <Button onClick={this.onCloseDrawer}>Отменить</Button>
              <Button onClick={this.onSaveDrawer} type="primary">Сохранить</Button>
            </Space>
                )}
        >
          <Form
            initialValues={{ remember: true }}
            ref={this.formRef}
          >
            <Col>
              <Form.Item
                name="id"
                hidden
                initialValue={objectInEdit && objectInEdit.id}
              />
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Предмет договора:</span>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                  initialValue={objectInEdit && objectInEdit.name}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Фактические расходы:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="actualCostsMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.actualCostsMajor}
                    >
                      <InputNumber onChange={this.onInputChange} style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="actualCostsMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.actualCostsMinor}
                    >
                      <InputNumber onChange={this.onInputChange} />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Кассовые расходы:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="paperCostsMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.paperCostsMajor}
                    >
                      <InputNumber onChange={this.onInputChange} style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="paperCostsMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.paperCostsMinor}
                    >
                      <InputNumber onChange={this.onInputChange} />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">Отклонение:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="deviationsMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.deviationsMajor}
                    >
                      <InputNumber value={objectInEdit?.deviationsMajor} style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="deviationsMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.deviationsMinor}
                      value={objectInEdit?.deviationsMinor}
                    >
                      <InputNumber value={objectInEdit?.deviationsMinor} />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              {isPaper && (
              <div className="application-main-box-content-wrapper">
                <span className="application-main-box-label">За счет средств гранта, выделенных на выполнение задания:</span>
                <Row align="bottom" gutter={8}>
                  <Col>
                    <Form.Item
                      name="grantSumMajor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.grantSumMajor}
                    >
                      <InputNumber style={{ width: '200px' }} />
                    </Form.Item>
                  </Col>
                  <Col>руб.</Col>
                  <Col>
                    <Form.Item
                      name="grantSumMinor"
                      rules={[{ required: true, message: 'Обязательное поле' }]}
                      initialValue={objectInEdit && objectInEdit.grantSumMinor}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col>коп.</Col>
                </Row>
              </div>
              )}
              <Divider />
              <div style={{ color: '#212121', fontSize: '18px', marginLeft: '20px' }}>Документы подтверждающие расходы</div>
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Договор:</div>
                {objectInEdit && !objectInEdit.materialContract && <Button type="primary" onClick={() => this.setState({ materialContractInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                {objectInEdit && objectInEdit.materialContract && (
                <Row gutter={5}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialContractInEdit: { ...objectInEdit.materialContract, formRef: React.createRef() } })}><EditOutlined /></Col>
                  <Col style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      onConfirm={() => this.onDeleteDocument('materialContract')}
                      okText="Да, удалить"
                      cancelText="Нет"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Col>
                  <Col>{`Договор ${objectInEdit.materialContract.name}`}</Col>
                </Row>
                )}
              </Col>
              <Col style={{ padding: '15px 20px' }} span={24}>
                <Row><div style={{ color: '#212121' }}>Дополнительное соглашение:</div></Row>
                <Row><Checkbox defaultChecked={objectInEdit.isNoAdditionalAgreement} value={objectInEdit.isNoAdditionalAgreement} onChange={this.onChangeIsNoAdditionalAgreement}>Дополнительное соглашение не заключалось</Checkbox></Row>
                {objectInEdit && !objectInEdit.isNoAdditionalAgreement && <Button type="primary" onClick={() => this.setState({ materialDSInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                {objectInEdit && objectInEdit.materialDS && objectInEdit.materialDS.map((m) => (
                  <Row gutter={5}>
                    <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialDSInEdit: { ...m, formRef: React.createRef() } })}><EditOutlined /></Col>
                    <Col style={{ cursor: 'pointer' }}>
                      <Popconfirm
                        title="Вы действительно хотите удалить?"
                        onConfirm={() => this.onDeleteDocument('materialDS', m.id)}
                        okText="Да, удалить"
                        cancelText="Нет"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </Col>
                    <Col>{`Дополнительное соглашение ${m.name}`}</Col>
                  </Row>
                ))}
              </Col>
              <Divider />
              <div style={{ color: '#212121', fontSize: '18px', marginLeft: '20px' }}>Закрывающие документы</div>
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Платежное поручение:</div>
                {objectInEdit && <Button type="primary" onClick={() => this.setState({ materialPPInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                {objectInEdit && objectInEdit.materialPP && objectInEdit.materialPP.map((m) => (
                  <Row gutter={5}>
                    <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialPPInEdit: { ...m, formRef: React.createRef() } })}><EditOutlined /></Col>
                    <Col style={{ cursor: 'pointer' }}>
                      <Popconfirm
                        title="Вы действительно хотите удалить?"
                        onConfirm={() => this.onDeleteDocument('materialPP', m.id)}
                        okText="Да, удалить"
                        cancelText="Нет"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </Col>
                    <Col>{`Платежное поручение ${m.name}`}</Col>
                  </Row>
                ))}
              </Col>
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Акт:</div>
                {objectInEdit && !objectInEdit.materialAct && <Button type="primary" onClick={() => this.setState({ materialActInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                {objectInEdit && objectInEdit.materialAct && (
                <Row gutter={5}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialActInEdit: { ...objectInEdit.materialAct, formRef: React.createRef() } })}><EditOutlined /></Col>
                  <Col style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      onConfirm={() => this.onDeleteDocument('materialAct')}
                      okText="Да, удалить"
                      cancelText="Нет"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Col>
                  <Col>{`Акт ${objectInEdit.materialAct.name}`}</Col>
                </Row>
                )}

              </Col>
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>УПД:</div>
                {objectInEdit && !objectInEdit.materialYPD && !objectInEdit.isDeliveryBill && <Button type="primary" onClick={() => this.setState({ materialYPDInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                <Row><Checkbox defaultChecked={objectInEdit.isDeliveryBill} value={objectInEdit.isDeliveryBill} onChange={this.onChangeIsDeliveryBill}>Предусмотрена товарная накладная</Checkbox></Row>
                {objectInEdit && objectInEdit.materialYPD && (
                <Row gutter={5}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialYPDInEdit: { ...objectInEdit.materialYPD, formRef: React.createRef() } })}><EditOutlined /></Col>
                  <Col style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      onConfirm={() => this.onDeleteDocument('materialYPD')}
                      okText="Да, удалить"
                      cancelText="Нет"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Col>
                  <Col>{`УПД ${objectInEdit.materialYPD.name}`}</Col>
                </Row>
                )}
              </Col>

              {objectInEdit && objectInEdit.isDeliveryBill && (
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Счет-фактура:</div>
                {objectInEdit && !objectInEdit.materialSF && !objectInEdit.excludingVAT && <Button type="primary" onClick={() => this.setState({ materialSFInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                <Row><Checkbox defaultChecked={objectInEdit.excludingVAT} value={objectInEdit.excludingVAT} onChange={this.onChangeExcludingVAT}>Не облагается НДС</Checkbox></Row>
                {objectInEdit && objectInEdit.materialSF && (
                <Row gutter={5}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialSFInEdit: { ...objectInEdit.materialSF, formRef: React.createRef() } })}><EditOutlined /></Col>
                  <Col style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      onConfirm={() => this.onDeleteDocument('materialSF')}
                      okText="Да, удалить"
                      cancelText="Нет"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Col>
                  <Col>{`СФ ${objectInEdit.materialSF.name}`}</Col>
                </Row>
                )}
              </Col>
              )}

              {objectInEdit && objectInEdit.isDeliveryBill && (
              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Товарная накладная:</div>
                {objectInEdit && !objectInEdit.materialTovN && <Button type="primary" onClick={() => this.setState({ materialTovNInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                {objectInEdit && objectInEdit.materialTovN && (
                <Row gutter={5}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialTovNInEdit: { ...objectInEdit.materialTovN, formRef: React.createRef() } })}><EditOutlined /></Col>
                  <Col style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      onConfirm={() => this.onDeleteDocument('materialTovN')}
                      okText="Да, удалить"
                      cancelText="Нет"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Col>
                  <Col>{`СФ ${objectInEdit.materialTovN?.name}`}</Col>
                </Row>
                )}
              </Col>
              )}

              <Col style={{ padding: '15px 20px' }}>
                <div style={{ color: '#212121' }}>Транспортная накладная:</div>
                {objectInEdit && !objectInEdit.materialTN && !objectInEdit.isNotDeliveryBill && <Button type="primary" onClick={() => this.setState({ materialTNInEdit: { formRef: React.createRef() } })}>Добавить</Button>}
                <Row><Checkbox defaultChecked={objectInEdit.isNotDeliveryBill} value={objectInEdit.isNotDeliveryBill} onChange={this.onChangeisNotDeliveryBill}>Не предусмотрено Договором</Checkbox></Row>
                {objectInEdit && objectInEdit.materialTN && (
                <Row gutter={5}>
                  <Col style={{ cursor: 'pointer' }} onClick={() => this.setState({ materialTNInEdit: { ...objectInEdit.materialTN, formRef: React.createRef() } })}><EditOutlined /></Col>
                  <Col style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      onConfirm={() => this.onDeleteDocument('materialTN')}
                      okText="Да, удалить"
                      cancelText="Нет"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Col>
                  <Col>{`ТН ${objectInEdit.materialTN.name}`}</Col>
                </Row>
                )}
              </Col>
            </Col>
          </Form>
        </Drawer>
        )}
        {materialContractInEdit
        && (
        <MaterialsContract
          object={materialContractInEdit}
          onClose={() => this.setState({ materialContractInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialActInEdit
        && (
        <MaterialsAct
          object={materialActInEdit}
          onClose={() => this.setState({ materialActInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialYPDInEdit
        && (
        <MaterialsYPD
          object={materialYPDInEdit}
          onClose={() => this.setState({ materialYPDInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialTNInEdit
        && (
        <MaterialsTN
          object={materialTNInEdit}
          onClose={() => this.setState({ materialTNInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialTovNInEdit
        && (
        <MaterialsTovN
          object={materialTovNInEdit}
          onClose={() => this.setState({ materialTovNInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialSFInEdit
        && (
        <MaterialsSF
          object={materialSFInEdit}
          onClose={() => this.setState({ materialSFInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialPPInEdit
        && (
        <MaterialsPP
          object={materialPPInEdit}
          onClose={() => this.setState({ materialPPInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
        {materialDSInEdit
        && (
        <MaterialsDS
          object={materialDSInEdit}
          onClose={() => this.setState({ materialDSInEdit: null })}
          onRemoveFile={this.onRemoveFile}
          onUploadFile={this.onUploadFile}
          onSave={this.onSaveModal}
        />
        )}
      </Col>
    );
  }
}

Materials.propTypes = {
  title: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checkboxName: PropTypes.string.isRequired,
  checkboxValue: PropTypes.bool,
  isMaterial: PropTypes.bool,
  isEquipment: PropTypes.bool,
  isOther: PropTypes.bool,
  isPaper: PropTypes.bool,
  financialPaperPolicyFile: PropTypes.shape(),
  financialPaperAccountFile: PropTypes.shape(),
  isFormDisabled: PropTypes.bool.isRequired,
};

Materials.defaultProps = {
  checkboxValue: null,
  isMaterial: null,
  isEquipment: null,
  isOther: null,
  isPaper: null,
  financialPaperPolicyFile: null,
  financialPaperAccountFile: null,
};

export default Materials;
