/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Breadcrumb, Col, Row } from 'antd';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { routes } from '../../../utils/routes';
import getApplicationStatusTag from '../../Home/components/HomePageDesktop/utils/getApplicationStatusTag';

function Navigation({ view, onChangeView, application }) {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href={routes.homePage}>Список заявок</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`Заявка №${application.displayID || '-'}`}
          {getApplicationStatusTag(application.status)}
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{application.theme}</span> */}

      <Row
        justify="space-between"
        align="middle"
        style={{
          width: '100%',
          margin: '20px 0px',
          backgroundColor: '#fff',
          borderRadius: '25px',
          padding: '20px',
        }}
      >
        <Col>
          <Row gutter={40}>
            <Col>
              <Row
                style={{
                  alignItems: 'center',
                  cursor: view === 'common' ? 'initial' : 'pointer',
                }}
                onClick={() => onChangeView('common')}
              >
                <Col>
                  <div
                    style={{
                      padding: '5px',
                      borderRadius: '100%',
                      width: '40px',
                      height: '40px',
                      backgroundColor:
                        view === 'common' ? '#1890FF' : '#e9e9e9',
                      color: view === 'common' ? '#fff' : '#959595',
                      fontSize: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    1
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      color: view === 'common' ? '#1890FF' : '#959595',
                      fontSize: '18px',
                      marginLeft: '10px',
                    }}
                  >
                    Информация о заявке
                  </div>
                </Col>
              </Row>
            </Col>
            {application.status === 'Поддержана' && (
              <Col>
                <Row
                  style={{
                    alignItems: 'center',
                    cursor: view === 'project' ? 'initial' : 'pointer',
                  }}
                  onClick={() => onChangeView('project')}
                >
                  <Col>
                    <div
                      style={{
                        padding: '5px',
                        borderRadius: '100%',
                        width: '40px',
                        height: '40px',
                        backgroundColor:
                          view === 'project' ? '#1890FF' : '#e9e9e9',
                        color: view === 'project' ? '#fff' : '#959595',
                        fontSize: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      2
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        color: view === 'project' ? '#1890FF' : '#959595',
                        fontSize: '18px',
                        marginLeft: '10px',
                      }}
                    >
                      Проект
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

Navigation.propTypes = {
  view: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
  application: PropTypes.shape().isRequired,
};

export default withRouter(Navigation);
