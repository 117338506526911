/* eslint-disable no-nested-ternary */
import { createBrowserHistory as createHistory } from 'history';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import api from './directualAPI/api';


function handleError(error) {
  if (get(error, 'response.status', null) === 403) {
    return createHistory({ forceRefresh: true }).push('/login');
  }
  throw new Error(error);
}

function parseUser(user) {
  const isPending = get(user, 'isPending', false);
  const isBlocked = get(user, 'isBlocked', false);
  return {
    id: get(user, 'id', null),
    shortName: get(user, 'shortName', ''),
    onApproval: Number(get(user, 'onApproval', 0)),
    drafts: Number(get(user, 'drafts', 0)),
    approved: Number(get(user, 'approved', 0)),
    declined: Number(get(user, 'declined', 0)),
    total: Number(get(user, 'total', 0)),
    role: get(user, 'role', 'user'),
    position: get(user, 'position', ''),
    middleName: get(user, 'middleName', ''),
    lastName: get(user, 'lastName', ''),
    firstName: get(user, 'firstName', ''),
    phone: get(user, 'phone', ''),
    email: get(user, 'email', ''),
    isPending: get(user, 'isPending', false),
    isBlocked: get(user, 'isBlocked', false),
    status: isPending ? 'На согласовании' : isBlocked ? 'Отклонено' : 'Согласовано',
    passwordView: get(user, 'passwordView', ''),
    regDate: get(user, 'regDate', null) ? moment(get(user, 'regDate', null)) : null,
    company: get(user, 'company', ''),
    legalAddress: get(user, 'legalAddress', ''),
    contactPosition: get(user, 'contactPosition', ''),
    contactMiddleName: get(user, 'contactMiddleName', ''),
    contactLastName: get(user, 'contactLastName', ''),
    contactFirstName: get(user, 'contactFirstName', ''),
    contactPhone: get(user, 'contactPhone', ''),
    contactEmail: get(user, 'contactEmail', ''),
    isDzm: get(user, 'isDzm', ''),
    year: get(user, 'year', '2022'),
  };
}

function parseFile(file) {
  return {
    id: get(file, 'id', null),
    urlLink: get(file, 'urlLink', null),
    name: get(file, 'originalFileName', ''),
    extension: get(file, 'extension', ''),
    size: get(file, 'size', 0),
  };
}

export function escapeHTML(text) {
  // Debug
  // const map = {
  //   '&': '&amp;',
  //   '<': '&lt;',
  //   '>': '&gt;',
  //   '"': '&quot;',
  //   "'": '&#039;',
  //   '’': '&rsquo;',
  //   '/': '&#47;',
  // };
  // return text.replace(/[&<>"'’/]/g, (m) => map[m]);

  return text;
}

export function unescapeHTML(text) {
  // =/
  const map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&amp;quot;': '"',
    '&amp;amp;': '&',
    '&amp;amp;quot': '"',
    '&#039;': "'",
    '&rsquo;': '’',
    '&#47;': '/',
  };

  Object.entries(map).forEach(([key, value]) => {
    text = text.replace(new RegExp(key, 'g'), value);
  });

  return text;
}


function parseApplication(application) {
  return {
    id: get(application, 'id', null),
    displayID: get(application, 'displayID', ''),
    createDate: get(application, 'createDate', null) ? moment(get(application, 'createDate', null)) : null,
    theme: get(application, 'theme', ''),
    status: get(application, 'status', ''),
    specialization: get(application, 'specialization_id.value', ''),
    scientificDirection: get(application, 'scientificDirection_id.value', ''),
    shortName: get(application, 'shortName', ''),
    projectFinance: get(application, 'projectFinance', ''),
    webUserShortName: get(application, 'webUser_id.shortName', ''),
    webUserId: get(application, 'webUser_id.id', ''),
    webUser_id: get(application, 'webUser_id.id', ''),
    detailedApplicationLink_id: get(application, 'detailedApplicationLink_id.id', null) ? parseFile(get(application, 'detailedApplicationLink_id.id', null)) : null,
    additionalFiles: get(application, 'additionalFiles_ids', []).map((f) => parseFile(f)),
    applicationAndSopd_ids: get(application, 'applicationAndSopd_ids', []).map((f) => parseFile(f)),
    isApproved: get(application, 'isApproved', false),
    grantAgreementLink: get(application, 'grantAgreement_id.urlLink', null),
    grantAppStatus: get(application, 'grantAppStatus', ''),
    grantAppComment: get(application, 'grantAppComment', ''),
    agreementPersonsStatus: get(application, 'agreementPersonsStatus', 'На заполнении'),
    grantAgreementScanLink: get(application, 'grantAgreementScan_id.urlLink', null),
    grantApplicationLink: get(application, 'grantApplication_id.urlLink', null),

    applicationStepReport_ids: get(application, 'applicationStepReport_ids', []).map((f) => ({
      id: get(f, 'id', null),
      stepNumber: get(f, 'stepNumber', null),
      annotationReportPDF: get(f, 'annotationReportPDF', null),
      annotationReportPDFSigned: get(f, 'annotationReportPDFSigned.urlLink', null),
      financialReportPDF: get(f, 'financialReportPDF', null),
      financialReportPDFSigned: get(f, 'financialReportPDFSigned.urlLink', null),
      actReport_id: get(f, 'actReport_id', null) ? parseFile(get(f, 'actReport_id')) : null,
    })),

    nirFile_id: get(application, 'nirFile_id', null) ? parseFile(get(application, 'nirFile_id')) : null,
    currentTransferedSum: get(application, 'currentTransferedSum'),
    allStepsSum: get(application, 'allStepsSum'),
    isPersonApplication: get(application, 'isPersonApplication', false),
    isGrants2023: get(application, 'isGrants2023', false),
    persons: get(application, 'persons_ids', []).map((p) => ({ id: p.id, name: p.form })),
  };
}

function parseStatus(status) {
  return {
    id: get(status, 'id', null),
    status: get(status, 'status', ''),
    date: get(status, 'date', null) ? moment(get(status, 'date', null)) : null,
    comment: get(status, 'comment', ''),
    user: get(status, 'webUser_id.shortName', ''),
  };
}

function parseApplicationPerson(person) {
  return {
    id: get(person, 'id', null),
    status: get(person, 'status', null),
    totalSum: get(person, 'totalSum', null),
    form: get(person, 'form', null),
    ogrn: get(person, 'ogrn', null),
    inn: get(person, 'inn', null),
    isMoscow: get(person, 'isMoscow', null),
    firstName: get(person, 'firstName', null),
    lastName: get(person, 'lastName', null),
    middleName: get(person, 'middleName', null),
    phone: get(person, 'phone', null),
    email: get(person, 'email', null),
    maxStepsNumber: Number(get(person, 'maxStepsNumber', 0)),
    step1Cost: get(person, 'step1Cost', null),
    step1Description: get(person, 'step1Description', null),
    step2Cost: get(person, 'step2Cost', null),
    step2Description: get(person, 'step2Description', null),
    step3Cost: get(person, 'step3Cost', null),
    step3Description: get(person, 'step3Description', null),
    step4Cost: get(person, 'step4Cost', null),
    step4Description: get(person, 'step4Description', null),
    step5Cost: get(person, 'step5Cost', null),
    step5Description: get(person, 'step5Description', null),
    step6Cost: get(person, 'step6Cost', null),
    step6Description: get(person, 'step6Description', null),

    step1Date: get(person, 'step1Date', null) ? moment(get(person, 'step1Date', null)) : null,
    step1Requisites: get(person, 'step1Requisites', null),
    step1Transfered: get(person, 'step1Transfered', null),
    step1Comment: get(person, 'step1Comment', null),
    step1TransferedTotal: get(person, 'step1TransferedTotal', null),

    step2Date: get(person, 'step2Date', null) ? moment(get(person, 'step2Date', null)) : null,
    step2Requisites: get(person, 'step2Requisites', null),
    step2Transfered: get(person, 'step2Transfered', null),
    step2Comment: get(person, 'step2Comment', null),
    step2TransferedTotal: get(person, 'step2TransferedTotal', null),

    step3Date: get(person, 'step3Date', null) ? moment(get(person, 'step3Date', null)) : null,
    step3Requisites: get(person, 'step3Requisites', null),
    step3Transfered: get(person, 'step3Transfered', null),
    step3Comment: get(person, 'step3Comment', null),
    step3TransferedTotal: get(person, 'step3TransferedTotal', null),

    step4Date: get(person, 'step4Date', null) ? moment(get(person, 'step4Date', null)) : null,
    step4Requisites: get(person, 'step4Requisites', null),
    step4Transfered: get(person, 'step4Transfered', null),
    step4Comment: get(person, 'step4Comment', null),
    step4TransferedTotal: get(person, 'step4TransferedTotal', null),

    step5Date: get(person, 'step5Date', null) ? moment(get(person, 'step5Date', null)) : null,
    step5Requisites: get(person, 'step5Requisites', null),
    step5Transfered: get(person, 'step5Transfered', null),
    step5Comment: get(person, 'step5Comment', null),
    step5TransferedTotal: get(person, 'step5TransferedTotal', null),

    step6Date: get(person, 'step6Date', null) ? moment(get(person, 'step6Date', null)) : null,
    step6Requisites: get(person, 'step6Requisites', null),
    step6Transfered: get(person, 'step6Transfered', null),
    step6Comment: get(person, 'step6Comment', null),
    step6TransferedTotal: get(person, 'step6TransferedTotal', null),

    step1DateClose: get(person, 'step1DateClose', null) ? moment(get(person, 'step1DateClose', null)) : null,
    step1RequisitesClose: get(person, 'step1RequisitesClose', null),
    step1TransferedClose: get(person, 'step1TransferedClose', null),
    step1CommentClose: get(person, 'step1CommentClose', null),

    step2DateClose: get(person, 'step2DateClose', null) ? moment(get(person, 'step2DateClose', null)) : null,
    step2RequisitesClose: get(person, 'step2RequisitesClose', null),
    step2TransferedClose: get(person, 'step2TransferedClose', null),
    step2CommentClose: get(person, 'step2CommentClose', null),

    step3DateClose: get(person, 'step3DateClose', null) ? moment(get(person, 'step3DateClose', null)) : null,
    step3RequisitesClose: get(person, 'step3RequisitesClose', null),
    step3TransferedClose: get(person, 'step3TransferedClose', null),
    step3CommentClose: get(person, 'step3CommentClose', null),

    step4DateClose: get(person, 'step4DateClose', null) ? moment(get(person, 'step4DateClose', null)) : null,
    step4RequisitesClose: get(person, 'step4RequisitesClose', null),
    step4TransferedClose: get(person, 'step4TransferedClose', null),
    step4CommentClose: get(person, 'step4CommentClose', null),

    step5DateClose: get(person, 'step5DateClose', null) ? moment(get(person, 'step5DateClose', null)) : null,
    step5RequisitesClose: get(person, 'step5RequisitesClose', null),
    step5TransferedClose: get(person, 'step5TransferedClose', null),
    step5CommentClose: get(person, 'step5CommentClose', null),

    step6DateClose: get(person, 'step6DateClose', null) ? moment(get(person, 'step6DateClose', null)) : null,
    step6RequisitesClose: get(person, 'step6RequisitesClose', null),
    step6TransferedClose: get(person, 'step6TransferedClose', null),
    step6CommentClose: get(person, 'step6CommentClose', null),

    stepNDate: get(person, 'stepNDate', null) ? moment(get(person, 'stepNDate', null)) : null,
    stepNRequisites: get(person, 'stepNRequisites', null),
    stepNTransfered: get(person, 'stepNTransfered', null),
    stepNComment: get(person, 'stepNComment', null),

    agreementScanFile_id: get(person, 'agreementScanFile_id.id', null) ? parseFile(get(person, 'agreementScanFile_id')) : null,

    step1files_ids: get(person, 'step1files_ids', []).map((f) => parseFile(f)),
    step2files_ids: get(person, 'step2files_ids', []).map((f) => parseFile(f)),
    step3files_ids: get(person, 'step3files_ids', []).map((f) => parseFile(f)),
    step4files_ids: get(person, 'step4files_ids', []).map((f) => parseFile(f)),
    step5files_ids: get(person, 'step5files_ids', []).map((f) => parseFile(f)),
    step6files_ids: get(person, 'step6files_ids', []).map((f) => parseFile(f)),

    allStepTransferedTotal: parseFloat(get(person, 'allStepTransferedTotal', 0)),

    fullName: get(person, 'fullName', null),
    position: get(person, 'position', null),

    directorFullName: get(person, 'directorFullName', null),
  };
}

function parseApplicationStep1Publications(object) {
  return {
    id: get(object, 'id', null),
    authors: get(object, 'authors', null),
    name: get(object, 'name', null),
    date: get(object, 'date', null),
    keyWords: get(object, 'keyWords', null),
    type: get(object, 'type', null),
    typeOther: get(object, 'type', null),
    publisherName: get(object, 'publisherName', null),
    ISSN: get(object, 'ISSN', null),
    eISSN: get(object, 'eISSN', null),
    ISBN: get(object, 'ISBN', null),
    qvartil: get(object, 'qvartil', null),
    publicationParams: get(object, 'publicationParams', null),
    publicationURL: get(object, 'publicationURL', null),
    DOI: get(object, 'DOI', null),
    WoS: get(object, 'WoS', null),
    EID: get(object, 'EID', null),
    isPublished: get(object, 'isPublished', null),
    isWebOfScience: get(object, 'isWebOfScience', null),
    impactFactor: get(object, 'impactFactor', null),
    isScopus: get(object, 'isScopus', null),
    isRinc: get(object, 'isRinc', null),
    isAffiliate: get(object, 'isAffiliate', null),
    isMoscowGrant: get(object, 'isMoscowGrant', null),
    isNonMoscowGrant: get(object, 'isNonMoscowGrant', null),
    publishedEmail_id: get(object, 'publishedEmail_id.id', null) ? parseFile(get(object, 'publishedEmail_id')) : null,
    publicationFile_id: get(object, 'publicationFile_id.id', null) ? parseFile(get(object, 'publicationFile_id')) : null,
  };
}

function parseApplicationStep1Rid(object) {
  return {
    id: get(object, 'id', null),
    documentType: get(object, 'documentType', null),
    certificateType: get(object, 'certificateType', null),
    patentType: get(object, 'patentType', null),
    name: get(object, 'name', null),
    fio: get(object, 'fio', null),
    materials_files_field: get(object, 'materials_files_field', []).map((f) => parseFile(f)),
    applicationNumber: get(object, 'applicationNumber', null),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)) : null,
    documentNumber: get(object, 'documentNumber', null),
    regDate: get(object, 'regDate', null) ? moment(get(object, 'regDate', null)) : null,
    endDate: get(object, 'endDate', null) ? moment(get(object, 'endDate', null)) : null,
    annotation: get(object, 'annotation', null),
    documentURL: get(object, 'documentURL', null),
  };
}

function parseApplicationStep1Events(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    dateStart: get(object, 'dateStart', null) ? moment(get(object, 'dateStart', null)) : null,
    dateEnd: get(object, 'dateEnd', null) ? moment(get(object, 'dateEnd', null)) : null,
    material_files_field: get(object, 'material_files_field', []).map((f) => parseFile(f)),
    url_materials: get(object, 'url_materials', null),
    city: get(object, 'city', null),
    resultsForm: get(object, 'resultsForm', null),
    resultsFIO: get(object, 'resultsFIO', null),
    isInternational: get(object, 'isInternational', null),
    participants: get(object, 'participants', null),
  };
}

function applicationCostsDocument(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', ''),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)) : null,
    pages: Number(get(object, 'pages', 0)),
    contractor: get(object, 'contractor', null),
    comment: get(object, 'comment', null),
    file: get(object, 'file', null) ? parseFile(get(object, 'file')) : null,
    sumMajor: Number(get(object, 'sumMajor', 0)),
    sumMinor: Number(get(object, 'sumMinor', 0)),
  };
}

function parseApplicationCost(object) {
  return {
    id: get(object, 'id', null),
    isOther: get(object, 'isOther', false),
    isMaterial: get(object, 'isMaterial', false),
    isEquipment: get(object, 'isEquipment', false),
    isPaper: get(object, 'isPaper', false),
    name: get(object, 'name', ''),
    actualCostsMajor: Number(get(object, 'actualCostsMajor', 0)),
    actualCostsMinor: Number(get(object, 'actualCostsMinor', 0)),
    paperCostsMajor: Number(get(object, 'paperCostsMajor', 0)),
    paperCostsMinor: Number(get(object, 'paperCostsMinor', 0)),
    deviationsMajor: Number(get(object, 'deviationsMajor', 0)),
    deviationsMinor: Number(get(object, 'deviationsMinor', 0)),
    isNoAdditionalAgreement: get(object, 'isNoAdditionalAgreement', false),
    isDeliveryBill: get(object, 'isDeliveryBill', false),
    deliveryBill: get(object, 'deliveryBill', null) ? applicationCostsDocument(get(object, 'deliveryBill')) : null,
    invoice: get(object, 'invoice', null) ? applicationCostsDocument(get(object, 'invoice')) : null,
    isNotDeliveryBill: get(object, 'isNotDeliveryBill', false),
    excludingVAT: get(object, 'excludingVAT', false),
    materialTN: get(object, 'materialTN', null) ? applicationCostsDocument(get(object, 'materialTN')) : null,
    materialTovN: get(object, 'materialTovN', null) ? applicationCostsDocument(get(object, 'materialTovN')) : null,
    materialSF: get(object, 'materialSF', null) ? applicationCostsDocument(get(object, 'materialSF')) : null,
    materialYPD: get(object, 'materialYPD', null) ? applicationCostsDocument(get(object, 'materialYPD')) : null,
    materialAct: get(object, 'materialAct', null) ? applicationCostsDocument(get(object, 'materialAct')) : null,
    materialPP: get(object, 'materialPP', []).map((o) => applicationCostsDocument(o)),
    materialDS: get(object, 'materialDS', []).map((o) => applicationCostsDocument(o)),
    materialContract: get(object, 'materialContract', null) ? applicationCostsDocument(get(object, 'materialContract')) : null,
    grantSumMajor: Number(get(object, 'grantSumMajor', 0)),
    grantSumMinor: Number(get(object, 'grantSumMinor', 0)),
  };
}

function parseApplicationSalary(object) {
  return {
    id: get(object, 'id', null),
    fio: get(object, 'fio', ''),
    role: get(object, 'role', ''),
    position: get(object, 'position', ''),
    totalPaidMajor: Number(get(object, 'totalPaidMajor', 0)),
    totalPaidMinor: Number(get(object, 'totalPaidMinor', 0)),
    ndflMajor: Number(get(object, 'ndflMajor', 0)),
    ndflMinor: Number(get(object, 'ndflMinor', 0)),
    pfrMajor: Number(get(object, 'pfrMajor', 0)),
    pfrMinor: Number(get(object, 'pfrMinor', 0)),
    fomsMajor: Number(get(object, 'fomsMajor', 0)),
    fomsMinor: Number(get(object, 'fomsMinor', 0)),
    fssMajor: Number(get(object, 'fssMajor', 0)),
    fssMinor: Number(get(object, 'fssMinor', 0)),
    fssnsMajor: Number(get(object, 'fssnsMajor', 0)),
    fssnsMinor: Number(get(object, 'fssnsMinor', 0)),
    actualPaidMajor: Number(get(object, 'actualPaidMajor', 0)),
    actualPaidMinor: Number(get(object, 'actualPaidMinor', 0)),
    remainMajor: Number(get(object, 'remainMajor', 0)),
    remainMinor: Number(get(object, 'remainMinor', 0)),
    totalPersonMajor: Number(get(object, 'totalPersonMajor', 0)),
    totalPersonMinor: Number(get(object, 'totalPersonMinor', 0)),
    enpMajor: Number(get(object, 'enpMajor', 0)),
    enpMinor: Number(get(object, 'enpMinor', 0)),
  };
}

function parseApplicationsFinancialReportTotal(object) {
  return {
    id: get(object, 'id', null),
    type: get(object, 'type', ''),
    comment: get(object, 'comment', ''),
    moneyLeftPeriodStartMajor: Number(get(object, 'moneyLeftPeriodStartMajor', 0)),
    moneyLeftPeriodStartMinor: Number(get(object, 'moneyLeftPeriodStartMinor', 0)),
    grantSumMajor: Number(get(object, 'grantSumMajor', 0)),
    grantSumMinor: Number(get(object, 'grantSumMinor', 0)),
    money2022Major: Number(get(object, 'money2022Major', 0)),
    money2022Minor: Number(get(object, 'money2022Minor', 0)),
  };
}

function parseStepSevenRows(object) {
  return {
    id: get(object, 'id', null),
    task: get(object, 'task', ''),
    description: get(object, 'description', ''),
    result: get(object, 'result', ''),
    orderIndex: get(object, 'orderIndex', 0),
  };
}

function parseApplicationStepReport(application) {
  return {
    id: get(application, 'id', null),
    isAllowAnnotationEdit: get(application, 'isAllowAnnotationEdit', null),
    isAllowFinanceEdit: get(application, 'isAllowFinanceEdit', null),

    endDate: get(application, 'endDate', null) ? moment(get(application, 'endDate', null)) : null,
    stepDate: get(application, 'stepDate', null) ? moment(get(application, 'stepDate', null)) : null,
    stepNumber: get(application, 'stepNumber', null),
    toPaySumMajor: Number(get(application, 'toPaySumMajor', 0)),
    toPaySumMinor: Number(get(application, 'toPaySumMinor', 0)),
    anoExpertiseResult: get(application, 'anoExpertiseResult', null),
    stepFiles: get(application, 'stepFiles', []).map((f) => parseFile(f)),
    displayID: get(application, 'displayID', null),

    ano1date: get(application, 'ano1date', null) ? moment(get(application, 'ano1date', null)) : null,
    fin1date: get(application, 'fin1date', null) ? moment(get(application, 'fin1date', null)) : null,
    fin2date: get(application, 'fin2date', null) ? moment(get(application, 'fin2date', null)) : null,
    fin3date: get(application, 'fin3date', null) ? moment(get(application, 'fin3date', null)) : null,
    fin4date: get(application, 'fin4date', null) ? moment(get(application, 'fin4date', null)) : null,
    fin5date: get(application, 'fin5date', null) ? moment(get(application, 'fin5date', null)) : null,
    fin6date: get(application, 'fin6date', null) ? moment(get(application, 'fin6date', null)) : null,

    formAndName: get(application, 'formAndName', ''),
    themeName: get(application, 'themeName', ''),
    agreementNumber: get(application, 'agreementNumber', ''),
    monthAndYearEnd: get(application, 'monthAndYearEnd', null) ? moment(get(application, 'monthAndYearEnd', null)) : null,
    agreementDate: get(application, 'agreementDate', ''),
    agreementNumberAndDates: get(application, 'agreementNumberAndDates', ''),
    fullAgreementSumMajor: get(application, 'fullAgreementSumMajor', 0),
    stepSumTotalMajor: get(application, 'stepSumTotalMajor', 0),
    stepSumGainedMajor: get(application, 'stepSumGainedMajor', 0),
    fullAgreementSumMinor: get(application, 'fullAgreementSumMinor', 0),
    stepSumTotalMinor: get(application, 'stepSumTotalMinor', 0),
    stepSumGainedMinor: get(application, 'stepSumGainedMinor', 0),
    stepWork: get(application, 'stepWork', ''),
    stepResults: get(application, 'stepResults', ''),
    agreementLastNameSpecialist: get(application, 'agreementLastNameSpecialist', ''),
    agreementFirstNameSpecialist: get(application, 'agreementFirstNameSpecialist', ''),
    agreementMiddleNameSpecialist: get(application, 'agreementMiddleNameSpecialist', ''),
    agreementPhoneSpecialist: get(application, 'agreementPhoneSpecialist', ''),
    agreementEmailSpecialist: get(application, 'agreementEmailSpecialist', ''),
    agreementLastNameDirector: get(application, 'agreementLastNameDirector', ''),
    agreementFirstNameDirector: get(application, 'agreementFirstNameDirector', ''),
    agreementMiddleNameDirector: get(application, 'agreementMiddleNameDirector', ''),
    agreementPositionDirector: get(application, 'agreementPositionDirector', ''),

    annotationTasks: get(application, 'annotationTasks', ''),
    annotationTasksDone: get(application, 'annotationTasksDone', ''),
    annotationTasksDoneAttachments: get(application, 'annotationTasksDoneAttachments', []).map((f) => parseFile(f)),
    annotationMethods: get(application, 'annotationMethods', ''),
    annotationMaterials: get(application, 'annotationMaterials', ''),
    annotationIsPublications: get(application, 'annotationIsPublications', ''),
    annotationIsPresented: get(application, 'annotationIsPresented', ''),
    annotationIsIntellectualProperty: get(application, 'annotationIsIntellectualProperty', ''),
    annotationIsResultsAchieved: get(application, 'annotationIsResultsAchieved', ''),
    annotationResultsWorksNotDone: get(application, 'annotationResultsWorksNotDone', ''),
    annotationResultsWorksDone: get(application, 'annotationResultsWorksDone', ''),
    annotationResultsWorksReplaced: get(application, 'annotationResultsWorksReplaced', ''),
    annotationStatus: get(application, 'annotationStatus', ''),
    annotationComment: get(application, 'annotationComment', ''),
    applicationStepPublications_ids: get(application, 'applicationStepPublications_ids', []).map((f) => parseApplicationStep1Publications(f)),
    applicationStepEvents_ids: get(application, 'applicationStepEvents_ids', []).map((f) => parseApplicationStep1Events(f)),
    applicationStepRid_ids: get(application, 'applicationStepRid_ids', []).map((f) => parseApplicationStep1Rid(f)),
    annotationReportPDF: get(application, 'annotationReportPDF', null),
    annotationReportPDFSigned: get(application, 'annotationReportPDFSigned.urlLink', null),

    financialCosts_ids: get(application, 'financialCosts_ids', []).map((f) => parseApplicationCost(f)),
    financialIsSalaryDone: get(application, 'financialIsSalaryDone', false),
    financialIsPaperDone: get(application, 'financialIsPaperDone', false),
    financialIsOtherDone: get(application, 'financialIsOtherDone', false),
    financialIsEquipmentDone: get(application, 'financialIsEquipmentDone', false),
    financialIsMaterialDone: get(application, 'financialIsMaterialDone', false),
    financialSalaryFile: get(application, 'financialSalaryFile', null) ? parseFile(get(application, 'financialSalaryFile')) : null,
    financialSalaryFiles: get(application, 'financialSalaryFiles', []).map((f) => parseFile(f)),
    financialSalaryPPFiles: get(application, 'financialSalaryPPFiles', []).map((f) => parseFile(f)),
    financialSalary_ids: get(application, 'financialSalary_ids', []).map((f) => parseApplicationSalary(f)),
    financialPaperPolicyFile: get(application, 'financialPaperPolicyFile', null) ? parseFile(get(application, 'financialPaperPolicyFile')) : null,
    financialPaperAccountFile: get(application, 'financialPaperAccountFile', null) ? parseFile(get(application, 'financialPaperAccountFile')) : null,
    financialReportStatus: get(application, 'financialReportStatus', ''),
    financialReportTotal_ids: get(application, 'financialReportTotal_ids', []).map((a) => parseApplicationsFinancialReportTotal(a)),
    isFinancialTotalChecked: get(application, 'isFinancialTotalChecked', false),
    financialReportPosition: get(application, 'financialReportPosition', ''),
    financialReportFIO: get(application, 'financialReportFIO', ''),
    financialReportPDF: get(application, 'financialReportPDF', null),
    financialReportPDFSigned: get(application, 'financialReportPDFSigned.urlLink', null),

    actReport_id: get(application, 'actReport_id', null) ? parseFile(get(application, 'actReport_id')) : null,
    nirTemplateFiles_ids: get(application, 'nirTemplateFiles_ids', []).map((f) => parseFile(f)),
    nirReport_ids: get(application, 'nirReport_ids', []).map((f) => parseFile(f)),

    mainDisplayID: get(application, 'mainDisplayID', ''),
    mainFullName: get(application, 'mainFullName', ''),
    mainAgreementDirectorFio: get(application, 'mainAgreementDirectorFio', ''),
    mainAgreementSpecialistFio: get(application, 'mainAgreementSpecialistFio', ''),
    mainFormAndName: get(application, 'mainFormAndName', ''),
    mainPositionDirector: get(application, 'mainPositionDirector', ''),

    // Новый аннотированный отчет
    annotationProjectGoal: get(application, 'annotationProjectGoal', ''),
    projectShortAnnotation: get(application, 'projectShortAnnotation', ''),
    projectShortAnnotationFiles: get(application, 'projectShortAnnotationFiles', []).map((f) => parseFile(f)),
    stepSevenRows: get(application, 'stepSevenRows', []).map((s) => parseStepSevenRows(s)),
    annotationProjectTasks: get(application, 'annotationProjectTasks', ''),
    projectPublicationsPlan: get(application, 'projectPublicationsPlan', null),
    projectPublicationsFact: get(application, 'projectPublicationsFact', null),
    projectPublicationsReason: get(application, 'projectPublicationsReason', null),
    projectVACPlan: get(application, 'projectVACPlan', null),
    projectVACFact: get(application, 'projectVACFact', null),
    projectVACReason: get(application, 'projectVACReason', null),
    projectRINCPlan: get(application, 'projectRINCPlan', null),
    projectRINCFact: get(application, 'projectRINCFact', null),
    projectRINCReason: get(application, 'projectRINCReason', null),
    projectRIDPlan: get(application, 'projectRIDPlan', null),
    projectRIDFact: get(application, 'projectRIDFact', null),
    projectRIDReason: get(application, 'projectRIDReason', null),
    projectEventsPlan: get(application, 'projectEventsPlan', null),
    projectEventsFact: get(application, 'projectEventsFact', null),
    projectEventsReason: get(application, 'projectEventsReason', null),
    projectPatientsPlan: get(application, 'projectPatientsPlan', null),
    projectPatientsFact: get(application, 'projectPatientsFact', null),
    projectPatientsReason: get(application, 'projectPatientsReason', null),
    projectPatientsMainPlan: get(application, 'projectPatientsMainPlan', null),
    projectPatientsMainFact: get(application, 'projectPatientsMainFact', null),
    projectPatientsMainReason: get(application, 'projectPatientsMainReason', null),
    projectPatientsControlPlan: get(application, 'projectPatientsControlPlan', null),
    projectPatientsControlFact: get(application, 'projectPatientsControlFact', null),
    projectPatientsControlReason: get(application, 'projectPatientsControlReason', null),
    projectPatientsComparePlan: get(application, 'projectPatientsComparePlan', null),
    projectPatientsCompareFact: get(application, 'projectPatientsCompareFact', null),
    projectPatientsCompareReason: get(application, 'projectPatientsCompareReason', null),
    projectExamplesPlan: get(application, 'projectExamplesPlan', null),
    projectExamplesFact: get(application, 'projectExamplesFact', null),
    projectExamplesReason: get(application, 'projectExamplesReason', null),
    projectProtocolsPlan: get(application, 'projectProtocolsPlan', null),
    projectProtocolsFact: get(application, 'projectProtocolsFact', null),
    projectProtocolsReason: get(application, 'projectProtocolsReason', null),
    projectAudiencePlan: get(application, 'projectAudiencePlan', null),
    projectAudienceFact: get(application, 'projectAudienceFact', null),
    projectAudienceReason: get(application, 'projectAudienceReason', null),
    projectActsPlan: get(application, 'projectActsPlan', null),
    projectActsFact: get(application, 'projectActsFact', null),
    projectActsReason: get(application, 'projectActsReason', null),
    projectEventsDemonstrationFact: get(application, 'projectEventsDemonstrationFact', null),
    projectEventsDemonstrationPlan: get(application, 'projectEventsDemonstrationPlan', null),
    projectEventsDemonstrationReason: get(application, 'projectEventsDemonstrationReason', null),
    projectActsFiles_ids: get(application, 'projectActsFiles_ids', []).map((f) => parseFile(f)),
    projectProtocols: get(application, 'projectProtocols', ''),
    projectProtocolsNames: get(application, 'projectProtocolsNames', ''),
    projectResultsDescription: get(application, 'projectResultsDescription', ''),
    projectResultsDescriptionFiles_ids: get(application, 'projectResultsDescriptionFiles_ids', []).map((f) => parseFile(f)),
    projectResultsAchieved: get(application, 'projectResultsAchieved', ''),
    projectResultsAchievedFact: get(application, 'projectResultsAchievedFact', ''),
    projectResultsAchievedReason: get(application, 'projectResultsAchievedReason', ''),
    projectResultsExecuted: get(application, 'projectResultsExecuted', ''),
    projectResultsExecutedType: get(application, 'projectResultsExecutedType', ''),
    projectResultsExecutedDescription: get(application, 'projectResultsExecutedDescription', ''),
    projectResultsExecutedReasons: get(application, 'projectResultsExecutedReasons', ''),
    projectResultsExecutedRequired: get(application, 'projectResultsExecutedRequired', ''),
    projectVACPublications_ids: get(application, 'projectVACPublications_ids', []).map((f) => parseApplicationStep1Publications(f)),
    projectRINCPublications_ids: get(application, 'projectRINCPublications_ids', []).map((f) => parseApplicationStep1Publications(f)),
    projectEvents_ids: get(application, 'projectEvents_ids', []).map((f) => parseApplicationStep1Events(f)),
    projectInternationalEvents_ids: get(application, 'projectInternationalEvents_ids', []).map((f) => parseApplicationStep1Events(f)),
    projectRID_ids: get(application, 'projectRID_ids', []).map((f) => parseApplicationStep1Rid(f)),
    step1ReportDateAnnotation: get(application, 'step1ReportDateAnnotation', null) ? moment(get(application, 'step1ReportDateAnnotation', null)) : null,
    step1ReportCommentAnnotation: get(application, 'step1ReportCommentAnnotation', ''),
    step2ReportDateAnnotation: get(application, 'step2ReportDateAnnotation', null) ? moment(get(application, 'step2ReportDateAnnotation', null)) : null,
    step2ReportCommentAnnotation: get(application, 'step2ReportCommentAnnotation', ''),
    step3ReportDateAnnotation: get(application, 'step3ReportDateAnnotation', null) ? moment(get(application, 'step3ReportDateAnnotation', null)) : null,
    step3ReportCommentAnnotation: get(application, 'step3ReportCommentAnnotation', ''),
    step4ReportDateAnnotation: get(application, 'step4ReportDateAnnotation', null) ? moment(get(application, 'step4ReportDateAnnotation', null)) : null,
    step4ReportCommentAnnotation: get(application, 'step4ReportCommentAnnotation', ''),
    step5ReportDateAnnotation: get(application, 'step5ReportDateAnnotation', null) ? moment(get(application, 'step5ReportDateAnnotation', null)) : null,
    step5ReportCommentAnnotation: get(application, 'step5ReportCommentAnnotation', ''),
    step6ReportDateAnnotation: get(application, 'step6ReportDateAnnotation', null) ? moment(get(application, 'step6ReportDateAnnotation', null)) : null,
    step6ReportCommentAnnotation: get(application, 'step6ReportCommentAnnotation', ''),
    step1ReportDateFinance: get(application, 'step1ReportDateFinance', null) ? moment(get(application, 'step1ReportDateFinance', null)) : null,
    step1ReportCommentFinance: get(application, 'step1ReportCommentFinance', ''),
    step2ReportDateFinance: get(application, 'step2ReportDateFinance', null) ? moment(get(application, 'step2ReportDateFinance', null)) : null,
    step2ReportCommentFinance: get(application, 'step2ReportCommentFinance', ''),
    step3ReportDateFinance: get(application, 'step3ReportDateFinance', null) ? moment(get(application, 'step3ReportDateFinance', null)) : null,
    step3ReportCommentFinance: get(application, 'step3ReportCommentFinance', ''),
    step4ReportDateFinance: get(application, 'step4ReportDateFinance', null) ? moment(get(application, 'step4ReportDateFinance', null)) : null,
    step4ReportCommentFinance: get(application, 'step4ReportCommentFinance', ''),
    step5ReportDateFinance: get(application, 'step5ReportDateFinance', null) ? moment(get(application, 'step5ReportDateFinance', null)) : null,
    step5ReportCommentFinance: get(application, 'step5ReportCommentFinance', ''),
    step6ReportDateFinance: get(application, 'step6ReportDateFinance', null) ? moment(get(application, 'step6ReportDateFinance', null)) : null,
    step6ReportCommentFinance: get(application, 'step6ReportCommentFinance', ''),

    grantAgreementPresentation_id: parseFile(get(application, 'grantAgreementPresentation_id', {})),
    grantAgreementCertificate_id: parseFile(get(application, 'grantAgreementCertificate_id', {})),
    projectPublicationsLeft: get(application, 'projectPublicationsLeft', null),
    projectVACLeft: get(application, 'projectVACLeft', null),
    projectRINCLeft: get(application, 'projectRINCLeft', null),
    projectRIDLeft: get(application, 'projectRIDLeft', null),
    projectEventsDemonstrationLeft: get(application, 'projectEventsDemonstrationLeft', null),
    projectAudienceLeft: get(application, 'projectAudienceLeft', null),
    projectEventsLeft: get(application, 'projectEventsLeft', null),
    projectPatientsLeft: get(application, 'projectPatientsLeft', null),
    projectPatientsMainLeft: get(application, 'projectPatientsMainLeft', null),
    projectPatientsControlLeft: get(application, 'projectPatientsControlLeft', null),
    projectPatientsCompareLeft: get(application, 'projectPatientsCompareLeft', null),
    projectActsLeft: get(application, 'projectActsLeft', null),
    projectExamplesLeft: get(application, 'projectExamplesLeft', null),

    isEnpSalaryForm: get(application, 'isEnpSalaryForm', false),

    // Новый аннотированный отчет 2
    annotationProjectGoalAchieved: get(application, 'annotationProjectGoalAchieved', ''),
    resultsStep1: get(application, 'resultsStep1', ''),
    resultsStep1Achieved: get(application, 'resultsStep1Achieved', ''),
    resultsStep1Description: get(application, 'resultsStep1Description', ''),
    resultsStep1Reason: get(application, 'resultsStep1Reason', ''),
    resultsStep2: get(application, 'resultsStep2', ''),
    resultsStep2Achieved: get(application, 'resultsStep2Achieved', ''),
    resultsStep2Description: get(application, 'resultsStep2Description', ''),
    resultsStep2Reason: get(application, 'resultsStep2Reason', ''),
    resultsStep3: get(application, 'resultsStep3', ''),
    resultsStep3Achieved: get(application, 'resultsStep3Achieved', ''),
    resultsStep3Description: get(application, 'resultsStep3Description', ''),
    resultsStep3Reason: get(application, 'resultsStep3Reason', ''),
    resultsStep4: get(application, 'resultsStep4', ''),
    resultsStep4Achieved: get(application, 'resultsStep4Achieved', ''),
    resultsStep4Description: get(application, 'resultsStep4Description', ''),
    resultsStep4Reason: get(application, 'resultsStep4Reason', ''),
    resultsStep5: get(application, 'resultsStep5', ''),
    resultsStep5Achieved: get(application, 'resultsStep5Achieved', ''),
    resultsStep5Description: get(application, 'resultsStep5Description', ''),
    resultsStep5Reason: get(application, 'resultsStep5Reason', ''),
    integrationResults: get(application, 'integrationResults', ''),
    integrationResultsFormat: get(application, 'integrationResultsFormat', ''),
    integrationResultsDetailed: get(application, 'integrationResultsDetailed', ''),
    integrationResultsReason: get(application, 'integrationResultsReason', ''),
    integrationResultsExpand: get(application, 'integrationResultsExpand', ''),
    projectTermChange: get(application, 'projectTermChange', ''),
    projectTermChangeReason: get(application, 'projectTermChangeReason', ''),
    projectPlanChange: get(application, 'projectPlanChange', ''),
    projectPlanChangeReason: get(application, 'projectPlanChangeReason', ''),
    projectPeopleChange: get(application, 'projectPeopleChange', ''),
    projectPeopleChangeReason: get(application, 'projectPeopleChangeReason', ''),
    reportDate: get(application, 'reportDate', null) ? moment(get(application, 'reportDate', null)) : null,
  };
}

function parseParentApplicationPerson(application) {
  if (!get(application, 'id', null)) {
    return null;
  }
  return parseApplicationPerson(application);
}

function parseNeDZMAdditionalFilesApplications(application) {
  return {
    id: get(application, 'id', null),
    field: get(application, 'field'),
    name: get(application, 'name'),
    letterFile_id: get(application, 'letterFile_id', null) ? parseFile(get(application, 'letterFile_id')) : null,
    wordFile_id: get(application, 'wordFile_id', null) ? parseFile(get(application, 'wordFile_id')) : null,
    pdfFile_id: get(application, 'pdfFile_id', null) ? parseFile(get(application, 'pdfFile_id')) : null,
    file_id: get(application, 'file_id', null) ? parseFile(get(application, 'file_id')) : null,
  };
}

function parseApplicationPlan(application) {
  const obj = {
    id: get(application, 'applicationsPlan_id.id', null),
    application_id: get(application, 'applicationsPlan_id.application_id', null),
    applicationStepReportExpert_id: get(application, 'applicationsPlan_id.applicationStepReportExpert_id', null),
    steps: get(application, 'applicationsPlan_id.steps', null),
  };
  Array(15).fill(0).forEach((el, index) => {
    const field1 = `n${index + 1}`;
    const field2 = `d${index + 1}`;
    const field3 = `w${index + 1}`;
    const field4 = `r${index + 1}`;
    obj[field1] = get(application, `applicationsPlan_id.${field1}`, null);
    obj[field2] = get(application, `applicationsPlan_id.${field2}`, null);
    obj[field3] = get(application, `applicationsPlan_id.${field3}`, null);
    obj[field4] = get(application, `applicationsPlan_id.${field4}`, null);
  });
  return obj;
}

function parseLinkedApplicationFiles(application) {
  const resultArray = [];
  if (application.linkedApplications_ids) {
    application.linkedApplications_ids.forEach((e) => {
      const step = e.applicationStepReport_ids.find((s) => Number(s.stepNumber) === e.maxStepsNumber);
      if (step.nirReport_ids) {
        resultArray.push(...step.nirReport_ids);
      }
    });
  }

  return resultArray;
}

function parseFullApplication(application) {
  return {
    id: get(application, 'id', null),
    webUser_id: get(application, 'webUser_id.id', null),
    displayID: get(application, 'displayID', ''),
    createDate: get(application, 'createDate', null) ? moment(get(application, 'createDate', null)) : null,
    theme: get(application, 'theme', ''),
    status: get(application, 'status', ''),
    specialization: get(application, 'specialization_id.value', ''),
    scientificDirection: get(application, 'scientificDirection_id.value', ''),
    specializationID: get(application, 'specialization_id.id', ''),
    scientificDirectionID: get(application, 'scientificDirection_id.id', ''),
    projectGoal: get(application, 'projectGoal', ''),
    projectDeadline: get(application, 'projectDeadline', null) ? moment(get(application, 'projectDeadline', null)) : null,
    projectStart: get(application, 'projectStart', null) ? moment(get(application, 'projectStart', null)) : null,
    projectFinance: get(application, 'projectFinance', ''),
    projectTasks: get(application, 'projectTasks', ''),
    projectResults: get(application, 'projectResults', ''),
    projectUse: get(application, 'projectUse', ''),
    firstName: get(application, 'firstName', ''),
    lastName: get(application, 'lastName', ''),
    middleName: get(application, 'middleName', ''),
    phone: get(application, 'phone', ''),
    email: get(application, 'email', ''),
    position: get(application, 'position', ''),
    degree: get(application, 'degree', ''),
    title: get(application, 'title', ''),
    specialistFirstName: get(application, 'specialistFirstName', ''),
    specialistLastName: get(application, 'specialistLastName', ''),
    specialistMiddleName: get(application, 'specialistMiddleName', ''),
    specialistPosition: get(application, 'specialistPosition', ''),
    specialistPhone: get(application, 'specialistPhone', ''),
    specialistEmail: get(application, 'specialistEmail', ''),
    statuses: get(application, 'statuses_ids', []).map((st) => parseStatus(st)),
    fullName: get(application, 'fullName', ''),
    shortName: get(application, 'shortName', ''),
    INN: get(application, 'INN', ''),
    legalAddress: get(application, 'legalAddress', ''),
    directorFirstName: get(application, 'directorFirstName', ''),
    directorLastName: get(application, 'directorLastName', ''),
    directorMiddleName: get(application, 'directorMiddleName', ''),
    directorPhone: get(application, 'directorPhone', ''),
    directorEmail: get(application, 'directorEmail', ''),
    directorPosition: get(application, 'directorPosition', ''),
    directorDegree: get(application, 'directorDegree', ''),
    directorTitle: get(application, 'directorTitle', ''),
    applicationType: get(application, 'applicationType', ''),
    publications: get(application, 'publications', ''),
    otherEvents: get(application, 'otherEvents', ''),
    people: get(application, 'people', ''),
    publicationsQ1Q2: get(application, 'publicationsQ1Q2', ''),
    intellectualProperty: get(application, 'intellectualProperty', ''),
    events: get(application, 'events', ''),
    detailedApplicationLink_id: get(application, 'detailedApplicationLink_id.id', null) ? parseFile(get(application, 'detailedApplicationLink_id.id', null)) : null,
    additionalFiles: get(application, 'additionalFiles_ids', []).map((f) => parseFile(f)),
    applicationAndSopd_ids: get(application, 'applicationAndSopd_ids', []).map((f) => parseFile(f)),
    grantAgreementLink: get(application, 'grantAgreement_id.urlLink', null),
    grantAppStatus: get(application, 'grantAppStatus', ''),
    agreementTotalSum: get(application, 'agreementTotalSum', 0),
    persons: get(application, 'persons_ids', []).map((f) => parseApplicationPerson(f)),
    agreementPersonsStatus: get(application, 'agreementPersonsStatus', 'На заполнении'),
    agreementPersonsComment: get(application, 'agreementPersonsComment', ''),
    grantAgreementScanLink: get(application, 'grantAgreementScan_id.urlLink', null),
    grantApplicationLink: get(application, 'grantApplication_id.urlLink', null),

    applicationStepReports: get(application, 'applicationStepReport_ids', []).map((f) => ({
      id: get(f, 'id', null),
      stepNumber: get(f, 'stepNumber', null),
      endDate: get(f, 'endDate', null) ? moment(get(f, 'endDate', null)) : null,
    })),

    employeeReportFirstName: get(application, 'employeeReportFirstName', ''),
    employeeReportLastName: get(application, 'employeeReportLastName', ''),
    employeeReportMiddleName: get(application, 'employeeReportMiddleName', ''),
    employeeReportPosition: get(application, 'employeeReportPosition', ''),
    employeeReportPhone: get(application, 'employeeReportPhone', ''),
    employeeReportEmail: get(application, 'employeeReportEmail', ''),

    isPersonApplication: get(application, 'isPersonApplication', false),
    maxStepsNumber: Number(get(application, 'maxStepsNumber', 0)),
    role: get(application, 'webUser_id.role', ''),
    grantAgreementScan_id: parseFile(get(application, 'grantAgreementScan_id', {})),
    grantAgreementScan_ids: get(application, 'grantAgreementScan_ids', []).map((f) => parseFile(f)),
    additionalAgreements_ids: get(application, 'additionalAgreements_ids', []).map((f) => parseFile(f)),
    specialistFirstNamePerson: get(application, 'specialistFirstNamePerson', ''),
    specialistLastNamePerson: get(application, 'specialistLastNamePerson', ''),
    specialistMiddleNamePerson: get(application, 'specialistMiddleNamePerson', ''),
    specialistPositionPerson: get(application, 'specialistPositionPerson', ''),
    specialistPhonePerson: get(application, 'specialistPhonePerson', ''),
    specialistEmailPerson: get(application, 'specialistEmailPerson', ''),
    neDZMAdditionalFiles_ids: get(application, 'neDZMAdditionalFiles_ids', []).map((f) => parseFile(f)),

    additionalFile6_id: get(application, 'additionalFile6_id', null) ? parseFile(get(application, 'additionalFile6_id')) : null,
    additionalFile3_id: get(application, 'additionalFile3_id', null) ? parseFile(get(application, 'additionalFile3_id')) : null,
    additionalFile1_id: get(application, 'additionalFile1_id', null) ? parseFile(get(application, 'additionalFile1_id')) : null,
    presentation_id: get(application, 'presentation_id', null) ? parseFile(get(application, 'presentation_id')) : null,
    finalAnnotation_id: get(application, 'finalAnnotation_id', null) ? parseFile(get(application, 'finalAnnotation_id')) : null,

    nirFile_id: get(application, 'nirFile_id', null) ? parseFile(get(application, 'nirFile_id')) : null,
    takeGrantRequest_id: get(application, 'takeGrantRequest_id', null) ? parseFile(get(application, 'takeGrantRequest_id')) : null,

    step1Sum: get(application, 'step1Sum'),
    step2Sum: get(application, 'step2Sum'),
    step3Sum: get(application, 'step3Sum'),
    step4Sum: get(application, 'step4Sum'),
    step5Sum: get(application, 'step5Sum'),
    step6Sum: get(application, 'step6Sum'),
    currentTransferedSum: get(application, 'currentTransferedSum', ''),
    allStepsSum: get(application, 'allStepsSum', ''),
    peopleAgreementTotalSum: get(application, 'peopleAgreementTotalSum', ''),
    peopleMainTotalSum: get(application, 'peopleMainTotalSum', ''),
    peoplePersonsTotalSum: get(application, 'peoplePersonsTotalSum', ''),
    projectGoalExpert: get(application, 'projectGoalExpert', ''),
    projectTasksExpert: get(application, 'projectTasksExpert', ''),

    isGrants2023: get(application, 'isGrants2023', false),
    numberOfPatienceTotal: get(application, 'numberOfPatienceTotal', ''),
    numberOfPatienceMain: get(application, 'numberOfPatienceMain', ''),
    numberOfPatienceControl: get(application, 'numberOfPatienceControl', ''),
    numberOfPatienceCompare: get(application, 'numberOfPatienceCompare', ''),
    numberOfActs: get(application, 'numberOfActs', ''),
    numberOfExamples: get(application, 'numberOfExamples', ''),
    numberOfPublicationsTotal: get(application, 'numberOfPublicationsTotal', ''),
    directorFullName: get(application, 'directorFullName', ''),
    supervisorFullName: get(application, 'supervisorFullName', ''),
    contactCommonFullName: get(application, 'contactCommonFullName', ''),
    contactFinanceFullName: get(application, 'contactFinanceFullName', ''),
    projectTerm: get(application, 'projectTerm', ''),
    practice: get(application, 'practice', ''),

    applicationStepsFilesOnly: get(application, 'applicationStepReport_ids', []).map((f) => parseApplicationStepReport(f)),

    parentApplicationPerson: parseParentApplicationPerson(get(application, 'applicationPersons_id')),

    applicationStepReportExpert: get(application, 'applicationStepReportExpert_ids', []).map((f) => ({
      urlLink: f.printFormUrlSigned,
      name: f.webUser_id?.shortName,
    })),

    neDZMAdditionalFilesApplications_ids: get(application, 'neDZMAdditionalFilesApplications_ids', []).map((e) => parseNeDZMAdditionalFilesApplications(e)),

    applicationsPlan: parseApplicationPlan(application),

    applicationsFiles_ids: get(application, 'applicationsFiles_ids', []).map((e) => parseNeDZMAdditionalFilesApplications(e)),

    linkedApplicationFiles: parseLinkedApplicationFiles(application),
  };
}

function parseCatalogueValue(val) {
  return {
    id: get(val, 'id', null),
    value: get(val, 'value', ''),
  };
}


export function getUsers() {
  return api
    .structure('WebUser')
    .getData('getUsers', { pageSize: '400' })
    .then((resp) => get(resp, 'payload', []).map((user) => parseUser(user)))
    .catch(handleError);
}

export function getUsersAdmin(search) {
  return api
    .structure('WebUser')
    .getData('getUsersAdmin', { pageSize: '300', ...search })
    .then((resp) => get(resp, 'payload', []).map((user) => parseUser(user)))
    .catch(handleError);
}

export function editUser(values, headers) {
  return api
    .structure('WebUser')
    .sendData('editUser', values, headers)
    .then((resp) => parseUser(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function getUser(userID, sessionID) {
  return api
    .structure('WebUser')
    .getData('getUser', {
      userID,
      sessionID,
    })
    .then((resp) => parseUser(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getAllApplications(searchData) {
  return api
    .structure('applications')
    .getData('getAllApplications', searchData)
    .then((resp) => ({ data: get(resp, 'payload', []).map((application) => parseApplication(application)), pageInfo: get(resp, 'pageInfo', {}) }))
    .catch(handleError);
}

export function getSpecializationCatalogue() {
  return api
    .structure('specialization')
    .getData('getSpecializationCatalogue', {})
    .then((resp) => get(resp, 'payload', []).map((specialization) => parseCatalogueValue(specialization)))
    .catch(handleError);
}

export function getScientificDirectionCatalogue() {
  return api
    .structure('scientificDirection')
    .getData('getScientificDirectionCatalogue', { pageSize: '100' })
    .then((resp) => get(resp, 'payload', []).map((scientificDirection) => parseCatalogueValue(scientificDirection)))
    .catch(handleError);
}

export function getApplicationByID(id) {
  return api
    .structure('applications')
    .getData('getApplicationByID', { id })
    .then((resp) => parseFullApplication(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getAllApplicationsByUser(searchData) {
  return api
    .structure('applications')
    .getData('getAllApplicationsByUser', searchData)
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplication(application)))
    .catch(handleError);
}

function parseExpertProject(val) {
  const simpleFields = ['projectGoal', 'projectTasks', 'relevance', 'novelty', 'isValueOtherText', 'isValueComment', 'influence', 'effectiveness',
    'actions', 'infrastructure', 'accordance', 'goalAchieved', 'evaluation', 'expediency', 'totalResultText'];
  const boolFields = ['isNoValue', 'isValueMoscow', 'isValueWorld', 'isValueOther', 'isValueMunicipal', 'isValuePractical'];
  const obj = {
    id: get(val, 'id', null),
    sopdStatus: get(val, 'sopdStatus', null),
    sopdDate: get(val, 'sopdDate', null) ? moment(get(val, 'sopdDate')).format('DD.MM.YYYY') : null,
    expert: get(val, 'webUser_id.shortName', ''),
    displayID: get(val, 'application_id.displayID', ''),
    status: get(val, 'application_id.application_id.status', ''),
    theme: get(val, 'application_id.theme', ''),
    projectTerm: Number(get(val, 'application_id.projectTerm', 0)),
    maxStepsNumber: Number(get(val, 'application_id.maxStepsNumber', 0)),
    applicationStepReports: get(val, 'application_id.applicationStepReport_ids', []),
    directorFirstName: `${get(val, 'application_id.firstName', '')} ${get(val, 'application_id.middleName', '')} ${get(val, 'application_id.lastName', '')}`,
    scientificDirectorFullName: `${get(val, 'application_id.lastName', '')} ${get(val, 'application_id.firstName', '')} ${get(val, 'application_id.middleName', '')}`,
    scientificDirection_id: get(val, 'application_id.scientificDirection_id.value', ''),
    webUserShortName: get(val, 'application_id.webUser_id.shortName', ''),
    materialDate: get(val, 'materialDate', null) ? moment(get(val, 'materialDate')) : null,
    conclusionDate: get(val, 'conclusionDate', null) ? moment(get(val, 'conclusionDate')) : null,
    additionalFile6_id: get(val, 'application_id.additionalFile6_id', null) ? parseFile(get(val, 'application_id.additionalFile6_id')) : null,
    additionalFile3_id: get(val, 'application_id.additionalFile3_id', null) ? parseFile(get(val, 'application_id.additionalFile3_id')) : null,
    additionalFile1_id: get(val, 'application_id.additionalFile1_id', null) ? parseFile(get(val, 'application_id.additionalFile1_id')) : null,
    presentation_id: get(val, 'application_id.presentation_id', null) ? parseFile(get(val, 'application_id.presentation_id')) : null,
    finalAnnotation_id: get(val, 'application_id.finalAnnotation_id', null) ? parseFile(get(val, 'application_id.finalAnnotation_id')) : null,
    persons: get(val, 'application_id.persons_ids', []).map((e) => parseApplicationPerson(e)),
    application_id: get(val, 'application_id.id', null),
    expert_id: get(val, 'webUser_id.id', null),
    applicationsPlan: parseApplicationPlan(val),
    financeSum: get(val, 'financeSum', ''),
    financeOption: get(val, 'financeOption', ''),
    financeReason: get(val, 'financeReason', ''),
    organisations: get(val, 'organisations', ''),
    planFactEvaluationDescription: get(val, 'planFactEvaluationDescription', ''),
    planFactEvaluation: get(val, 'planFactEvaluation', ''),
    planResult: get(val, 'planResult', ''),
    planReasonDescription: get(val, 'planReasonDescription', ''),
    planInfluence: get(val, 'planInfluence', ''),

    projectVACPlan: get(val, 'projectVACPlan', null),
    projectVACFact: get(val, 'projectVACFact', null),
    projectVACLeft: get(val, 'projectVACLeft', null),
    projectVACComment: get(val, 'projectVACComment', ''),

    projectRINCPlan: get(val, 'projectRINCPlan', null),
    projectRINCFact: get(val, 'projectRINCFact', null),
    projectRINCLeft: get(val, 'projectRINCLeft', null),
    projectRINCComment: get(val, 'projectRINCComment', ''),

    projectRIDPlan: get(val, 'projectRIDPlan', null),
    projectRIDFact: get(val, 'projectRIDFact', null),
    projectRIDLeft: get(val, 'projectRIDLeft', null),
    projectRIDComment: get(val, 'projectRIDComment', ''),

    projectEventsDemonstrationPlan: get(val, 'projectEventsDemonstrationPlan', null),
    projectEventsDemonstrationFact: get(val, 'projectEventsDemonstrationFact', null),
    projectEventsDemonstrationLeft: get(val, 'projectEventsDemonstrationLeft', null),
    projectEventsDemonstrationComment: get(val, 'projectEventsDemonstrationComment', ''),

    projectEventsPlan: get(val, 'projectEventsPlan', null),
    projectEventsFact: get(val, 'projectEventsFact', null),
    projectEventsLeft: get(val, 'projectEventsLeft', null),
    projectEventsComment: get(val, 'projectEventsComment', ''),

    projectPatientsPlan: get(val, 'projectPatientsPlan', null),
    projectPatientsFact: get(val, 'projectPatientsFact', null),
    projectPatientsLeft: get(val, 'projectPatientsLeft', null),
    projectPatientsComment: get(val, 'projectPatientsComment', ''),

    applicationsFiles_ids: get(val, 'application_id.applicationsFiles_ids', []).map((e) => parseNeDZMAdditionalFilesApplications(e)),
    linkedApplicationFiles: parseLinkedApplicationFiles(val.application_id),
    neDZMAdditionalFilesApplications_ids: get(val, 'application_id.neDZMAdditionalFilesApplications_ids', []).map((e) => parseNeDZMAdditionalFilesApplications(e)),
    grantAgreementScan_ids: get(val, 'application_id.grantAgreementScan_ids', []).map((f) => parseFile(f)),

  };
  simpleFields.forEach((f) => { obj[f] = get(val, f, ''); });
  boolFields.forEach((f) => { obj[f] = get(val, f, false); });
  return obj;
}

export function getExpertProjects() {
  return api
    .structure('applicationStepReportExpert')
    .getData('getExpertApplications', { pageSize: '100' })
    .then((resp) => get(resp, 'payload', []).map((e) => parseExpertProject(e)))
    .catch(handleError);
}

export function getExpertProjectByID(id) {
  return api
    .structure('applicationStepReportExpert')
    .getData('getApplicationStepReportExpert', { applicationId: id, pageSize: '30' })
    .then((resp) => parseExpertProject(get(resp, 'payload', [])[0]))
    .catch(handleError);
}

export function getExpertReportURL(id) {
  return api
    .structure('applicationStepReportExpert')
    .sendData('editApplicationStepReportExpertURL', { id })
    .then((resp) => get(resp, 'result[0].printFormUrl'))
    .catch(handleError);
}

export function editApplicationStepReportExpert(values, headers) {
  return api
    .structure('applicationStepReportExpert')
    .sendData('editApplicationStepReportExpert', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editExpertToApplication(values, headers) {
  return api
    .structure('expertToApplication')
    .sendData('editExpertToApplication', values, headers)
    .then((resp) => parseExpertProject(get(resp, 'result[0].applicationStepReportExpert_id')))
    .catch(handleError);
}

export function getAllApplicationsByUserMobile(id) {
  return api
    .structure('applications')
    .getData('getAllApplicationsByUserMobile', { webUserID: id })
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplication(application)))
    .catch(handleError);
}

export function getAllDraftApplicationsByUser(id) {
  return api
    .structure('applications')
    .getData('getAllDraftApplicationsByUser', { webUserID: id })
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplication(application)))
    .catch(handleError);
}

export function editApplication(values, headers) {
  return api
    .structure('applications')
    .sendData('editApplication', values, headers)
    .then((resp) => parseApplication(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function addNewApplication(values, headers) {
  return api
    .structure('applications')
    .sendData('addNewApplication', values, headers)
    .then((resp) => parseApplication(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function addApplicationStatus(values) {
  return api
    .structure('applicationStatus')
    .sendData('addApplicationStatus', values)
    .then((resp) => parseStatus(get(resp, 'result[0]', {})))
    .catch(handleError);
}


export function deleteUser(values) {
  return api
    .structure('WebUser')
    .sendData('deleteUser', values)
    .then((resp) => parseUser(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function getApplicationPDF(values) {
  return api
    .structure('applications')
    .sendData('getApplicationPDF', values)
    .then((resp) => ({
      fileLink: get(resp, 'result[0].fileLink', null),
      fileLinkPDF: get(resp, 'result[0].fileLinkPDF', null),
    }))
    .catch(handleError);
}

export function getEffectDocx(values) {
  return api
    .structure('applications')
    .sendData('getEffectDocx', values)
    .then((resp) => get(resp, 'result[0].effectDocxURL', null))
    .catch(handleError);
}

export function getPendingUsers(searchData) {
  return api
    .structure('WebUser')
    .getData('getPendingUsers', searchData)
    .then((resp) => ({
      pageInfo: get(resp, 'pageInfo', {}),
      data: get(resp, 'payload', []).map((user) => parseUser(user)),
    }))
    .catch(handleError);
}

export function generateApplicationsXLS(values) {
  return api
    .structure('generateApplicationsXLS')
    .sendData('generateApplicationsXLS', values)
    .then((resp) => get(resp, 'result[0].url', ''))
    .catch(handleError);
}

export function generateStatisticsXLS(values) {
  return api
    .structure('generateStatisticsXLS')
    .sendData('generateStatisticsXLS', values)
    .then((resp) => get(resp, 'result[0].url', ''))
    .catch(handleError);
}

export function addUserFeedback(userFeedback) {
  return api
    .structure('userFeedback')
    .sendData('addUserFeedback', userFeedback)
    .catch(handleError);
}

export function editGrantsRecoverEmail(grantsRecoverEmail) {
  return api
    .structure('grantsRecoverEmail')
    .sendData('editGrantsRecoverEmail', grantsRecoverEmail)
    .catch(handleError);
}

export function uploadFile(file, headers) {
  return api
    .structure('inputFiles')
    .sendData('uploadFile', file, headers)
    .then((resp) => parseFile(get(resp, 'result[0].file', {})))
    .catch(handleError);
}

function parseAppStatistics(app) {
  return {
    id: get(app, 'id', null),
    status: get(app, 'status', ''),
    userID: get(app, 'webUser_id', ''),
    isDeleted: get(app, 'isDeleted', false),
  };
}

export function getSecondStageApplications() {
  return api
    .structure('applications')
    .getData('getSecondStageApplications', { pageSize: '30' })
    .then((resp) => get(resp, 'payload', []).map((user) => parseAppStatistics(user)))
    .catch(handleError);
}

export function editGrantsStatistics(grantsStatistics) {
  return api
    .structure('grantsStatistics')
    .sendData('editGrantsStatistics', grantsStatistics)
    .catch(handleError);
}

export function editApplicationPersons(applicationPerson) {
  return api
    .structure('applicationPersons')
    .sendData('editApplicationPersons', applicationPerson)
    .then((resp) => parseApplicationPerson(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function generateApplicationPersonDocx(applicationPerson) {
  return api
    .structure('applicationPersons')
    .sendData('generateApplicationPersonDocx', applicationPerson)
    .then((resp) => get(resp, 'result[0].url', null))
    .catch(handleError);
}

export function sendApplicationPersonsEmail(applicationPerson) {
  return api
    .structure('applicationPersons')
    .sendData('sendApplicationPersonsEmail', applicationPerson)
    .then((resp) => get(resp, 'result[0].id', null))
    .catch(handleError);
}

export function editAplicationStep1Publications(object) {
  return api
    .structure('applicationStep1Publications')
    .sendData('editAplicationStep1Publications', object)
    .catch(handleError);
}

export function editApplicationStep1Events(object) {
  return api
    .structure('applicationStep1Events')
    .sendData('editApplicationStep1Events', object)
    .catch(handleError);
}

export function editApplicationStep1Rid(object) {
  return api
    .structure('applicationStep1Rid')
    .sendData('editApplicationStep1Rid', object)
    .catch(handleError);
}


function parseApplicationReports(application) {
  return {
    isPersonApplication: true,
    ...parseFullApplication(get(application, 'application_id', {})),
    id: get(application, 'id', null),
    displayID: get(application, 'application_id.displayID', ''),
    webUserShortName: '',
    projectFinance: '',
    grantAppStatus: '',
    agreementPersonsStatus: '',
    status: '',
    anoRequisites: get(application, 'anoRequisites', null),
    personRequisites: get(application, 'personRequisites', null),
    applicationStepReports: get(application, 'applicationStepReport_ids', []).map((f) => ({ id: get(f, 'id', null), stepNumber: get(f, 'stepNumber', null) })),
  };
}

export function getAllApplicationReports() {
  return api
    .structure('applicationReports')
    .getData('getAllApplicationReports', { pageSize: '30' })
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplicationReports(application)))
    .catch(handleError);
}

export function getApplicationReportByID(id) {
  return api
    .structure('applicationReports')
    .getData('getApplicationReportByID', { id })
    .then((resp) => parseApplicationReports(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getApplicationReportsByUser(webUserID) {
  return api
    .structure('applicationReports')
    .getData('getApplicationReportsByUser', { webUserID, pageSize: '30' })
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplicationReports(application)))
    .catch(handleError);
}

export function editApplicationReport(values, headers) {
  return api
    .structure('applicationReports')
    .sendData('editApplicationReport', values, headers)
    .then((resp) => parseApplicationReports(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function editApplicationCostsDocument(values, headers) {
  return api
    .structure('applicationCostsDocument')
    .sendData('editApplicationCostsDocument', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationCosts(values, headers) {
  return api
    .structure('applicationCosts')
    .sendData('editApplicationCosts', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationSalary(values, headers) {
  return api
    .structure('applicationSalary')
    .sendData('editApplicationSalary', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationsFinancialReportTotal(values, headers) {
  return api
    .structure('applicationsFinancialReportTotal')
    .sendData('editApplicationsFinancialReportTotal', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}


export function editApplicationStepPersonReport(values, headers) {
  return api
    .structure('applicationStepPersonReport')
    .sendData('editApplicationStepPersonReport', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationStepReport(values, headers) {
  return api
    .structure('applicationStepReport')
    .sendData('editApplicationStepReport', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function getApplicationStepReportById(id) {
  return api
    .structure('applicationStepReport')
    .getData('getApplicationStepReportById', { id })
    .then((resp) => parseApplicationStepReport(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getApplicationStepReportByIds(values) {
  return api
    .structure('applicationStepReport')
    .getData('getApplicationStepReportByIds', values)
    .then((resp) => get(resp, 'payload', []).map((e) => parseApplicationStepReport(e)))
    .catch(handleError);
}

function parseApplications2InfoTabMaterial(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    name: get(application, 'name', null),
    description: get(application, 'description', null),
  };
}

function parseApplications2InfoTabAnalog(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    organisation: get(application, 'organisation', null),
    workDescription: get(application, 'workDescription', null),
    advantage: get(application, 'advantage', null),
    analogName: get(application, 'analogName', null),
    analogDescription: get(application, 'analogDescription', null),
    cost: get(application, 'cost', null),
    isAvaliableRussia: get(application, 'isAvaliableRussia', null),
  };
}

function parseApplications2InfoTabTask(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    task: get(application, 'task', null),
  };
}

function parseApplications2ShortTabPeople(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    isMoscow: get(application, 'isMoscow', null),
    shortName: get(application, 'shortName', null),
    fullName: get(application, 'fullName', null),
    ogrn: get(application, 'ogrn', null),
    inn: get(application, 'inn', null),
    legalAddress: get(application, 'legalAddress', null),
    directorFullName: get(application, 'directorFullName', null),
    directorPosition: get(application, 'directorPosition', null),
    directorPhone: get(application, 'directorPhone', null),
    directorEmail: get(application, 'directorEmail', null),
    contactCommonFullName: get(application, 'contactCommonFullName', null),
    contactCommonPosition: get(application, 'contactCommonPosition', null),
    contactCommonPhone: get(application, 'contactCommonPhone', null),
    contactCommonEmail: get(application, 'contactCommonEmail', null),
  };
}

function parseApplications2AuthorTabPublications(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    authors: get(application, 'authors', ''),
    name: get(application, 'name', ''),
    date: get(application, 'date', ''),
    keyWords: get(application, 'keyWords', ''),
    type: get(application, 'type', ''),
    publisherName: get(application, 'publisherName', ''),
    ISSN: get(application, 'ISSN', ''),
    eISSN: get(application, 'eISSN', ''),
    ISBN: get(application, 'ISBN', ''),
    qvartil: get(application, 'qvartil', ''),
    publicationParams: get(application, 'publicationParams', ''),
    publicationURL: get(application, 'publicationURL', ''),
    DOI: get(application, 'DOI', ''),
    isPublished: get(application, 'isPublished', ''),
    publishedEmail_id: parseFile(get(application, 'publishedEmail_id', {})),
    isWebOfScience: get(application, 'isWebOfScience', ''),
    impactFactor: get(application, 'impactFactor', ''),
    isScopus: get(application, 'isScopus', ''),
    isRinc: get(application, 'isRinc', ''),
    isVac: get(application, 'isVac', ''),
    isAffiliate: get(application, 'isAffiliate', ''),
    publicationFile_id: parseFile(get(application, 'publicationFile_id', {})),
  };
}

function parseApplications2AuthorTabEvent(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    name: get(application, 'name', null),
    dates: get(application, 'dates', null),
    city: get(application, 'city', null),
    form: get(application, 'form', null),
    presenterFio: get(application, 'presenterFio', null),
    isInternational: get(application, 'isInternational', null),
    numberOfPeople: get(application, 'numberOfPeople', null),
  };
}

function parseApplications2AuthorTabRid(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    ridType: get(application, 'ridType', null),
    certificateType: get(application, 'certificateType', null),
    patentType: get(application, 'patentType', null),
    name: get(application, 'name', null),
    fio: get(application, 'fio', null),
    applicationNumber: get(application, 'applicationNumber', null),
    date: get(application, 'date', null) ? moment(get(application, 'date', null)) : null,
    documentNumber: get(application, 'documentNumber', null),
    regDate: get(application, 'regDate', null) ? moment(get(application, 'regDate', null)) : null,
    endDate: get(application, 'endDate', null) ? moment(get(application, 'endDate', null)) : null,
    annotation: get(application, 'annotation', null),
    documentURL: get(application, 'documentURL', null),
  };
}

function parseApplications2PersonsTabPeople(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    theme: get(application, 'theme', null),
    task: get(application, 'task', null),
    goal: get(application, 'goal', null),
    type: get(application, 'type', null),
    results: get(application, 'results', null),
    requirements: get(application, 'requirements', null),
    resultsRequirements: get(application, 'resultsRequirements', null),
    docsRequirements: get(application, 'docsRequirements', null),
    isStep1: get(application, 'isStep1', false),
    isStep2: get(application, 'isStep2', false),
    isStep3: get(application, 'isStep3', false),
    isStep4: get(application, 'isStep4', false),
    isStep5: get(application, 'isStep5', false),
    isStep6: get(application, 'isStep6', false),
    step1Work: get(application, 'step1Work', null),
    step1Result: get(application, 'step1Result', null),
    step1Sum: get(application, 'step1Sum', null),
    step2Work: get(application, 'step2Work', null),
    step2Result: get(application, 'step2Result', null),
    step2Sum: get(application, 'step2Sum', null),
    step3Work: get(application, 'step3Work', null),
    step3Result: get(application, 'step3Result', null),
    step3Sum: get(application, 'step3Sum', null),
    step4Work: get(application, 'step4Work', null),
    step4Result: get(application, 'step4Result', null),
    step4Sum: get(application, 'step4Sum', null),
    step5Work: get(application, 'step5Work', null),
    step5Result: get(application, 'step5Result', null),
    step5Sum: get(application, 'step5Sum', null),
    step6Work: get(application, 'step6Work', null),
    step6Result: get(application, 'step6Result', null),
    step6Sum: get(application, 'step6Sum', null),
    stepTotal: get(application, 'stepTotal', null),
    confirmEmail_id: parseFile(get(application, 'confirmEmail_id', {})),
    meri_podderjki: get(application, 'meri_podderjki', {}),
    isMoscow: get(application, 'isMoscow', null),
    shortName: get(application, 'shortName', null),
    fullName: get(application, 'fullName', null),
    ogrn: get(application, 'ogrn', null),
    inn: get(application, 'inn', null),
    legalAddress: get(application, 'legalAddress', null),
    directorFullName: get(application, 'directorFullName', null),
    directorPosition: get(application, 'directorPosition', null),
    directorPhone: get(application, 'directorPhone', null),
    directorEmail: get(application, 'directorEmail', null),
    contactCommonFullName: get(application, 'contactCommonFullName', null),
    contactCommonPosition: get(application, 'contactCommonPosition', null),
    contactCommonPhone: get(application, 'contactCommonPhone', null),
    contactCommonEmail: get(application, 'contactCommonEmail', null),
    directorSOPDFiles_ids: get(application, 'directorSOPDFiles_ids', []).map((f) => parseFile(f)),
    contactCommonSOPDFiles_ids: get(application, 'contactCommonSOPDFiles_ids', []).map((f) => parseFile(f)),
  };
}


function parseApplications2AuthorTabPeople(application) {
  return {
    id: get(application, 'id', null),
    applications2_id: get(application, 'applications2_id', null),
    lastName: get(application, 'lastName', null),
    firstName: get(application, 'firstName', null),
    middleName: get(application, 'middleName', null),
    birthDate: get(application, 'birthDate', null) ? moment(get(application, 'birthDate', null)) : null,
    education: get(application, 'education', null),
    degree: get(application, 'degree', null),
    title: get(application, 'title', null),
    achievements: get(application, 'achievements', null),
    hirsh: get(application, 'hirsh', null),
    participation: get(application, 'participation', null),
    applications2AuthorTabRid_ids: get(application, 'applications2AuthorTabRid_ids', []).map((e) => parseApplications2AuthorTabRid(e)),
    applications2AuthorTabPublications_ids: get(application, 'applications2AuthorTabPublications_ids', []).map((e) => parseApplications2AuthorTabPublications(e)),
    applications2AuthorTabEvent_ids: get(application, 'applications2AuthorTabEvent_ids', []).map((e) => parseApplications2AuthorTabEvent(e)),
    SOPDFiles_ids: get(application, 'SOPDFiles_ids', []).map((f) => parseFile(f)),

    publicationsText: get(application, 'publicationsText', null),
    ridText: get(application, 'ridText', null),
    eventsText: get(application, 'eventsText', null),
    fullName: `${get(application, 'lastName', null)} ${get(application, 'firstName', null)} ${get(application, 'middleName', null)}`,

    isRussian: get(application, 'isRussian', null),
    experience: get(application, 'experience', null),
    position: get(application, 'position', null),
  };
}

function parseApplications2Costs(application) {
  return {
    id: get(application, 'id', null),
    type: get(application, 'type', ''),
    year: get(application, 'year', ''),
    name: get(application, 'name', ''),
    description: get(application, 'description', ''),
    isTotal: get(application, 'isTotal', false),
    majorSum: get(application, 'majorSum', ''),
    minorSum: get(application, 'minorSum', ''),
  };
}

function parseApplications2ExpectedResults(application) {
  return {
    id: get(application, 'id', null),
    partsFile_id: parseFile(get(application, 'partsFile_id', {})),
    name: get(application, 'name', ''),
    type: get(application, 'type', ''),
    evaluation: get(application, 'evaluation', ''),
    partsRussia: get(application, 'partsRussia', ''),
    partsMoscow: get(application, 'partsMoscow', ''),
    partsDescription: get(application, 'partsDescription', ''),
    applications2_id: get(application, 'applications2_id', ''),
    isDeleted: get(application, 'isDeleted', ''),
  };
}

function parseApplication2(application) {
  return {
    id: get(application, 'id', null),
    declineComment: get(application, 'declineComment', null),
    year: get(application, 'year', ''),
    displayID: get(application, 'displayID', ''),
    sendOnReviewDate: get(application, 'sendOnReviewDate', null) ? moment(get(application, 'sendOnReviewDate', null)).format('DD MMMM YYYY') : null,
    createDate: get(application, 'createDate', null) ? moment(get(application, 'createDate', null)) : null,
    status: get(application, 'status', ''),
    organisationFullName: get(application, 'organisationFullName', ''),
    scientificDirection: get(application, 'scientificDirection', ''),
    projectName: get(application, 'projectName', ''),
    organisationShortName: get(application, 'organisationShortName', ''),
    organisationINN: get(application, 'organisationINN', ''),
    numberOfPublicationsTotal: get(application, 'numberOfPublicationsTotal', ''),
    numberOfPublicationsVAC: get(application, 'numberOfPublicationsVAC', ''),
    numberOfPublicationsRINC: get(application, 'numberOfPublicationsRINC', ''),
    numberOfRID: get(application, 'numberOfRID', ''),
    numberOfEvents: get(application, 'numberOfEvents', ''),
    numberOfEventsPeople: get(application, 'numberOfEventsPeople', ''),
    numberOfPerformance: get(application, 'numberOfPerformance', ''),
    numberOfPatienceTotal: get(application, 'numberOfPatienceTotal', ''),
    numberOfPatienceMain: get(application, 'numberOfPatienceMain', ''),
    numberOfPatienceControl: get(application, 'numberOfPatienceControl', ''),
    numberOfPatienceCompare: get(application, 'numberOfPatienceCompare', ''),
    numberOfActs: get(application, 'numberOfActs', ''),
    numberOfExamples: get(application, 'numberOfExamples', ''),
    projectTerm: get(application, 'projectTerm', ''),
    projectAnnotation: get(application, 'projectAnnotation', ''),
    supervisorFullName: get(application, 'supervisorFullName', ''),
    supervisorPosition: get(application, 'supervisorPosition', ''),
    supervisorDegree: get(application, 'supervisorDegree', ''),
    supervisorTitle: get(application, 'supervisorTitle', ''),
    supervisorPhone: get(application, 'supervisorPhone', ''),
    supervisorEmail: get(application, 'supervisorEmail', ''),
    supervisorIsRussian: get(application, 'supervisorIsRussian', null),
    supervisorExperience: get(application, 'supervisorExperience', null),
    directorFullName: get(application, 'directorFullName', ''),
    directorPosition: get(application, 'directorPosition', ''),
    directorPhone: get(application, 'directorPhone', ''),
    directorEmail: get(application, 'directorEmail', ''),
    contactCommonFullName: get(application, 'contactCommonFullName', ''),
    contactCommonPosition: get(application, 'contactCommonPosition', ''),
    contactCommonPhone: get(application, 'contactCommonPhone', ''),
    contactCommonEmail: get(application, 'contactCommonEmail', ''),
    contactFinanceFullName: get(application, 'contactFinanceFullName', ''),
    contactFinancePosition: get(application, 'contactFinancePosition', ''),
    contactFinancePhone: get(application, 'contactFinancePhone', ''),
    contactFinanceEmail: get(application, 'contactFinanceEmail', ''),
    isPersonsParticipate: get(application, 'isPersonsParticipate', ''),
    applications2ShortTabPeople_ids: get(application, 'applications2ShortTabPeople_ids', []).map((e) => parseApplications2ShortTabPeople(e)),
    supervisorSOPDFiles_ids: get(application, 'supervisorSOPDFiles_ids', []).map((f) => parseFile(f)),
    directorSOPDFiles_ids: get(application, 'directorSOPDFiles_ids', []).map((f) => parseFile(f)),
    contactCommonSOPDFiles_ids: get(application, 'contactCommonSOPDFiles_ids', []).map((f) => parseFile(f)),
    contactFinanceSOPDFiles_ids: get(application, 'contactFinanceSOPDFiles_ids', []).map((f) => parseFile(f)),

    conclusions_application_file: get(application, 'conclusions_application_file', null) || null,
    productDescription: get(application, 'productDescription', ''),
    productGroup: get(application, 'productGroup', ''),
    goal: get(application, 'goal', ''),
    tasks: get(application, 'tasks', ''),
    work: get(application, 'work', ''),
    result: get(application, 'result', ''),
    method: get(application, 'method', ''),
    power: get(application, 'power', ''),
    materialExist: get(application, 'materialExist', ''),
    materialToBuy: get(application, 'materialToBuy', ''),
    risk: get(application, 'risk', ''),
    kind: get(application, 'kind', ''),
    epidemic: get(application, 'epidemic', ''),
    state: get(application, 'state', ''),
    practice: get(application, 'practice', ''),
    medicalEffect: get(application, 'medicalEffect', ''),
    economicEffect: get(application, 'economicEffect', ''),
    competitor: get(application, 'competitor', ''),
    type: get(application, 'type', ''),
    epidemicFiles_ids: get(application, 'epidemicFiles_ids', []).map((f) => parseFile(f)),
    economicEffectFiles_ids: get(application, 'economicEffectFiles_ids', []).map((f) => parseFile(f)),
    medicalEffectFiles_ids: get(application, 'medicalEffectFiles_ids', []).map((f) => parseFile(f)),
    stateFiles_ids: get(application, 'stateFiles_ids', []).map((f) => parseFile(f)),
    applications2InfoTabMaterial_ids: get(application, 'applications2InfoTabMaterial_ids', []).map((e) => parseApplications2InfoTabMaterial(e)),
    applications2InfoTabAnalog_ids: get(application, 'applications2InfoTabAnalog_ids', []).map((e) => parseApplications2InfoTabAnalog(e)),
    applications2InfoTabTasks_ids: get(application, 'applications2InfoTabTasks_ids', []).map((e) => parseApplications2InfoTabTask(e)),

    participation: get(application, 'participation', ''),
    supervisorBirthDate: get(application, 'supervisorBirthDate', null) ? moment(get(application, 'supervisorBirthDate', null)) : null,
    supervisorAchievements: get(application, 'supervisorAchievements', ''),
    supervisorEducation: get(application, 'supervisorEducation', ''),
    supervisorHirsh: get(application, 'supervisorHirsh', ''),
    supervisorParticipation: get(application, 'supervisorParticipation', ''),
    young: get(application, 'young', ''),
    applications2AuthorTabRid_ids: get(application, 'applications2AuthorTabRid_ids', []).map((e) => parseApplications2AuthorTabRid(e)),
    applications2AuthorTabPublications_ids: get(application, 'applications2AuthorTabPublications_ids', []).map((e) => parseApplications2AuthorTabPublications(e)),
    applications2AuthorTabPeople_ids: get(application, 'applications2AuthorTabPeople_ids', []).map((e) => parseApplications2AuthorTabPeople(e)),
    applications2AuthorTabEvent_ids: get(application, 'applications2AuthorTabEvent_ids', []).map((e) => parseApplications2AuthorTabEvent(e)),

    applications2PersonsTabPeople_ids: get(application, 'applications2PersonsTabPeople_ids', []).map((e) => parseApplications2PersonsTabPeople(e)),

    financeTotalMajor: get(application, 'financeTotalMajor', ''),
    financeTotalMinor: get(application, 'financeTotalMinor', ''),
    financeTotalPercent: get(application, 'financeTotalPercent', ''),
    fot2023Major: get(application, 'fot2023Major', ''),
    fot2023Minor: get(application, 'fot2023Minor', ''),
    fot2024Major: get(application, 'fot2024Major', ''),
    fot2024Minor: get(application, 'fot2024Minor', ''),
    fot2025Major: get(application, 'fot2025Major', ''),
    fot2025Minor: get(application, 'fot2025Minor', ''),

    fotTotalMajor: get(application, 'fotTotalMajor', ''),
    fotTotalMinor: get(application, 'fotTotalMinor', ''),
    fotPercent: get(application, 'fotPercent', ''),
    financeTotal2023Major: get(application, 'financeTotal2023Major', ''),
    financeTotal2023Minor: get(application, 'financeTotal2023Minor', ''),
    financeTotal2024Major: get(application, 'financeTotal2024Major', ''),
    financeTotal2024Minor: get(application, 'financeTotal2024Minor', ''),
    financeTotal2025Major: get(application, 'financeTotal2025Major', ''),
    financeTotal2025Minor: get(application, 'financeTotal2025Minor', ''),

    materialTotalMajor: get(application, 'materialTotalMajor', ''),
    materialTotalMinor: get(application, 'materialTotalMinor', ''),
    materialPercent: get(application, 'materialPercent', ''),
    material2023Major: get(application, 'material2023Major', ''),
    material2023Minor: get(application, 'material2023Minor', ''),
    material2024Minor: get(application, 'material2024Minor', ''),
    material2024Major: get(application, 'material2024Major', ''),
    material2025Major: get(application, 'material2025Major', ''),
    material2025Minor: get(application, 'material2025Minor', ''),

    equipmentTotalMajor: get(application, 'equipmentTotalMajor', ''),
    equipmentTotalMinor: get(application, 'equipmentTotalMinor', ''),
    equipmentPercent: get(application, 'equipmentPercent', ''),
    equipment2023Major: get(application, 'equipment2023Major', ''),
    equipment2023Minor: get(application, 'equipment2023Minor', ''),
    equipment2024Minor: get(application, 'equipment2024Minor', ''),
    equipment2024Major: get(application, 'equipment2024Major', ''),
    equipment2025Major: get(application, 'equipment2025Major', ''),
    equipment2025Minor: get(application, 'equipment2025Minor', ''),

    otherTotalMajor: get(application, 'otherTotalMajor', ''),
    otherTotalMinor: get(application, 'otherTotalMinor', ''),
    otherPercent: get(application, 'otherPercent', ''),
    other2023Major: get(application, 'other2023Major', ''),
    other2023Minor: get(application, 'other2023Minor', ''),
    other2024Minor: get(application, 'other2024Minor', ''),
    other2024Major: get(application, 'other2024Major', ''),
    other2025Major: get(application, 'other2025Major', ''),
    other2025Minor: get(application, 'other2025Minor', ''),

    paperTotalMajor: get(application, 'paperTotalMajor', ''),
    paperTotalMinor: get(application, 'paperTotalMinor', ''),
    paperPercent: get(application, 'paperPercent', ''),
    paper2023Major: get(application, 'paper2023Major', ''),
    paper2023Minor: get(application, 'paper2023Minor', ''),
    paper2024Minor: get(application, 'paper2024Minor', ''),
    paper2024Major: get(application, 'paper2024Major', ''),
    paper2025Major: get(application, 'paper2025Major', ''),
    paper2025Minor: get(application, 'paper2025Minor', ''),

    peopleTotalMajor: get(application, 'peopleTotalMajor', ''),
    peopleTotalMinor: get(application, 'peopleTotalMinor', ''),
    peoplePercent: get(application, 'peoplePercent', ''),
    people2023Major: get(application, 'people2023Major', ''),
    people2023Minor: get(application, 'people2023Minor', ''),
    people2024Minor: get(application, 'people2024Minor', ''),
    people2024Major: get(application, 'people2024Major', ''),
    people2025Major: get(application, 'people2025Major', ''),
    people2025Minor: get(application, 'people2025Minor', ''),

    step1PlanWorkDescription: get(application, 'step1PlanWorkDescription', ''),
    step1PlanWorkResult: get(application, 'step1PlanWorkResult', ''),
    step2PlanWorkDescription: get(application, 'step2PlanWorkDescription', ''),
    step2PlanWorkResult: get(application, 'step2PlanWorkResult', ''),
    step3PlanWorkDescription: get(application, 'step3PlanWorkDescription', ''),
    step3PlanWorkResult: get(application, 'step3PlanWorkResult', ''),
    financeFiles_ids: get(application, 'financeFiles_ids', []).map((f) => parseFile(f)),
    materials_project: get(application, 'materials_project', []).map((f) => parseFile(f)),
    webUserShortName: get(application, 'webUser_id.shortName', ''),
    webUserId: get(application, 'webUser_id.id', ''),

    applications2Costs_ids: get(application, 'applications2Costs_ids', []).map((f) => parseApplications2Costs(f)),

    salaryDoctorAmount2023: get(application, 'salaryDoctorAmount2023', ''),
    salaryMiddleAmount2023: get(application, 'salaryMiddleAmount2023', ''),
    salaryJuniorAmount2023: get(application, 'salaryJuniorAmount2023', ''),
    salaryOtherAmount2023: get(application, 'salaryOtherAmount2023', ''),
    salarySupervisorDescription2023: get(application, 'salarySupervisorDescription2023', ''),
    salaryDoctorDescription2023: get(application, 'salaryDoctorDescription2023', ''),
    salaryMiddleDescription2023: get(application, 'salaryMiddleDescription2023', ''),
    salaryJuniorDescription2023: get(application, 'salaryJuniorDescription2023', ''),
    salaryOtherDescription2023: get(application, 'salaryOtherDescription2023', ''),
    salarySupervisorTotal2023: get(application, 'salarySupervisorTotal2023', ''),
    salaryDoctorTotal2023: get(application, 'salaryDoctorTotal2023', ''),
    salaryMiddleTotal2023: get(application, 'salaryMiddleTotal2023', ''),
    salaryJuniorTotal2023: get(application, 'salaryJuniorTotal2023', ''),
    salaryOtherTotal2023: get(application, 'salaryOtherTotal2023', ''),
    salarySupervisorAverageYear2023: get(application, 'salarySupervisorAverageYear2023', ''),
    salaryDoctorAverageYear2023: get(application, 'salaryDoctorAverageYear2023', ''),
    salaryMiddleAverageYear2023: get(application, 'salaryMiddleAverageYear2023', ''),
    salaryJuniorAverageYear2023: get(application, 'salaryJuniorAverageYear2023', ''),
    salaryOtherAverageYear2023: get(application, 'salaryOtherAverageYear2023', ''),
    salarySupervisorAverageMonth2023: get(application, 'salarySupervisorAverageMonth2023', ''),
    salaryDoctorAverageMonth2023: get(application, 'salaryDoctorAverageMonth2023', ''),
    salaryMiddleAverageMonth2023: get(application, 'salaryMiddleAverageMonth2023', ''),
    salaryJuniorAverageMonth2023: get(application, 'salaryJuniorAverageMonth2023', ''),
    salaryOtherAverageMonth2023: get(application, 'salaryOtherAverageMonth2023', ''),
    salaryTotal2023: get(application, 'salaryTotal2023', ''),
    salaryAverageYear2023: get(application, 'salaryAverageYear2023', ''),
    salaryAverageMonth2023: get(application, 'salaryAverageMonth2023', ''),

    salaryDoctorAmount2024: get(application, 'salaryDoctorAmount2024', ''),
    salaryMiddleAmount2024: get(application, 'salaryMiddleAmount2024', ''),
    salaryJuniorAmount2024: get(application, 'salaryJuniorAmount2024', ''),
    salaryOtherAmount2024: get(application, 'salaryOtherAmount2024', ''),
    salarySupervisorDescription2024: get(application, 'salarySupervisorDescription2024', ''),
    salaryDoctorDescription2024: get(application, 'salaryDoctorDescription2024', ''),
    salaryMiddleDescription2024: get(application, 'salaryMiddleDescription2024', ''),
    salaryJuniorDescription2024: get(application, 'salaryJuniorDescription2024', ''),
    salaryOtherDescription2024: get(application, 'salaryOtherDescription2024', ''),
    salarySupervisorTotal2024: get(application, 'salarySupervisorTotal2024', ''),
    salaryDoctorTotal2024: get(application, 'salaryDoctorTotal2024', ''),
    salaryMiddleTotal2024: get(application, 'salaryMiddleTotal2024', ''),
    salaryJuniorTotal2024: get(application, 'salaryJuniorTotal2024', ''),
    salaryOtherTotal2024: get(application, 'salaryOtherTotal2024', ''),
    salarySupervisorAverageYear2024: get(application, 'salarySupervisorAverageYear2024', ''),
    salaryDoctorAverageYear2024: get(application, 'salaryDoctorAverageYear2024', ''),
    salaryMiddleAverageYear2024: get(application, 'salaryMiddleAverageYear2024', ''),
    salaryJuniorAverageYear2024: get(application, 'salaryJuniorAverageYear2024', ''),
    salaryOtherAverageYear2024: get(application, 'salaryOtherAverageYear2024', ''),
    salarySupervisorAverageMonth2024: get(application, 'salarySupervisorAverageMonth2024', ''),
    salaryDoctorAverageMonth2024: get(application, 'salaryDoctorAverageMonth2024', ''),
    salaryMiddleAverageMonth2024: get(application, 'salaryMiddleAverageMonth2024', ''),
    salaryJuniorAverageMonth2024: get(application, 'salaryJuniorAverageMonth2024', ''),
    salaryOtherAverageMonth2024: get(application, 'salaryOtherAverageMonth2024', ''),
    salaryTotal2024: get(application, 'salaryTotal2024', ''),
    salaryAverageYear2024: get(application, 'salaryAverageYear2024', ''),
    salaryAverageMonth2024: get(application, 'salaryAverageMonth2024', ''),

    salaryDoctorAmount2025: get(application, 'salaryDoctorAmount2025', ''),
    salaryMiddleAmount2025: get(application, 'salaryMiddleAmount2025', ''),
    salaryJuniorAmount2025: get(application, 'salaryJuniorAmount2025', ''),
    salaryOtherAmount2025: get(application, 'salaryOtherAmount2025', ''),
    salarySupervisorDescription2025: get(application, 'salarySupervisorDescription2025', ''),
    salaryDoctorDescription2025: get(application, 'salaryDoctorDescription2025', ''),
    salaryMiddleDescription2025: get(application, 'salaryMiddleDescription2025', ''),
    salaryJuniorDescription2025: get(application, 'salaryJuniorDescription2025', ''),
    salaryOtherDescription2025: get(application, 'salaryOtherDescription2025', ''),
    salarySupervisorTotal2025: get(application, 'salarySupervisorTotal2025', ''),
    salaryDoctorTotal2025: get(application, 'salaryDoctorTotal2025', ''),
    salaryMiddleTotal2025: get(application, 'salaryMiddleTotal2025', ''),
    salaryJuniorTotal2025: get(application, 'salaryJuniorTotal2025', ''),
    salaryOtherTotal2025: get(application, 'salaryOtherTotal2025', ''),
    salarySupervisorAverageYear2025: get(application, 'salarySupervisorAverageYear2025', ''),
    salaryDoctorAverageYear2025: get(application, 'salaryDoctorAverageYear2025', ''),
    salaryMiddleAverageYear2025: get(application, 'salaryMiddleAverageYear2025', ''),
    salaryJuniorAverageYear2025: get(application, 'salaryJuniorAverageYear2025', ''),
    salaryOtherAverageYear2025: get(application, 'salaryOtherAverageYear2025', ''),
    salarySupervisorAverageMonth2025: get(application, 'salarySupervisorAverageMonth2025', ''),
    salaryDoctorAverageMonth2025: get(application, 'salaryDoctorAverageMonth2025', ''),
    salaryMiddleAverageMonth2025: get(application, 'salaryMiddleAverageMonth2025', ''),
    salaryJuniorAverageMonth2025: get(application, 'salaryJuniorAverageMonth2025', ''),
    salaryOtherAverageMonth2025: get(application, 'salaryOtherAverageMonth2025', ''),
    salaryTotal2025: get(application, 'salaryTotal2025', ''),
    salaryAverageYear2025: get(application, 'salaryAverageYear2025', ''),
    salaryAverageMonth2025: get(application, 'salaryAverageMonth2025', ''),

    salaryTotal: get(application, 'salaryTotal', ''),
    salaryAverageYear: get(application, 'salaryAverageYear', ''),
    salaryAverageMonth: get(application, 'salaryAverageMonth', ''),

    supervisorPublicationsText: get(application, 'supervisorPublicationsText', ''),
    supervisorRIDText: get(application, 'supervisorRIDText', ''),
    supervisorEventsText: get(application, 'supervisorEventsText', ''),

    printFormFull: get(application, 'printFormFull', null),
    printFormFullSigned: get(application, 'printFormFullSigned.urlLink', null),

    isGeneratePDF: get(application, 'isGeneratePDF', false),

    supervisorRIDFiles_ids: get(application, 'supervisorRIDFiles_ids', []).map((f) => parseFile(f)),

    requisites: get(application, 'requisites', ''),

    applications2ExpectedResults_ids: get(application, 'applications2ExpectedResults_ids', []).map((f) => parseApplications2ExpectedResults(f)),
    projectEndDateDate: get(application, 'projectEndDateDate', null) ? moment(get(application, 'projectEndDateDate', null)) : null,

    epidemicMoscow: get(application, 'epidemicMoscow', ''),
    epidemicMoscowLink: get(application, 'epidemicMoscowLink', ''),
    epidemicRussia: get(application, 'epidemicRussia', ''),
    epidemicRussiaLink: get(application, 'epidemicRussiaLink', ''),
    epidemicWorld: get(application, 'epidemicWorld', ''),
    epidemicWorldLink: get(application, 'epidemicWorldLink', ''),
    epidemicMoscowAdditional: get(application, 'epidemicMoscowAdditional', ''),
    epidemicRussiaAdditional: get(application, 'epidemicRussiaAdditional', ''),
    economyRussia: get(application, 'economyRussia', ''),
    economyMoscow: get(application, 'economyMoscow', ''),
    economyAdditional: get(application, 'economyAdditional', ''),
    isEconomyCheaper: get(application, 'isEconomyCheaper', ''),
    isPowerPeople: get(application, 'isPowerPeople', null) ? get(application, 'isPowerPeople') === 'true' : null,
    isNoAnalogs: get(application, 'isNoAnalogs', false),
    powerPeopleReason: get(application, 'powerPeopleReason', ''),
    resultValues: get(application, 'resultValues', ''),
    resultSubType: get(application, 'resultSubType', ''),
    resultType: get(application, 'resultType', ''),

    isPatent1: get(application, 'isPatent1', ''),
    isPatent2: get(application, 'isPatent2', ''),
    isWorldPatent1: get(application, 'isWorldPatent1', ''),
    isWorldPatent2: get(application, 'isWorldPatent2', ''),
    isHelpPatent1: get(application, 'isHelpPatent1', ''),
    isHelpPatent2: get(application, 'isHelpPatent2', ''),
    orgProducer1: get(application, 'orgProducer1', ''),
    orgProducer2: get(application, 'orgProducer2', ''),
    isHelpPlace: get(application, 'isHelpPlace', ''),
    isRegPaper: get(application, 'isRegPaper', ''),
    isRegPapeDuringGrant: get(application, 'isRegPapeDuringGrant', ''),
    isRegPapeDuringGrantHelpCenter: get(application, 'isRegPapeDuringGrantHelpCenter', ''),
    regPaperRightsOwner: get(application, 'regPaperRightsOwner', ''),
    isClinicChangesRequired: get(application, 'isClinicChangesRequired', ''),
    isClinicChangesPlaned: get(application, 'isClinicChangesPlaned', ''),
    isMarketEntry: get(application, 'isMarketEntry', ''),
    isPartnerExist: get(application, 'isPartnerExist', ''),
    partnerFullName: get(application, 'partnerFullName', ''),
    isPartnerHelp: get(application, 'isPartnerHelp', ''),
    continueDescription: get(application, 'continueDescription', ''),
    whyBetterAnalog: get(application, 'whyBetterAnalog', ''),

    customScientificDirection: get(application, 'customScientificDirection', ''),
    economyCheaperReason: get(application, 'economyCheaperReason', ''),

    isDeadline: get(application, 'isDeadline', false),
    reviewDate: get(application, 'reviewDate', null) ? moment(get(application, 'reviewDate', null)) : null,
    fixTillDate: get(application, 'fixTillDate', null) ? moment(get(application, 'fixTillDate', null)) : null,
    row11Comment: get(application, 'row11Comment', ''),
    row11CheckBox: get(application, 'row11CheckBox', false),
    row12Comment: get(application, 'row12Comment', ''),
    row12CheckBox: get(application, 'row12CheckBox', false),
    row21Comment: get(application, 'row21Comment', ''),
    row21CheckBox: get(application, 'row21CheckBox', false),
    row22Comment: get(application, 'row22Comment', ''),
    row22CheckBox: get(application, 'row22CheckBox', false),
    row23Comment: get(application, 'row23Comment', ''),
    row23CheckBox: get(application, 'row23CheckBox', false),
    row31Comment: get(application, 'row31Comment', ''),
    row31CheckBox: get(application, 'row31CheckBox', false),
    row32Comment: get(application, 'row32Comment', ''),
    row32CheckBox: get(application, 'row32CheckBox', false),
    row33Comment: get(application, 'row33Comment', ''),
    row33CheckBox: get(application, 'row33CheckBox', false),
    row4Comment: get(application, 'row4Comment', ''),
  };
}

export function editApplications2ExpectedResults(values, headers) {
  return api
    .structure('applications2ExpectedResults')
    .sendData('editApplications2ExpectedResults', values, headers)
    .then((resp) => parseApplications2ExpectedResults(get(resp, 'result[0]')))
    .catch(handleError);
}

export function editApplications2(values, headers) {
  return api
    .structure('applications2')
    .sendData('editApplications2', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function getApplications2ById(id) {
  return api
    .structure('applications2')
    .getData('getApplications2ById', { id })
    .then((resp) => parseApplication2(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getApplications2(searchObj) {
  return api
    .structure('applications2')
    .getData('getApplications2', searchObj)
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplication2(application)))
    .catch(handleError);
}

export function getAllGrantsApplications2(searchObj) {
  return api
    .structure('applications2')
    .getData('export_table', searchObj)
    .then((resp) => get(resp, 'payload', []).map((application) => parseApplication2(application)))
    .catch(handleError);
}

export function editApplications2ShortTabPeople(values, headers) {
  return api
    .structure('applications2ShortTabPeople')
    .sendData('editApplications2ShortTabPeople', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2InfoTabMaterial(values, headers) {
  return api
    .structure('applications2InfoTabMaterial')
    .sendData('editApplications2InfoTabMaterial', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2InfoTabAnalog(values, headers) {
  return api
    .structure('applications2InfoTabAnalog')
    .sendData('editApplications2InfoTabAnalog', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2InfoTabTask(values, headers) {
  return api
    .structure('applications2InfoTabTasks')
    .sendData('editApplications2InfoTabTask', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2AuthorTabRid(values, headers) {
  return api
    .structure('applications2AuthorTabRid')
    .sendData('editApplications2AuthorTabRid', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2AuthorTabEvent(values, headers) {
  return api
    .structure('applications2AuthorTabEvent')
    .sendData('editApplications2AuthorTabEvent', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2AuthorTabPeople(values, headers) {
  return api
    .structure('applications2AuthorTabPeople')
    .sendData('editApplications2AuthorTabPeople', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2AuthorTabPublications(values, headers) {
  return api
    .structure('applications2AuthorTabPublications')
    .sendData('editApplications2AuthorTabPublications', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2PersonsTabPeople(values, headers) {
  return api
    .structure('applications2PersonsTabPeople')
    .sendData('editApplications2PersonsTabPeople', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function generateFinancialReport(values, headers) {
  return api
    .structure('applicationStepReport')
    .sendData('generateFinancialReport', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function generateAnnotationReport(values, headers) {
  return api
    .structure('applicationStepReport')
    .sendData('generateAnnotationReport', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplications2Costs(values, headers) {
  return api
    .structure('applications2Costs')
    .sendData('editApplications2Costs', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function generateFullReport(values, headers) {
  return api
    .structure('applications2')
    .sendData('generateFullReport', values, headers)
    .then((resp) => get(resp, 'result[0].printFormFull'))
    .catch(handleError);
}

export function editApplicationStepReportStepSevenRows(values, headers) {
  return api
    .structure('applicationStepReportStepSevenRows')
    .sendData('editApplicationStepReportStepSevenRows', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

function parseAgreement(obj) {
  const docs = [];
  const printFormAgreementFull = get(obj, 'printFormAgreementFull', null);
  const printFormAgreementFive = get(obj, 'printFormAgreementFive', null);
  const printFormAgreementFour = get(obj, 'printFormAgreementFour', null);
  if (printFormAgreementFull) {
    docs.push(printFormAgreementFull);
  }
  if (printFormAgreementFive) {
    docs.push(printFormAgreementFive);
  }
  if (printFormAgreementFour) {
    docs.push(printFormAgreementFour);
  }
  get(obj, 'applications2PersonsTabPeople_ids', [])
    .forEach((p) => {
      const printFormAgreement = get(p, 'printFormAgreement', null);
      const printFormNIR = get(p, 'printFormNIR', null);
      if (printFormAgreement) {
        docs.push(printFormAgreement);
      }
      if (printFormNIR) {
        docs.push(printFormNIR);
      }
    });
  return docs;
}

export function editApplicationAgreement(values, headers) {
  return api
    .structure('applications2')
    .sendData('editApplicationAgreement', values, headers)
    .then((resp) => ({ docs: parseAgreement(get(resp, 'result[0]')) }))
    .catch(handleError);
}

export function getAllApplicationStepReportExpert(values) {
  return api
    .structure('applicationStepReportExpert')
    .getData('getAllApplicationStepReportExpert', { pageSize: '100', ...values })
    .then((resp) => get(resp, 'payload', []).map((e) => parseExpertProject(e)))
    .catch(handleError);
}

export function getSupportedApplications() {
  return api
    .structure('applications')
    .getData('getSupportedApplications', { pageSize: '300' })
    .then((resp) => get(resp, 'payload', []).map((application) => parseFullApplication(application)))
    .catch(handleError);
}

function parseApplicationSurvey(application) {
  return {
    id: get(application, 'id', null),
    displayId: get(application, 'displayId', null),
    theme: get(application, 'application_id.theme', null),
    orgName: get(application, 'orgName', null),
    numberOfPublicationsTotalPlan: get(application, 'numberOfPublicationsTotalPlan', null),
    numberOfPublicationsTotalFact: get(application, 'numberOfPublicationsTotalFact', null),
    publicationsPlan: get(application, 'publicationsPlan', null),
    publicationsFact: get(application, 'publicationsFact', null),
    publicationsQ1Q2Plan: get(application, 'publicationsQ1Q2Plan', null),
    publicationsQ1Q2Fact: get(application, 'publicationsQ1Q2Fact', null),
    intellectualPropertyPlan: get(application, 'intellectualPropertyPlan', null),
    intellectualPropertyFact: get(application, 'intellectualPropertyFact', null),
    eventsPlan: get(application, 'eventsPlan', null),
    eventsFact: get(application, 'eventsFact', null),
    otherEventsPlan: get(application, 'otherEventsPlan', null),
    otherEventsFact: get(application, 'otherEventsFact', null),
    fio: get(application, 'fio', null),
    peoplePlan: get(application, 'peoplePlan', null),
    peopleFact: get(application, 'peopleFact', null),
    numberOfPatienceTotalPlan: get(application, 'numberOfPatienceTotalPlan', null),
    numberOfPatienceTotalFact: get(application, 'numberOfPatienceTotalFact', null),
    numberOfPatienceMainPlan: get(application, 'numberOfPatienceMainPlan', null),
    numberOfPatienceMainFact: get(application, 'numberOfPatienceMainFact', null),
    numberOfPatienceControlPlan: get(application, 'numberOfPatienceControlPlan', null),
    numberOfPatienceControlFact: get(application, 'numberOfPatienceControlFact', null),
    numberOfPatienceComparePlan: get(application, 'numberOfPatienceComparePlan', null),
    numberOfPatienceCompareFact: get(application, 'numberOfPatienceCompareFact', null),
    numberOfActsPlan: get(application, 'numberOfActsPlan', null),
    numberOfActsFact: get(application, 'numberOfActsFact', null),
    numberOfExamplesPlan: get(application, 'numberOfExamplesPlan', null),
    numberOfExamplesFact: get(application, 'numberOfExamplesFact', null),
    applicationSurveyFiles_ids: get(application, 'applicationSurveyFiles_ids', []).map((e) => ({
      id: get(e, 'id'),
      fileName: get(e, 'fileName'),
      file: get(e, 'file_id.id', null) ? parseFile(get(e, 'file_id', null)) : null,
      fieldName: get(e, 'fieldName'),
    })),
    isDone: get(application, 'isDone', false),
  };
}

export function getApplicationSurvey(searchData) {
  return api
    .structure('applicationSurvey')
    .getData('getApplicationSurvey', searchData)
    .then((resp) => get(resp, 'payload', []).map((a) => parseApplicationSurvey(a)))
    .catch(handleError);
}

export function editApplicationSurvey(values, headers) {
  return api
    .structure('applicationSurvey')
    .sendData('editApplicationSurvey', values, headers)
    .then((resp) => parseApplicationSurvey(get(resp, 'result[0]')))
    .catch(handleError);
}

export function editApplicationSurveyFiles(values, headers) {
  return api
    .structure('applicationSurveyFiles')
    .sendData('editApplicationSurveyFiles', values, headers)
    .then((resp) => parseApplicationSurvey(get(resp, 'result[0]')))
    .catch(handleError);
}

function parseApplicationSmeta(application) {
  return {
    id: get(application, 'id', null),
    projectTerm: get(application, 'application_id.maxStepsNumber', null),
    isGrants2023: get(application, 'application_id.isGrants2023', false),
    applicationId: get(application, 'application_id.id', false),

    financeTotalMajor: get(application, 'financeTotalMajor', ''),
    financeTotalMinor: get(application, 'financeTotalMinor', ''),
    financeTotalPercent: get(application, 'financeTotalPercent', ''),
    fot2022Major: get(application, 'fot2022Major', ''),
    fot2022Minor: get(application, 'fot2022Minor', ''),
    fot2023Major: get(application, 'fot2023Major', ''),
    fot2023Minor: get(application, 'fot2023Minor', ''),
    fot2024Major: get(application, 'fot2024Major', ''),
    fot2024Minor: get(application, 'fot2024Minor', ''),
    fot2025Major: get(application, 'fot2025Major', ''),
    fot2025Minor: get(application, 'fot2025Minor', ''),
    fot2026Major: get(application, 'fot2026Major', ''),
    fot2026Minor: get(application, 'fot2026Minor', ''),

    fotTotalMajor: get(application, 'fotTotalMajor', ''),
    fotTotalMinor: get(application, 'fotTotalMinor', ''),
    fotPercent: get(application, 'fotPercent', ''),
    financeTotal2022Major: get(application, 'financeTotal2022Major', ''),
    financeTotal2022Minor: get(application, 'financeTotal2022Minor', ''),
    financeTotal2023Major: get(application, 'financeTotal2023Major', ''),
    financeTotal2023Minor: get(application, 'financeTotal2023Minor', ''),
    financeTotal2024Major: get(application, 'financeTotal2024Major', ''),
    financeTotal2024Minor: get(application, 'financeTotal2024Minor', ''),
    financeTotal2025Major: get(application, 'financeTotal2025Major', ''),
    financeTotal2025Minor: get(application, 'financeTotal2025Minor', ''),
    financeTotal2026Major: get(application, 'financeTotal2026Major', ''),
    financeTotal2026Minor: get(application, 'financeTotal2026Minor', ''),

    materialTotalMajor: get(application, 'materialTotalMajor', ''),
    materialTotalMinor: get(application, 'materialTotalMinor', ''),
    materialPercent: get(application, 'materialPercent', ''),
    material2022Major: get(application, 'material2022Major', ''),
    material2022Minor: get(application, 'material2022Minor', ''),
    material2023Major: get(application, 'material2023Major', ''),
    material2023Minor: get(application, 'material2023Minor', ''),
    material2024Minor: get(application, 'material2024Minor', ''),
    material2024Major: get(application, 'material2024Major', ''),
    material2025Major: get(application, 'material2025Major', ''),
    material2025Minor: get(application, 'material2025Minor', ''),
    material2026Major: get(application, 'material2026Major', ''),
    material2026Minor: get(application, 'material2026Minor', ''),

    equipmentTotalMajor: get(application, 'equipmentTotalMajor', ''),
    equipmentTotalMinor: get(application, 'equipmentTotalMinor', ''),
    equipmentPercent: get(application, 'equipmentPercent', ''),
    equipment2022Major: get(application, 'equipment2022Major', ''),
    equipment2022Minor: get(application, 'equipment2022Minor', ''),
    equipment2023Major: get(application, 'equipment2023Major', ''),
    equipment2023Minor: get(application, 'equipment2023Minor', ''),
    equipment2024Minor: get(application, 'equipment2024Minor', ''),
    equipment2024Major: get(application, 'equipment2024Major', ''),
    equipment2025Major: get(application, 'equipment2025Major', ''),
    equipment2025Minor: get(application, 'equipment2025Minor', ''),
    equipment2026Major: get(application, 'equipment2026Major', ''),
    equipment2026Minor: get(application, 'equipment2026Minor', ''),

    otherTotalMajor: get(application, 'otherTotalMajor', ''),
    otherTotalMinor: get(application, 'otherTotalMinor', ''),
    otherPercent: get(application, 'otherPercent', ''),
    other2022Major: get(application, 'other2022Major', ''),
    other2022Minor: get(application, 'other2022Minor', ''),
    other2023Major: get(application, 'other2023Major', ''),
    other2023Minor: get(application, 'other2023Minor', ''),
    other2024Minor: get(application, 'other2024Minor', ''),
    other2024Major: get(application, 'other2024Major', ''),
    other2025Major: get(application, 'other2025Major', ''),
    other2025Minor: get(application, 'other2025Minor', ''),
    other2026Major: get(application, 'other2026Major', ''),
    other2026Minor: get(application, 'other2026Minor', ''),

    paperTotalMajor: get(application, 'paperTotalMajor', ''),
    paperTotalMinor: get(application, 'paperTotalMinor', ''),
    paperPercent: get(application, 'paperPercent', ''),
    paper2022Major: get(application, 'paper2022Major', ''),
    paper2022Minor: get(application, 'paper2022Minor', ''),
    paper2023Major: get(application, 'paper2023Major', ''),
    paper2023Minor: get(application, 'paper2023Minor', ''),
    paper2024Minor: get(application, 'paper2024Minor', ''),
    paper2024Major: get(application, 'paper2024Major', ''),
    paper2025Major: get(application, 'paper2025Major', ''),
    paper2025Minor: get(application, 'paper2025Minor', ''),
    paper2026Major: get(application, 'paper2026Major', ''),
    paper2026Minor: get(application, 'paper2026Minor', ''),

    peopleTotalMajor: get(application, 'peopleTotalMajor', ''),
    peopleTotalMinor: get(application, 'peopleTotalMinor', ''),
    peoplePercent: get(application, 'peoplePercent', ''),
    people2022Major: get(application, 'people2022Major', ''),
    people2022Minor: get(application, 'people2022Minor', ''),
    people2023Major: get(application, 'people2023Major', ''),
    people2023Minor: get(application, 'people2023Minor', ''),
    people2024Minor: get(application, 'people2024Minor', ''),
    people2024Major: get(application, 'people2024Major', ''),
    people2025Major: get(application, 'people2025Major', ''),
    people2025Minor: get(application, 'people2025Minor', ''),
    people2026Major: get(application, 'people2026Major', ''),
    people2026Minor: get(application, 'people2026Minor', ''),
  };
}

export function getApplicationSmeta(searchData) {
  return api
    .structure('applicationSmeta')
    .getData('getApplicationSmeta', { pageSize: '1', ...searchData })
    .then((resp) => get(resp, 'payload', []).map((a) => parseApplicationSmeta(a))[0])
    .catch(handleError);
}

export function editApplicationSmeta(values, headers) {
  return api
    .structure('applicationSmeta')
    .sendData('editApplicationSmeta', values, headers)
    .then((resp) => parseApplicationSmeta(get(resp, 'result[0]')))
    .catch(handleError);
}

function parseApplicationPayment(application) {
  return {
    id: get(application, 'id', null),
    maxStepsNumber: Number(get(application, 'maxStepsNumber', 0)),
    allStepTransferedTotal: Number(get(application, 'allStepTransferedTotal', 0)),

    step1Date: get(application, 'step1Date', null) ? moment(get(application, 'step1Date', null)) : null,
    step1Requisites: get(application, 'step1Requisites', null),
    step1Transfered: get(application, 'step1Transfered', null),
    step1Comment: get(application, 'step1Comment', null),
    step1TransferedTotal: get(application, 'step1TransferedTotal', null),

    step2Date: get(application, 'step2Date', null) ? moment(get(application, 'step2Date', null)) : null,
    step2Requisites: get(application, 'step2Requisites', null),
    step2Transfered: get(application, 'step2Transfered', null),
    step2Comment: get(application, 'step2Comment', null),
    step2TransferedTotal: get(application, 'step2TransferedTotal', null),

    step3Date: get(application, 'step3Date', null) ? moment(get(application, 'step3Date', null)) : null,
    step3Requisites: get(application, 'step3Requisites', null),
    step3Transfered: get(application, 'step3Transfered', null),
    step3Comment: get(application, 'step3Comment', null),
    step3TransferedTotal: get(application, 'step3TransferedTotal', null),

    step4Date: get(application, 'step4Date', null) ? moment(get(application, 'step4Date', null)) : null,
    step4Requisites: get(application, 'step4Requisites', null),
    step4Transfered: get(application, 'step4Transfered', null),
    step4Comment: get(application, 'step4Comment', null),
    step4TransferedTotal: get(application, 'step4TransferedTotal', null),

    step5Date: get(application, 'step5Date', null) ? moment(get(application, 'step5Date', null)) : null,
    step5Requisites: get(application, 'step5Requisites', null),
    step5Transfered: get(application, 'step5Transfered', null),
    step5Comment: get(application, 'step5Comment', null),
    step5TransferedTotal: get(application, 'step5TransferedTotal', null),

    step6Date: get(application, 'step6Date', null) ? moment(get(application, 'step6Date', null)) : null,
    step6Requisites: get(application, 'step6Requisites', null),
    step6Transfered: get(application, 'step6Transfered', null),
    step6Comment: get(application, 'step6Comment', null),
    step6TransferedTotal: get(application, 'step6TransferedTotal', null),

    step1DateClose: get(application, 'step1DateClose', null) ? moment(get(application, 'step1DateClose', null)) : null,
    step1RequisitesClose: get(application, 'step1RequisitesClose', null),
    step1TransferedClose: get(application, 'step1TransferedClose', null),
    step1CommentClose: get(application, 'step1CommentClose', null),

    step2DateClose: get(application, 'step2DateClose', null) ? moment(get(application, 'step2DateClose', null)) : null,
    step2RequisitesClose: get(application, 'step2RequisitesClose', null),
    step2TransferedClose: get(application, 'step2TransferedClose', null),
    step2CommentClose: get(application, 'step2CommentClose', null),

    step3DateClose: get(application, 'step3DateClose', null) ? moment(get(application, 'step3DateClose', null)) : null,
    step3RequisitesClose: get(application, 'step3RequisitesClose', null),
    step3TransferedClose: get(application, 'step3TransferedClose', null),
    step3CommentClose: get(application, 'step3CommentClose', null),

    step4DateClose: get(application, 'step4DateClose', null) ? moment(get(application, 'step4DateClose', null)) : null,
    step4RequisitesClose: get(application, 'step4RequisitesClose', null),
    step4TransferedClose: get(application, 'step4TransferedClose', null),
    step4CommentClose: get(application, 'step4CommentClose', null),

    step5DateClose: get(application, 'step5DateClose', null) ? moment(get(application, 'step5DateClose', null)) : null,
    step5RequisitesClose: get(application, 'step5RequisitesClose', null),
    step5TransferedClose: get(application, 'step5TransferedClose', null),
    step5CommentClose: get(application, 'step5CommentClose', null),

    step6DateClose: get(application, 'step6DateClose', null) ? moment(get(application, 'step6DateClose', null)) : null,
    step6RequisitesClose: get(application, 'step6RequisitesClose', null),
    step6TransferedClose: get(application, 'step6TransferedClose', null),
    step6CommentClose: get(application, 'step6CommentClose', null),

    stepNDate: get(application, 'stepNDate', null) ? moment(get(application, 'stepNDate', null)) : null,
    stepNRequisites: get(application, 'stepNRequisites', null),
    stepNTransfered: get(application, 'stepNTransfered', null),
    stepNComment: get(application, 'stepNComment', null),

    isGrants2023: get(application, 'application_id.isGrants2023', false),
  };
}

export function getApplicationPayment(searchData) {
  return api
    .structure('applicationPayment')
    .getData('getApplicationPayment', { pageSize: '1', ...searchData })
    .then((resp) => get(resp, 'payload', []).map((a) => parseApplicationPayment(a))[0])
    .catch(handleError);
}

export function editApplicationPayment(values, headers) {
  return api
    .structure('applicationPayment')
    .sendData('editApplicationPayment', values, headers)
    .then((resp) => parseApplicationPayment(get(resp, 'result[0]')))
    .catch(handleError);
}

function parseApplicationForFinancePerson(application) {
  return {
    id: get(application, 'id', null),
    form: get(application, 'form', null),
    totalSum: get(application, 'totalSum', null),
    allStepTransferedTotal: get(application, 'allStepTransferedTotal', null),

    ...parseApplicationPayment(application),
  };
}

function parseApplicationForFinance(application) {
  return {
    ...parseApplicationPayment(get(application, 'applicationPayment_id')),

    id: get(application, 'id', null),
    maxStepsNumber: Number(get(application, 'maxStepsNumber', 0)),
    webUserId: get(application, 'webUser_id.id', null),
    webUserName: get(application, 'webUser_id.shortName', null),
    displayID: get(application, 'displayID', null),
    theme: get(application, 'theme', null),
    allStepsSum: get(application, 'allStepsSum', null),
    peopleMainTotalSum: get(application, 'peopleMainTotalSum', null),
    currentTransferedSum: get(application, 'currentTransferedSum', null),
    isGrants2023: get(application, 'isGrants2023', false),

    persons: get(application, 'persons_ids', []).map((p) => parseApplicationForFinancePerson(p)),

  };
}

export function getApplicationsForFinance() {
  return api
    .structure('applications')
    .getData('getApplicationsForFinance', { pageSize: '150' })
    .then((resp) => get(resp, 'payload', []).map((a) => parseApplicationForFinance(a)))
    .catch(handleError);
}

function parseGreatArchive(object) {
  let obj = {
    id: get(object, 'id', null),
    isFolder: get(object, 'isFolder', false),
    parentId: get(object, 'parent_id', null),
    name: get(object, 'name', ''),
    createDate: get(object, 'createDate', null) ? moment(get(object, 'createDate', null)).format('DD MMMM YYYY HH:mm') : null,
    author: get(object, 'author_id.shortName', ''),
  };
  if (!obj.isFolder) {
    obj = {
      ...obj,
      ...parseFile(get(object, 'file_id', {})),
      id: get(object, 'id', null),
    };
  }
  return obj;
}

export function getGreatArchive(searchData) {
  return api
    .structure('greatArchive')
    .getData('getGreatArchive', searchData)
    .then((resp) => ({
      data: get(resp, 'payload', []).map((a) => parseGreatArchive(a)),
      total: get(resp, 'pageInfo.tableSize', 0),
    }))
    .catch(handleError);
}

export function editGreatArchive(values, headers) {
  return api
    .structure('greatArchive')
    .sendData('editGreatArchive', values, headers)
    .then((resp) => parseGreatArchive(get(resp, 'result[0]')))
    .catch(handleError);
}

function parseApplicationSurveyPostmonitoringRID(application) {
  return {
    id: get(application, 'id', null),
    name: get(application, 'name', null),
    number: get(application, 'number', null),
    owner: get(application, 'owner', null),
    type: get(application, 'type', null),
  };
}

function parseApplicationSurveyPostmonitoringResult(application) {
  return {
    id: get(application, 'id', null),
    addiction: get(application, 'addiction', null),
    addictionAdditional1: get(application, 'addictionAdditional1', null),
    analys: get(application, 'analys', []),
    analysAdditional1: get(application, 'analysAdditional1', null),
    analysAdditional2: get(application, 'analysAdditional2', null),
    analysAdditional3: get(application, 'analysAdditional3', []),
    analysAdditional3Additional1: get(application, 'analysAdditional3Additional1', null),
    analysAdditional4: get(application, 'analysAdditional4', null),
    analysAdditional4Additional1: get(application, 'analysAdditional4Additional1', null),
    barrier: get(application, 'barrier', []),
    barrierAdditional1: get(application, 'barrierAdditional1', null),
    barrierAdditional2: get(application, 'barrierAdditional2', null),
    barrierAdditional3: get(application, 'barrierAdditional3', null),
    barrierAdditional4: get(application, 'barrierAdditional4', null),
    barrierAdditional5: get(application, 'barrierAdditional5', null),
    barrierAdditional6: get(application, 'barrierAdditional6', null),
    foreignAnalogues: get(application, 'foreignAnalogues', []).map((e) => ({ value: e, id: uuidv4() })),
    help: get(application, 'help', null),
    helpAdditional1: get(application, 'helpAdditional1', null),
    helpAdditional2: get(application, 'helpAdditional2', null),
    helpAdditional1Additional1: get(application, 'helpAdditional1Additional1', null),
    level: get(application, 'level', []),
    levelAdditional1: get(application, 'levelAdditional1', null),
    place: get(application, 'place', null),
    placeAdditional1: get(application, 'placeAdditional1', []),
    placeAdditional1Additional1: get(application, 'placeAdditional1Additional1', null),
    result: get(application, 'result', null),
    resultAdditional1: get(application, 'resultAdditional1', null),
    resultName: get(application, 'resultName', null),
    revenue: get(application, 'revenue', null),
    revenueAdditional1: get(application, 'revenueAdditional1', null),
    russianAnalogues: get(application, 'russianAnalogues', []).map((e) => ({ value: e, id: uuidv4() })),
    techLevel: get(application, 'techLevel', null),
    usersOrg: get(application, 'usersOrg', null),
    usersPatience: get(application, 'usersPatience', null),
  };
}

function parseApplicationSurveyPostmonitoring(application) {
  return {
    id: get(application, 'id', null),
    displayID: get(application, 'application_id.displayID', null),
    theme: get(application, 'application_id.theme', null),
    webUser: get(application, 'webUser_id.shortName', null),
    endDate: get(application, 'application_id.endDate', null) ? moment(get(application, 'application_id.endDate', null)).format('DD-MM-YYYY') : null,
    applicationSurveyPostmonitoringResult_ids: get(application, 'applicationSurveyPostmonitoringResult_ids', []).map((a) => parseApplicationSurveyPostmonitoringResult(a)),
    patentName: get(application, 'patentName', null),
    patentNumber: get(application, 'patentNumber', null),
    patentOwner: get(application, 'patentOwner', null),
    programName: get(application, 'programName', null),
    programNumber: get(application, 'programNumber', null),
    programOwner: get(application, 'programOwner', null),
    paperName: get(application, 'paperName', null),
    paperNumber: get(application, 'paperNumber', null),
    paperOwner: get(application, 'paperOwner', null),
    patentPlan: get(application, 'patentPlan', null),
    programPlan: get(application, 'programPlan', null),
    paperPlan: get(application, 'paperPlan', null),
    applicationSurveyPostmonitoringRID_ids: get(application, 'applicationSurveyPostmonitoringRID_ids', []).map((e) => parseApplicationSurveyPostmonitoringRID(e)),
  };
}

export function editApplicationSurveyPostmonitoringResult(values, headers) {
  return api
    .structure('applicationSurveyPostmonitoringResult')
    .sendData('editApplicationSurveyPostmonitoringResult', values, headers)
    .then((resp) => parseApplicationSurveyPostmonitoringResult(get(resp, 'result[0]')))
    .catch(handleError);
}

export function editApplicationSurveyPostmonitoring(values, headers) {
  return api
    .structure('applicationSurveyPostmonitoring')
    .sendData('editApplicationSurveyPostmonitoring', values, headers)
    .then((resp) => parseApplicationSurveyPostmonitoringResult(get(resp, 'result[0]')))
    .catch(handleError);
}

export function getApplicationSurveyPostmonitoring(searchData) {
  return api
    .structure('applicationSurveyPostmonitoring')
    .getData('getApplicationSurveyPostmonitoring', searchData)
    .then((resp) => ({
      data: get(resp, 'payload', []).map((a) => parseApplicationSurveyPostmonitoring(a)),
      total: get(resp, 'pageInfo.tableSize', 0),
    }))
    .catch(handleError);
}

export function editApplicationSurveyPostmonitoringRID(values, headers) {
  return api
    .structure('applicationSurveyPostmonitoringRID')
    .sendData('editApplicationSurveyPostmonitoringRID', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationsFiles(values, headers) {
  return api
    .structure('applicationsFiles')
    .sendData('editApplicationsFiles', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationsPlan(values, headers) {
  return api
    .structure('applicationsPlan')
    .sendData('editApplicationsPlan', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function editApplicationsPlanExpert(values, headers) {
  return api
    .structure('applicationsPlanExpert')
    .sendData('editApplicationsPlanExpert', values, headers)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function generateConclusionFile(values, headers) {
  return api
    .structure('conclusions_application')
    .sendData('setConclusionsApplication', values, headers)
    .then((resp) => resp)
    .catch(handleError);
}

export function getAgreementData(id) {
  return api
    .structure('agreement')
    .getData('agreement_create', { id })
    .then((resp) => resp)
    .catch(handleError);
}

export function updateAgreementData(values, headers) {
  return api
    .structure('agreement')
    .sendData('agreement_create', values, headers)
    .then((resp) => resp)
    .catch(handleError);
}