import React from 'react';
import {
  Row, Col, Popconfirm, Button,
} from 'antd';

const getPaymentValue = (key, object) => {

  if (!object[`${key}Major`]) {
    return ''
  }

  return `${object[`${key}Major`].toString().length === 1 ? `0${object[`${key}Major`]}` : object[`${key}Major`]} руб. ${object[`${key}Minor`]?.toString().length === 1 ? `0${object[`${key}Minor`]}` : object[`${key}Minor`]} коп. `
};


const getNewSalaryPaymentsColumns = (onEditClick, onDeleteClick, onAddClick, financialIsSalaryDone) => [
  {
    title: 'Член коллектива специалистов (ФИО)',
    dataIndex: 'fio',
    key: 'fio',
    render: (text, object) => (object.isAdd && !financialIsSalaryDone ? <Button type="primary" onClick={onAddClick}>Добавить</Button> : text),
  },
  {
    title: 'Роль в проекте/профессия',
    dataIndex: 'rolePosition',
    key: 'rolePosition',
    render: (_, object) => (object.isAdd || object.isTotal ? '' : <div>{`${object.role}/${object.position}`}</div>),
  },
  {
    title: 'Всего начислено',
    dataIndex: 'totalPaid',
    key: 'totalPaid',
    render: (_, object) => (object.isAdd ? '' : <div>{getPaymentValue('totalPaid', object)}</div>),
  },
  {
    title: 'в т.ч. НДФЛ',
    dataIndex: 'ndfl',
    key: 'ndfl',
    render: (_, object) => (object.isAdd ? '' : <div>{getPaymentValue('ndfl', object)}</div>),
  },
  {
    title: 'ЕНП',
    dataIndex: 'enp',
    key: 'enp',
    render: (_, object) => (object.isAdd ? '' : <div>{getPaymentValue('enp', object)}</div>),
  },
  {
    title: 'ИТОГО по сотруднику',
    dataIndex: 'totalPerson',
    key: 'totalPerson',
    render: (_, object) => (object.isAdd ? '' : <div>{getPaymentValue('totalPerson', object)}</div>),
  },
  {
    title:
  <Col>
    <Row>Кассовый  расход</Row>
  </Col>,
    dataIndex: 'actualPaid',
    key: 'actualPaid',
    render: (_, object) => (object.isAdd ? '' : <div>{getPaymentValue('actualPaid', object)}</div>),
  },
  {
    title: 'Остаток к уплате',
    dataIndex: 'remain',
    key: 'remain',
    render: (_, object) => (object.isAdd ? '' : <div>{getPaymentValue('remain', object)}</div>),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '5%',
    render: (_, object) => (
      <div>
        {!object.isTotal && !object.isAdd && !financialIsSalaryDone && (
        <Col gutter={5}>
          <Row style={{ cursor: 'pointer' }}><Button type="link" style={{ padding: 0 }} onClick={() => onEditClick(object)}>изменить</Button></Row>
          <Row style={{ cursor: 'pointer' }}>
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={() => onDeleteClick(object)}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="link" style={{ padding: 0 }}>удалить</Button>
            </Popconfirm>
          </Row>
        </Col>
        )}
      </div>
    ),
  },
];

export default getNewSalaryPaymentsColumns;
