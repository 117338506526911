/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes, { func } from 'prop-types';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  Form,
  Input,
  Select,
  InputNumber,
  Upload,
  Col,
  Popconfirm,
  Row,
  DatePicker,
  Spin,
  Card,
  Button,
  notification,
  Anchor,
} from 'antd';
import {
  InboxOutlined,
  DownloadOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import getPlanRow from './getPlanRow';
import ProjectInfoPerson from './ProjectInfoPerson';
import './ProjectInfo.css';
import organisations from '../../organisations';
import { numberToWords } from '../../../../utils/numberToWords';
import {
  getAgreementData,
  updateAgreementData,
  editApplications2,
  editApplications2PersonsTabPeople,
  getApplications2ById,
} from '../../../../utils/http';
import getUserRole from '../../../../utils/localeStorage/getUserRole';


const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const calcTotalByKeys = (el, keys) => {
  let sum = 0;

  keys.forEach((key) => {
    sum
      += parseFloat(
        `${el[`${key}Major`] || 0}.${`${el[`${key}Minor`]}`.length === 1
          ? `0${el[`${key}Minor`]}`
          : el[`${key}Minor`]
        }`,
      ) * 100;
  });

  const major = Math.trunc(sum / 100) === 0 && sum < 0
    ? `-0${Math.trunc(sum / 100)}`
    : Math.trunc(sum / 100);
  const minor = Number((sum % 100).toFixed(0));

  if (major === 0) {
    return '';
  }

  return { major, minor };
};

function parseDate(date) {
  const dateString = date?.replace('года', '');
  return date ? moment(dateString, 'DD MMMM YYYY') : moment();
}

const getWordedNumber = (major, minor) => {
  const summ = `${numberToWords(major)} рублей ${numberToWords(minor)} копеек`;
  const firstLetter = summ.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = summ.slice(1);

  return `${firstLetterCap}${remainingLetters}`;
};

function ProjectInfo({ applicationProp, onRedirectToList }) {
  const [agreementData, setAgreementData] = useState();
  const [application, setApplication] = useState(applicationProp);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const disabled = true;

  const fetchAgreementData = async () => {
    const { id } = application;
    const resp = await getAgreementData(id);

    const data = resp.payload.find((agreement) => agreement.application === id);
    if (data) {
      setAgreementData(data);
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchAgreementData().then(() => {
      setLoading(false);
    });
  }, []);

  const hospitalTotal = calcTotalByKeys(application, [
    'fotTotal',
    'equipmentTotal',
    'materialTotal',
    'otherTotal',
    'paperTotal',
  ]);

  const onSaveForm = async () => {
    const values = formRef.current.getFieldsValue();

    if (values.dolznost_rod === application.directorPosition) {
      notification.error({
        message: 'Должность должна быть в родительном падеже',
      });
      return;
    }

    if (values.fio_rod === application.directorFullName) {
      notification.error({
        message: 'ФИО должно быть в родительном падеже',
      });
      return;
    }

    values.money_sum_minor = hospitalTotal.minor || 100;
    values.money_sum_major = hospitalTotal.major || 100;

    if (values.meri_podderjki?.length) {
      try {
        const promises = values.meri_podderjki?.map((el) => editApplications2PersonsTabPeople({
          id: el.id,
          meri_podderjki: el,
        }));
        await Promise.all(promises);
      } catch (error) {
        notification.error({
          message: 'Произошла ошибка при сохранении данных',
        });
      }
    }

    delete values.meri_podderjki;

    Object.entries(values).forEach(([key, value]) => {
      if (key === '') {
        delete values[key];
      }

      if (key.includes('date')) {
        values[key] = value.format('DD MMMM YYYY года');
      }
    });

    setLoading(true);
    await updateAgreementData(values);
    await fetchAgreementData();
    const newApplicationData = await getApplications2ById(application.id);
    setApplication(newApplicationData);
    setLoading(false);
  };

  const handlePepChange = () => {
    const fields = formRef.current.getFieldsValue();

    formRef.current.setFieldsValue({
      ...fields,
      ppep_3: parseInt(fields.ppep_3_1, 10) + application?.numberOfPerformance,
    });
  };

  const handleCancelProject = async () => {
    setLoading(true);
    editApplications2({
      id: application.id,
      status: 'Поддержана',
    }).then(() => onRedirectToList());
  };

  return (
    <>
      {loading ? (
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />
          }
          style={{ position: 'absolute', top: '40%', left: '40%' }}
        />
      ) : (
        <Form ref={formRef} className="project-info">
          <Row style={{ marginBottom: '15px', justifyContent: 'flex-end' }}>
            <Col>
              <Anchor offsetTop={20}>
                <Button
                  type="primary"
                  onClick={onSaveForm}
                  style={{ marginRight: '15px' }}
                >
                  Сохранить
                </Button>
                <Button
                  type="primary"
                  onClick={() => notification.info({
                    message: 'Данный функционал еще в разработке',
                  })}
                  style={{ marginRight: '15px' }}
                >
                  Сформировать соглашение
                </Button>
                {application?.id
                  && getUserRole() === 'bigAdmin'
                  && application?.status === 'Поддержана' && (
                    <Popconfirm
                      okText="Вернуть в заявку"
                      cancelText="Отмена"
                      onConfirm={handleCancelProject}
                      title="Вы уверены, что хотите вернуть в заявку?"
                    >
                      <Button
                        type="danger"
                        style={{ marginRight: '10px' }}
                        disabled={loading}
                      >
                        Вернуть в заявку
                      </Button>
                    </Popconfirm>
                  )}
              </Anchor>
            </Col>
          </Row>
          <Card
            style={{
              width: '100%',
              borderRadius: '15px',
              marginTop: '20px',
              height: '100%',
            }}
          >
            <div className="shortTab-wrapper">
              <Form.Item
                name="application"
                initialValue={application.id}
                hidden
              />
              <Form.Item name="id" initialValue={agreementData?.id} hidden />

              <div className="common-subTitle" style={{ fontSize: '20px' }}>
                <b>
                  Краткие сведения о Грантополучателе для генерации Соглашения
                  на предоставление гранта
                </b>
              </div>

              <div
                className="common-table-wrapper"
                style={{ marginBottom: '0' }}
              >
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Документ на право подписи уполномоченного лица со стороны
                    Грантополучателя (указывается с большой буквы);
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="osnovanie"
                    initialValue={agreementData?.osnovanie}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
              </div>

              <div className="application-main-box-content-wrapper">
                <Dragger
                  showUploadList={false}
                  onChange={(file) => null}
                  disabled={loading}
                  multiple
                  action={null}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Выберите или перенесите файл, чтобы загрузить (не более
                    15мб). Для прикрепления нескольких файлов осуществляйте их
                    загрузку по очереди, а не одновременно
                  </p>
                </Dragger>
                {/* {materials_files_field.map((f) => (
                        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                          {!loading && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile('materials_files_field', f)} /></Col>}
                          <Col>{f.name}</Col>
                        </Row>
                      ))} */}
              </div>

              <div className="common-table-wrapper">
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{
                      borderRight: '1px solid #d9d9d9',
                      borderBottom: '1px solid #d9d9d9',
                    }}
                  >
                    Должность руководителя в
                    {' '}
                    <b>родительном падеже</b>
                    {' '}
                    (указывается со строчной буквы)
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="dolznost_rod"
                    initialValue={
                      agreementData?.dolznost_rod
                      || application.directorPosition?.toLowerCase()
                    }
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{
                      borderRight: '1px solid #d9d9d9',
                      borderBottom: '1px solid #d9d9d9',
                    }}
                  >
                    ФИО руководителя в
                    {' '}
                    <b>родительном падеже</b>
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="fio_rod"
                    initialValue={
                      agreementData?.fio_rod || application.directorFullName
                    }
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <Form.Item
                  hidden
                  style={{ width: '100%' }}
                  name="amoun_sum_propis"
                  initialValue={
                    agreementData?.amoun_sum_propis
                    || getWordedNumber(
                      application.financeTotalMajor,
                      application.financeTotalMinor,
                    )
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  hidden
                  name="money_sum_propis"
                  initialValue={
                    agreementData?.money_sum_propis
                    || getWordedNumber(
                      hospitalTotal.major || 0,
                      hospitalTotal.minor || 0,
                    )
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  hidden
                  name="people_total_propis"
                  initialValue={
                    agreementData?.people_total_propis
                    || getWordedNumber(
                      application.peopleTotalMajor,
                      application.peopleTotalMinor,
                    )
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  name="step1_sum_propis"
                  hidden
                  initialValue={
                    agreementData?.step1_sum_propis
                    || getWordedNumber(
                      application.financeTotal2023Major,
                      application.financeTotal2023Minor,
                    )
                  }
                >
                  <Input />
                </Form.Item>
                {(application.projectTerm === '2'
                  || application.projectTerm === '3') && (
                    <Form.Item
                      hidden
                      style={{ width: '100%' }}
                      name="step2_sum_propis"
                      initialValue={
                        agreementData?.step2_sum_propis
                        || getWordedNumber(
                          application.financeTotal2024Major,
                          application.financeTotal2024Minor,
                        )
                      }
                    >
                      <Input />
                    </Form.Item>
                  )}
                {application.projectTerm === '3' && (
                  <Form.Item
                    hidden
                    style={{ width: '100%' }}
                    name="step3_sum_propis"
                    initialValue={
                      agreementData?.step3_sum_propis
                      || getWordedNumber(
                        application.financeTotal2025Major,
                        application.financeTotal2025Minor,
                      )
                    }
                  >
                    <Input />
                  </Form.Item>
                )}
              </div>

              <hr style={{ margin: '40px 0px' }} />

              <div className="common-subTitle" style={{ fontSize: '20px' }}>
                <b>Основания для заключения Соглашения с Грантополучателем</b>
              </div>

              <div className="common-table-wrapper">
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Протокол СГВ от (ДД.ММ.ГГГГ);
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="protokol_date"
                    initialValue={parseDate(agreementData?.protokol_date)}
                  >
                    <DatePicker format="DD MMMM YYYY года" />
                  </Form.Item>
                </div>

                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Приказ Медтеха № (указать);
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="prikaz_no"
                    initialValue={agreementData?.prikaz_no || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    От (ДД.ММ.ГГГГ);
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="prikaz_date"
                    initialValue={parseDate(agreementData?.prikaz_date)}
                  >
                    <DatePicker format="DD MMMM YYYY года" />
                  </Form.Item>
                </div>
              </div>

              <hr style={{ margin: '40px 0px' }} />

              <div className="common-subTitle" style={{ fontSize: '20px' }}>
                <b>Банковские реквизиты Грантополучателя</b>
              </div>
              <div
                className="common-subTitle"
                style={{ fontSize: '16px', fontStyle: 'italic' }}
              >
                <b>
                  Укажите в соответствии с учетной карточкой (реквизитов)
                  организации
                </b>
              </div>

              <div className="common-table-wrapper">
                <div className="common-table-row">
                  <div className="common-table-row-cell">ОГРН</div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="ogrn"
                    initialValue={agreementData?.ogrn || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div className="common-table-row-cell">ИНН</div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="inn"
                    initialValue={agreementData?.inn || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="common-table-row">
                  <div className="common-table-row-cell">КПП</div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="kpp"
                    initialValue={agreementData?.kpp || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Юридический адрес:
                    {' '}
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="ur_address"
                    initialValue={agreementData?.ur_address || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Получатель:
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="poluchatel"
                    initialValue={agreementData?.poluchatel || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Банк:
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="bank"
                    initialValue={agreementData?.bank || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    К/с:
                    {' '}
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="ks"
                    initialValue={agreementData?.ks || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Р/с:
                    {' '}
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="rs"
                    initialValue={agreementData?.rs || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    БИК:
                    {' '}
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="bik"
                    initialValue={agreementData?.bik || ''}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Дополнительная информация:
                    {' '}
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="other_info_requisits"
                    initialValue={agreementData?.other_info_requisits || ''}
                  >
                    <TextArea
                      placeholder="Введите текст"
                      autoSize={{ minRows: 2 }}
                    />
                  </Form.Item>
                </div>
              </div>

              <hr style={{ margin: '40px 0px' }} />

              <div className="common-subTitle" style={{ marginTop: '40px' }}>
                <b>План-график Грантополучателя</b>
              </div>
              {/* <span className="common-field-hint">Проект может выполняться от 1 года до 3-х лет. Каждый календарный год выполнения проекта соответствует 1 этапу (каждый этап длится до 12 месяцев).</span> */}
              {/* <br /> */}
              {/* <span className="common-field-hint">Перечень разрабатываемых документов по каждому этапу формирует заявитель, при этом в отчетную документацию должны быть обязательно включены аннотированный и финансовый отчеты. Аннотированный отчет предполагает краткое описание полученных научных результатов за соответствующий период и формируется по результатам завершения Проекта.</span> */}
              {/* <br /> */}
              {/* <span className="common-field-hint">Сведения, указанные в графе «Ожидаемый результат» должны содержать в себе перечень результатов, которые будут получены в соответствии с содержанием выполняемых работ и мероприятий по каждому конкретному этапу. Ожидаемый результат также должен содержать количественные показатели выполняемых работ и мероприятий (например: количество проб/объектов для проведения исследования и т.п.). Пример: Проведены масс-спектрометрические измерения 1000 образцов плазмы крови. Подготовлена база данных (в формате .xls), содержащая результаты измерений 1000 образцов плазмы крови.</span> */}
              <div
                className="common-table-wrapper"
                style={{ marginTop: '10px' }}
              >
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '4%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    № п/п
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Наименование этапа
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Содержание выполняемых работ и мероприятий
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Дата начала и окончания выполнения работ в рамках этапа
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Ожидаемый результат
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Перечень разрабатываемых документов
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      borderRight: '1px solid #d9d9d9',
                    }}
                  >
                    Финансирование (руб.)
                  </div>
                </div>

                {['1', '2', '3'].includes(application.projectTerm)
                  && getPlanRow(
                    1,
                    application,
                    disabled,
                    Number(application.projectTerm),
                    agreementData,
                  )}
                {['2', '3'].includes(application.projectTerm)
                  && getPlanRow(
                    2,
                    application,
                    disabled,
                    Number(application.projectTerm),
                    agreementData,
                  )}
                {['3'].includes(application.projectTerm)
                  && getPlanRow(
                    3,
                    application,
                    disabled,
                    Number(application.projectTerm),
                    agreementData,
                  )}

                <div
                  className="common-table-row"
                  style={{ borderBottom: '1px solid #d9d9d9' }}
                >
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '84%',
                      textAlign: 'right',
                      fontWeight: 'bold',
                    }}
                  >
                    ИТОГО
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{
                      width: '16%',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderRight: '1px solid #d9d9d9',
                    }}
                  >
                    <Form.Item
                      style={{ width: '55%' }}
                      initialValue={application.financeTotalMajor || 0}
                    >
                      <InputNumber
                        value={application.financeTotalMajor}
                        disabled
                      />
                    </Form.Item>
                    <span>руб.</span>
                    <Form.Item
                      style={{ width: '25%' }}
                      initialValue={application.financeTotalMinor || 0}
                    >
                      <InputNumber
                        value={application.financeTotalMinor}
                        disabled
                      />
                    </Form.Item>
                    <span>коп.</span>
                  </div>
                </div>
              </div>

              <hr style={{ margin: '40px 0px' }} />

              <div className="common-subTitle" style={{ fontSize: '20px' }}>
                <b>
                  Техническое задание Грантополучателя на выполнение работ в
                  рамках проекта
                </b>
              </div>

              <div className="common-table-wrapper">
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Цель, которая должна быть достигнута по завершении
                    реализации Проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_target"
                    initialValue={agreementData?.tz_total_target || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>

                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Подробное описание работ, подлежащих выполнению, в рамках
                    реализации Проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_works"
                    initialValue={agreementData?.tz_total_works || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Требования (в том числе технические) к работам, выполняемым
                    в рамках реализации Проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_trebovania_1"
                    initialValue={agreementData?.tz_total_trebovania_1 || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Перечень результатов работ, которые должны быть получены по
                    завершении реализации Проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_perechen_1"
                    initialValue={agreementData?.tz_total_perechen_1 || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Требования к результатам работ по Проекту
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_trebovania_2"
                    initialValue={agreementData?.tz_total_trebovania_2 || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Перечень разрабатываемых документов
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_perechen_2"
                    initialValue={agreementData?.tz_total_perechen_2 || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{
                      borderRight: '1px solid #d9d9d9',
                      borderBottom: '1px solid #d9d9d9',
                    }}
                  >
                    Требования к разрабатываемой документации
                    {' '}
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="tz_total_trebovania_3"
                    initialValue={agreementData?.tz_total_trebovania_3 || ''}
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </div>
              </div>

              <hr style={{ margin: '40px 0px' }} />

              <div className="common-subTitle" style={{ fontSize: '20px' }}>
                <b>Планируемые показатели эффективности проекта</b>
              </div>

              <div className="common-table-wrapper">
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    1.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Общее количество публикаций о проекте по завершении его
                    реализации:
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue={
                      application?.numberOfPublicationsTotal || ''
                    }
                  >
                    <Input
                      value={application?.numberOfPublicationsTotal}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    1.1.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    В научных журналах перечня ВАК с импакт-фактором
                    {' '}
                    {'>'}
                    {' '}
                    0,5
                    (на момент публикации материала)
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue={
                      application?.numberOfPublicationsVAC || ''
                    }
                  >
                    <Input
                      value={application?.numberOfPublicationsVAC}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    1.2.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    В научных журналах, индексируемыХ РИНЦ
                  </div>
                  <Form.Item
                    disabled
                    style={{ width: '100%' }}
                    initialValue={
                      application?.numberOfPublicationsRINC || ''
                    }
                  >
                    <Input
                      value={application?.numberOfPublicationsRINC}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    2.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Количество результатов интеллектуальной деятельности,
                    планируемых по завершении реализации проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue={
                      application?.numberOfPublicationsRINC || ''
                    }
                  >
                    <Input
                      value={application?.numberOfPublicationsRINC}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    3.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Количество мероприятий по демонстрации результатов
                    реализации проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="ppep_3"
                    initialValue={
                      (parseInt(agreementData?.ppep_3_1 || '0', 10)
                        || parseInt(application?.numberOfEvents || '0', 10))
                      + parseInt(application?.numberOfPerformance || '0', 10) || ''
                    }
                  >
                    <Input disabled />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    3.1.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Всероссийского и регионального уровней
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="ppep_3_1"
                    initialValue={
                      agreementData?.ppep_3_1
                      || application?.numberOfEvents
                      || ''
                    }
                  >
                    <Input onChange={handlePepChange} />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9', width: '60px' }}
                  >
                    3.2.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                  >
                    Международного уровня
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue={application?.numberOfPerformance || ''}
                  >
                    <Input
                      value={application?.numberOfPerformance}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="common-table-row">
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9', width: '60px' }}
                  >
                    4.
                  </div>
                  <div
                    className="common-table-row-cell"
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                  >
                    Общее количество пациентов, предусмотренных для реализации
                    проекта
                  </div>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue={application?.numberOfPatienceTotal || ''}
                  >
                    <Input
                      value={application?.numberOfPatienceTotal}
                      disabled
                    />
                  </Form.Item>
                </div>
              </div>

              {application.applications2PersonsTabPeople_ids.length > 0 && (
                <>
                  <hr style={{ margin: '40px 0px' }} />

                  <div className="common-subTitle" style={{ fontSize: '20px' }}>
                    <b>Меры поддержки</b>
                  </div>

                  {application.applications2PersonsTabPeople_ids.map(
                    (item, index) => (
                      <ProjectInfoPerson item={item} key={item.fullName} index={index} />
                    ),
                  )}
                </>
              )}
            </div>

            <Button
              type="primary"
              onClick={onSaveForm}
              style={{ marginTop: '15px' }}
            >
              Сохранить
            </Button>
          </Card>
        </Form>
      )}
    </>
  );
}

ProjectInfo.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  onRedirectToList: PropTypes.func.isRequired,
  // updateOriginalObject: PropTypes.func.isRequired,
};

export default ProjectInfo;
