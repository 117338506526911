import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Navigation from './Navigation/Navigation';
import Common from './Common/Common';
import { getApplications2ById } from '../../utils/http';
import { routes } from '../../utils/routes';
import './ApplicationStep2.css';
import ProjectInfo from './Common/ProjectInfo/ProjectInfo';


function ApplicationStep2({ history }) {
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);
  const [view, setView] = useState('common');
  const onChangeView = (newView) => setView(newView);

  useEffect(() => {
    const id = history.location.pathname.split('/')[2];
    getApplications2ById(id)
      .then((resp) => {
        setLoading(false);
        setApplication(resp);
      });
  }, []);

  const onRedirectToList = () => history.push(routes.homePage);

  return (
    <div className="applicationStep2-wrapper">
      {application && (
      <Navigation
        view={view}
        application={application}
        onChangeView={onChangeView}
      />
      )}
      {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '40%' }} />}
      {view === 'common' && application && <Common applicationProp={application} onRedirectToList={onRedirectToList} />}
      {view === 'project' && application && <ProjectInfo applicationProp={application} onRedirectToList={onRedirectToList} />}
    </div>
  );
}

ApplicationStep2.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ApplicationStep2);
