/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */


import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, Alert, Input, InputNumber, Upload, Radio, DatePicker,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
import ReportPublicationsModule from '../AnnotationReportComponents/ReportPublicationsModule';
import ReportEventsModule from '../AnnotationReportComponents/ReportEventsModule';
import ReportRidModule from '../AnnotationReportComponents/ReportRidModule';
// import SectionSevenTable from '../AnnotationReportComponents/SectionSevenTable';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';


const { TextArea } = Input;
const { Dragger } = Upload;
function NewAnnotationReport({
  application, isFormDisabled, disabled, onUploadFile, onRemoveFile, projectActsFiles_ids, projectShortAnnotationFiles,
  annotationFormRef,
}) {
  const steps = Array(application.maxStepsNumber).fill(0);
  const [isProjectProtocols, setIsProjectProtocols] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [deviations, setDeviations] = useState({});
  const stepsCatalogue = [
    {
      ano: application.ano1date,
      fin: application.fin1date,
    },
    { fin: application.fin2date },
    { fin: application.fin3date },
    { fin: application.fin4date },
    { fin: application.fin5date },
    { fin: application.fin6date },
  ];

  useEffect(() => {
    setIsProjectProtocols(application.projectProtocols);
    setSelectedValues(application);
    const newDeviations = {};
    steps.forEach((_, index) => {
      if (application[`step${index + 1}ReportDateAnnotation`]) {
        const deviation = stepsCatalogue[index].ano.diff(application[`step${index + 1}ReportDateAnnotation`], 'days');
        newDeviations[`step${index + 1}DeviationAnnotation`] = deviation < 0 ? -deviation : 0;
      }
      if (application[`step${index + 1}ReportDateFinance`]) {
        const deviation = stepsCatalogue[index].fin.diff(application[`step${index + 1}ReportDateFinance`], 'days');
        newDeviations[`step${index + 1}DeviationFinance`] = deviation < 0 ? -deviation : 0;
      }
    });
    setDeviations(newDeviations);
  }, []);

  const onChangeStepReportDate = (date, field, index, type) => {
    const compareDate = stepsCatalogue[index][type];
    const deviation = compareDate.diff(date, 'days');
    const newDeviation = { ...deviations };
    newDeviation[field] = deviation < 0 ? -deviation : 0;
    setDeviations(newDeviation);
    if (deviation === 0) {
      let fullType = '';
      if (type === 'ano') {
        fullType = 'Annotation';
      }
      if (type === 'fin') {
        fullType = 'Finance';
      }
      annotationFormRef.current.setFieldsValue({ [`step${index + 1}ReportComment${fullType}`]: '' });
    }
  };

  const onChangeFact = (field, value) => {
    setSelectedValues({ ...selectedValues, [field]: value });
  };

  const onChangeFactObjects = (amount, field) => {
    annotationFormRef.current.setFieldsValue({ [field]: amount });
    setSelectedValues({ ...selectedValues, [field]: amount });
  };

  const isHideFinancialReport = application.id === '1c19bdf2-7e5d-4199-b88b-42870627443e';

  return (
    <Col>
      <Alert
        style={{ margin: '20px 0px' }}
        description="Уважаемые грантополучатели! Внесите информацию для формирования Аннотированного отчета о достижении значений результатов предоставления гранта на реализацию научно-практического проекта в сфере медицины"
        type="warning"
      />
      <div className="application-main-box-content-wrapper">
        <div className="application-main-box-label">Дата подачи отчета:</div>
        <Form.Item
          name="reportDate"
          initialValue={application.reportDate}
        >
          <DatePicker disabled={disabled} placeholder="Дата" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Фамилия Руководителя коллектива специалистов</span>
        <Form.Item
          name="agreementLastNameSpecialist"
          initialValue={application.agreementLastNameSpecialist}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Имя Руководителя коллектива специалистов</span>
        <Form.Item
          name="agreementFirstNameSpecialist"
          initialValue={application.agreementFirstNameSpecialist}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Отчество Руководителя коллектива специалистов (при наличии)</span>
        <Form.Item
          name="agreementMiddleNameSpecialist"
          initialValue={application.agreementMiddleNameSpecialist}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Контактный телефон Руководителя коллектива специалистов</span>
        <Form.Item
          name="agreementPhoneSpecialist"
          initialValue={application.agreementPhoneSpecialist}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Контактный адрес электронной почты Руководителя коллектива специалистов</span>
        <Form.Item
          name="agreementEmailSpecialist"
          initialValue={application.agreementEmailSpecialist}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Фамилия Руководителя организации-грантополучателя</span>
        <Form.Item
          name="agreementLastNameDirector"
          initialValue={application.agreementLastNameDirector}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Имя Руководителя организации-грантополучателя</span>
        <Form.Item
          name="agreementFirstNameDirector"
          initialValue={application.agreementFirstNameDirector}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Отчество Руководителя организации-грантополучателя (при наличии)</span>
        <Form.Item
          name="agreementMiddleNameDirector"
          initialValue={application.agreementMiddleNameDirector}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Должность Руководителя организации-грантополучателя</span>
        <Form.Item
          name="agreementPositionDirector"
          initialValue={application.agreementPositionDirector}
        >
          <Input disabled={isFormDisabled} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px' }}>1. Информация о достигнутых научно-практических результатах Проекта</div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">1. Цель Проекта</span>
        <Form.Item
          name="annotationProjectGoal"
          initialValue={application.annotationProjectGoal}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Заполняется автоматически" />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">1.1. Была ли в ходе работ достигнута цель Проекта?</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="annotationProjectGoalAchieved"
          initialValue={application.annotationProjectGoalAchieved}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            disabled={isFormDisabled}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">2. Краткая аннотация результатов выполненных работ</span>
        <span style={{ fontStyle: 'italic' }}>Кратко подведите итог выполненных работ, на основании которого был сделан вывод о достижении / недостижении Цели Проекта.</span>
        <span style={{ fontStyle: 'italic' }}>Форма изложения должна дать возможность сделать выводы о достигнутых результатах и оценить степень выполнения цели Проекта.</span>
        <Form.Item
          name="projectShortAnnotation"
          initialValue={application.projectShortAnnotation}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
        </Form.Item>
      </div>
      <div style={{ padding: '0px 20px' }}>
        <span style={{ fontStyle: 'italic' }}>При необходимости представления дополнительных, в том числе иллюстрационных (таблиц, диаграмм и т.п.), материалов по Проекту загрузите файлы</span>
        <Dragger
          showUploadList={false}
          onChange={(e) => onUploadFile(e, 'projectShortAnnotationFiles')}
          disabled={isFormDisabled}
          multiple
          action={null}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">При необходимости представления дополнительных материалов по Проекту загрузите файлы (не более 15мб)</p>
        </Dragger>
        {projectShortAnnotationFiles.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile(f, 'projectShortAnnotationFiles')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div>
      <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px', marginTop: '20px' }}>3. Результаты, полученные членами коллектива специалистов по итогам реализации Проекта</div>
      {Array(application.maxStepsNumber).fill(0).map((e, i) => (
        <div style={{ display: 'flex', flexDirection: 'column' }} key={i}>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">{`3.${i + 1}.1 Ожидаемые результаты, запланированные к получению в рамках ${i + 1} этапа реализации Проекта (в соответствии с Соглашением о предоставлении гранта)`}</span>
            <Form.Item
              name={`resultsStep${i + 1}`}
              initialValue={application[`resultsStep${i + 1}`]}
            >
              <TextArea disabled={disabled} autoSize={{ minRows: 2 }} placeholder="Заполняется автоматически" />
            </Form.Item>
          </div>
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">{`3.${i + 1}.2 Результаты по ${i + 1} этапу были достигнуты в полном объеме`}</span>
            <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
            <Form.Item
              name={`resultsStep${i + 1}Achieved`}
              initialValue={application[`resultsStep${i + 1}Achieved`]}
            >
              <Radio.Group
                options={[
                  { label: 'Да', value: 'Да' },
                  { label: 'Нет', value: 'Нет' },
                ]}
                disabled={isFormDisabled}
                onChange={(v) => setSelectedValues({ ...selectedValues, [`resultsStep${i + 1}Achieved`]: v.target.value })}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </div>
          {selectedValues[`resultsStep${i + 1}Achieved`] === 'Да' && (
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">{`3.${i + 1}.3 Краткое описание полученных на ${i + 1} этапе результатов`}</span>
            <Form.Item
              name={`resultsStep${i + 1}Description`}
              initialValue={application[`resultsStep${i + 1}Description`]}
            >
              <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Приведите краткое описание полученных на данном этапе результатов работ и обоснуйте их выполнение в полном объеме. Минимальный объем - 1000 знаков (с пробелами)." />
            </Form.Item>
          </div>
          )}
          {selectedValues[`resultsStep${i + 1}Achieved`] === 'Нет' && (
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">{`3.${i + 1}.4 Обоснование неполучения в полном объеме результатов, запланированных к получению на ${i + 1} этапе работ`}</span>
            <Form.Item
              name={`resultsStep${i + 1}Reason`}
              initialValue={application[`resultsStep${i + 1}Reason`]}
            >
              <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Приведите краткое описание полученных на данном этапе результатов работ и обоснуйте их невыполнение в полном объеме. Минимальный объем - 1000 знаков (с пробелами)." />
            </Form.Item>
          </div>
          )}
        </div>
      ))}

      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">3.4 Сведения о достигнутых конкретных научно-практических результатах</span>
        <Form.Item
          name="projectResultsDescription"
          initialValue={application.projectResultsDescription}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Приведите описание конкретных результатов, достигнутых по итогу реализации проекта. Минимальный объем - 2000 знаков (с пробелами)." />
        </Form.Item>
      </div>

      <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px' }}>4. Внедрение результатов Проекта в практическую деятельность</div>
      <div className="application-main-box-content-wrapper">
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="integrationResults"
          initialValue={application.integrationResults}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            disabled={isFormDisabled}
            onChange={(v) => setSelectedValues({ ...selectedValues, integrationResults: v.target.value })}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      </div>
      {selectedValues.integrationResults === 'Да' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">4.1. Формат внедрения</span>
        <Form.Item
          name="integrationResultsFormat"
          initialValue={application.integrationResultsFormat}
        >
          <TextArea
            disabled={isFormDisabled}
            autoSize={{ minRows: 2 }}
            placeholder="Приведите краткое описание внедрения результатов Проекта в практическую деятельность.
          Примеры описания внедрения:
          - Методика внедрена в работу МО ДЗМ
          - Программный продукт разработан и используется в МО ДЗМ
          - Получено регистрационное удостоверение в Росздравнадзоре на прибор/тест-систему
          - Прибор введен в эксплуатацию в МО ДЗМ
          - и т.д."
          />
        </Form.Item>
      </div>
      )}
      {selectedValues.integrationResults === 'Да' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">4.2. Подробная информация о внедрении результатов Проекта в практическую деятельность</span>
        <Form.Item
          name="integrationResultsDetailed"
          initialValue={application.integrationResultsDetailed}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Подробная информация об успешном опыте внедрения результатов Проекта в практическую деятельность (сроки внедрения, масштаб внедрения - количество медицинских организаций, количество пользователей, и другие имеющиеся особенности)" />
        </Form.Item>
      </div>
      )}
      {selectedValues.integrationResults === 'Нет' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">4.3. Обоснование невнедрения результатов работ по Проекту в практическую деятельность</span>
        <Form.Item
          name="integrationResultsReason"
          initialValue={application.integrationResultsReason}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Привести обоснование невнедрения результатов работ по Проекту в практическую деятельность" />
        </Form.Item>
      </div>
      )}
      {selectedValues.integrationResults !== '' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">4.4. Действия, необходимые для внедрения / масштабирования внедрения результатов работ по Проекту в практическую деятельность</span>
        <span style={{ fontStyle: 'italic' }}>
          Привести описание действий, необходимых для внедрения / масштабирования внедрения результатов работ по Проекту в практическую деятельность
          Примеры описания действий, необходимых для внедрения:
          - проведение доклинических испытаний прибора/тест-системы
          - проведение клинических испытаний прибора/тест-системы
          - получение регистрационного удостоверения на прибор/тест-систему
          - регистрация программного обеспечения в реестре российского ПО
          - интеграция программного обеспечения с ЕМИАС
          - внедрение в клинические рекомендации
          - и т.д.

          Пример заполнения поля:
          В ходе валидации должна быть установлена способность тест-системы правильно дифференцировать больных (положительныи‌ результат) от здоровых (отрицательныи‌ результат).
          Для данного исследования планируется валидация тест-системы путем слепого мультицентрового тестирования выборки клинических и контрольных образцов. Тестирование образцов ДНК, включая приготовление библиотек, секвенирование и анализ данных, планируется провести в трех независимых референсных лабораториях.
          По результатам валидации тест-системы будет принято решение для проведения дополнительных исследований для получения регистрационного удостоверения и заведения тест-системы в клинические рекомендации
        </span>
        <Form.Item
          name="integrationResultsExpand"
          initialValue={application.integrationResultsExpand}
        >
          <TextArea
            disabled={isFormDisabled}
            autoSize={{ minRows: 2 }}
          />
        </Form.Item>
      </div>
      )}
      {!isHideFinancialReport && !application.isGrants2023 && <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px' }}>5. Соблюдение сроков сдачи отчетности по этапам Проекта</div>}
      {!isHideFinancialReport && !application.isGrants2023 && (
      <div className="application-main-box-content-wrapper" style={{ padding: 0 }}>
        {/* <span className="application-main-box-label">20. Соблюдение сроков сдачи отчетности по этапам Проекта</span> */}
        {steps.map((_, index) => (
          <div className="application-main-box-content-wrapper">
            <span className="application-main-box-label">{`${index + 1} Этап`}</span>
            {index === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
              <span style={{ textDecoration: 'underline' }}>Аннотированный отчет</span>
              {getUserRole() === 'bigAdmin' && <span>{`Срок подачи отчета в соответствии с Соглашением: ${stepsCatalogue[index].ano.format('DD.MM.YYYY')}`}</span>}
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>Дата фактической подачи отчета:</div>
                <Form.Item
                  name={`step${index + 1}ReportDateAnnotation`}
                  initialValue={application[`step${index + 1}ReportDateAnnotation`]}
                  style={{ margin: '0px 5px' }}
                >
                  <DatePicker disabled={isFormDisabled || getUserRole() !== 'bigAdmin'} placeholder="Дата" onChange={(e) => onChangeStepReportDate(e, `step${index + 1}DeviationAnnotation`, index, 'ano')} />
                </Form.Item>
                <div>{`Отклонение ${deviations[`step${index + 1}DeviationAnnotation`] ?? ''} дней`}</div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                {deviations[`step${index + 1}DeviationAnnotation`] > 0
              && (<span>{`Укажите причину несвоевременной подачи Аннотированного отчета по ${index + 1} Этапу.`}</span>)}
                <Form.Item
                  hidden={deviations[`step${index + 1}DeviationAnnotation`] === 0}
                  name={`step${index + 1}ReportCommentAnnotation`}
                  initialValue={application[`step${index + 1}ReportCommentAnnotation`]}
                >
                  <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
                </Form.Item>
              </div>
            </div>
            )}

            <div style={{ textDecoration: 'underline', marginTop: '0px' }}>Финансовый отчет</div>
            {getUserRole() === 'bigAdmin' && <span>{`Срок подачи отчета в соответствии с Соглашением: ${stepsCatalogue[index].fin?.format('DD.MM.YYYY')}`}</span>}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>Дата фактической подачи отчета:</div>
              <Form.Item
                name={`step${index + 1}ReportDateFinance`}
                initialValue={application[`step${index + 1}ReportDateFinance`]}
                style={{ margin: '0px 5px' }}
              >
                <DatePicker disabled={isFormDisabled || getUserRole() !== 'bigAdmin'} placeholder="Дата" onChange={(e) => onChangeStepReportDate(e, `step${index + 1}DeviationFinance`, index, 'fin')} />
              </Form.Item>
              <div>{`Отклонение ${deviations[`step${index + 1}DeviationFinance`] ?? ''} дней`}</div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              {deviations[`step${index + 1}DeviationFinance`] > 0
              && (<span>{`Укажите причину несвоевременной подачи Финансового отчета по ${index + 1} Этапу.`}</span>
              )}
              <Form.Item
                hidden={deviations[`step${index + 1}DeviationFinance`] === 0}
                name={`step${index + 1}ReportCommentFinance`}
                initialValue={application[`step${index + 1}ReportCommentFinance`]}
              >
                <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
              </Form.Item>
            </div>
          </div>
        ))}
      </div>
      )}

      <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px' }}>{`${isHideFinancialReport ? '5' : '6'}. Информация о внесении изменений в соглашение о предоставлении гранта`}</div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">{`${isHideFinancialReport ? '5.1' : '6.1'}. Изменение срока реализации Проекта`}</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="projectTermChange"
          initialValue={application.projectTermChange}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            disabled={isFormDisabled}
            onChange={(v) => setSelectedValues({ ...selectedValues, projectTermChange: v.target.value })}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        {selectedValues.projectTermChange === 'Да' && (
        <Form.Item
          style={{ marginTop: '10px' }}
          name="projectTermChangeReason"
          initialValue={application.projectTermChangeReason}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Привести обоснование продления срока выполнения Проекта" />
        </Form.Item>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">{`${isHideFinancialReport ? '5.2' : '6.2'}. Изменение Плана-графика работ по Проекту`}</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="projectPlanChange"
          initialValue={application.projectPlanChange}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            disabled={isFormDisabled}
            onChange={(v) => setSelectedValues({ ...selectedValues, projectPlanChange: v.target.value })}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        {selectedValues.projectPlanChange === 'Да' && (
        <Form.Item
          style={{ marginTop: '10px' }}
          name="projectPlanChangeReason"
          initialValue={application.projectPlanChangeReason}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Привести обоснование изменения Плана-графика работ по Проекту" />
        </Form.Item>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">{`${isHideFinancialReport ? '5.3' : '6.3'}. Добавление / исключение соисполнителей работ по гранту и исполнителей научно-исследовательских работ в Соглашение о предоставлении гранта`}</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="projectPeopleChange"
          initialValue={application.projectPeopleChange}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            disabled={isFormDisabled}
            onChange={(v) => setSelectedValues({ ...selectedValues, projectPeopleChange: v.target.value })}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        {selectedValues.projectPeopleChange === 'Да' && (
        <Form.Item
          style={{ marginTop: '10px' }}
          name="projectPeopleChangeReason"
          initialValue={application.projectPeopleChangeReason}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} placeholder="Привести обоснование изменения состава соисполнителей / исполнителей научно-исследовательских работ в Соглашении о предоставлении гранта" />
        </Form.Item>
        )}
      </div>
      <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px' }}>{`${isHideFinancialReport ? '6' : '7'}. Информация о достигнутых показателях эффективности реализации проекта`}</div>
      {/* <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">12. Задачи Проекта (в соответствии с Соглашением)</span>
        <SectionSevenTable values={application.stepSevenRows} disabled={isFormDisabled} />
      </div> */}
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Число публикаций по результатам реализации Проекта</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectPublicationsPlan"
          initialValue={application.projectPublicationsPlan}
        >
          <InputNumber disabled={disabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPublicationsPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectPublicationsFact"
          initialValue={application.projectPublicationsFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPublicationsFact', e)} />
        </Form.Item>
        {selectedValues.projectPublicationsFact !== null && selectedValues.projectPublicationsFact < selectedValues.projectPublicationsPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectPublicationsReason"
            initialValue={application.projectPublicationsReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">{`в научных журналах перечня ВАК с Импакт-фактором ${'>'} 0,5 (на момент публикации материала)`}</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectVACPlan"
          initialValue={application.projectVACPlan}
        >
          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" style={{ width: '100%' }} onChange={(e) => onChangeFact('projectVACPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectVACFact"
          initialValue={application.projectVACFact}
        >
          <InputNumber disabled={disabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectVACFact', e)} />
        </Form.Item>
        {selectedValues.projectVACFact !== null && selectedValues.projectVACFact < selectedValues.projectVACPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectVACReason"
            initialValue={application.projectVACReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
        <ReportPublicationsModule
          publications={application.projectVACPublications_ids}
          applicationId={application.id}
          fieldToSave="projectVACPublications_ids"
          onChangeAmount={(amount) => onChangeFactObjects(amount, 'projectVACFact')}
          label="Научные статьи по теме Проекта в журналах перечня ВАК с Импакт-фактором > 0,5"
        />
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">в научных журналах, индексируемых РИНЦ</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectRINCPlan"
          initialValue={application.projectRINCPlan}
        >
          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" style={{ width: '100%' }} onChange={(e) => onChangeFact('projectRINCPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectRINCFact"
          initialValue={application.projectRINCFact}
        >
          <InputNumber disabled={disabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectRINCFact', e)} />
        </Form.Item>
        {selectedValues.projectRINCFact !== null && selectedValues.projectRINCFact < selectedValues.projectRINCPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectRINCReason"
            initialValue={application.projectRINCReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
        <ReportPublicationsModule
          publications={application.projectRINCPublications_ids}
          applicationId={application.id}
          fieldToSave="projectRINCPublications_ids"
          onChangeAmount={(amount) => onChangeFactObjects(amount, 'projectRINCFact')}
          label="Научные статьи по теме Проекта в журналах, индексируемых РИНЦ"
        />
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Число результатов интеллектуальной деятельности, полученных по результатам реализации Проекта</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectRIDPlan"
          initialValue={application.projectRIDPlan}
        >
          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" style={{ width: '100%' }} onChange={(e) => onChangeFact('projectRIDPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectRIDFact"
          initialValue={application.projectRIDFact}
        >
          <InputNumber disabled={disabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectRIDFact', e)} />
        </Form.Item>
        {selectedValues.projectRIDFact !== null && selectedValues.projectRIDFact < selectedValues.projectRIDPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectRIDReason"
            initialValue={application.projectRIDReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
        <ReportRidModule
          publications={application.projectRID_ids}
          applicationId={application.id}
          fieldToSave="projectRID_ids"
          onChangeAmount={(amount) => onChangeFactObjects(amount, 'projectRIDFact')}
          label="Результаты интеллектуальной деятельности"
        />
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Количество мероприятий по демонстрации результатов реализации Проекта</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectEventsDemonstrationPlan"
          initialValue={application.projectEventsDemonstrationPlan}
        >
          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" style={{ width: '100%' }} onChange={(e) => onChangeFact('projectEventsDemonstrationPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectEventsDemonstrationFact"
          initialValue={application.projectEventsDemonstrationFact}
        >
          <InputNumber disabled={disabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectEventsDemonstrationFact', e)} />
        </Form.Item>
        {selectedValues.projectEventsDemonstrationFact !== null && selectedValues.projectEventsDemonstrationFact < selectedValues.projectEventsDemonstrationPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectEventsDemonstrationReason"
            initialValue={application.projectEventsDemonstrationReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <ReportEventsModule
        publications={application.projectEvents_ids}
        applicationId={application.id}
        fieldToSave="projectEvents_ids"
        onChangeAmount={(amount) => onChangeFactObjects(amount, 'projectEventsDemonstrationFact')}
        label="Научные мероприятия"
      />
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectAudiencePlan"
          initialValue={application.projectAudiencePlan}
        >
          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" style={{ width: '100%' }} onChange={(e) => onChangeFact('projectAudiencePlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectAudienceFact"
          initialValue={application.projectAudienceFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectAudienceFact', e)} />
        </Form.Item>
        {selectedValues.projectAudienceFact !== null && selectedValues.projectAudienceFact < selectedValues.projectAudiencePlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectAudienceReason"
            initialValue={application.projectAudienceReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Количество выступлений с докладами на международных научно-практических конференциях, симпозиумах, форумах</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectEventsPlan"
          initialValue={application.projectEventsPlan}
        >
          <InputNumber disabled={disabled} placeholder="Заполняется автоматически" style={{ width: '100%' }} onChange={(e) => onChangeFact('projectEventsPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectEventsFact"
          initialValue={application.projectEventsFact}
        >
          <InputNumber disabled={disabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectEventsFact', e)} />
        </Form.Item>
        {selectedValues.projectEventsFact !== null && selectedValues.projectEventsFact < selectedValues.projectEventsPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectEventsReason"
            initialValue={application.projectEventsReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <ReportEventsModule
        publications={application.projectInternationalEvents_ids}
        applicationId={application.id}
        fieldToSave="projectInternationalEvents_ids"
        onChangeAmount={(amount) => onChangeFactObjects(amount, 'projectEventsFact')}
        label="Международные научные мероприятия"
      />

      <div className="application-main-box-label" style={{ paddingLeft: '20px', fontSize: '18px' }}>8. Эффективность исполнения работ в рамках научно-практического Проекта в рамках грантовой поддержки</div>

      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Общее количество пациентов, предусмотренных для выполнения Проекта</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectPatientsPlan"
          initialValue={application.projectPatientsPlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectPatientsFact"
          initialValue={application.projectPatientsFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsFact', e)} />
        </Form.Item>
        {selectedValues.projectPatientsFact !== null && selectedValues.projectPatientsFact < selectedValues.projectPatientsPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectPatientsReason"
            initialValue={application.projectPatientsReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Пациенты основной группы</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectPatientsMainPlan"
          initialValue={application.projectPatientsMainPlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsMainPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectPatientsMainFact"
          initialValue={application.projectPatientsMainFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsMainFact', e)} />
        </Form.Item>
        {selectedValues.projectPatientsMainFact !== null && selectedValues.projectPatientsMainFact < selectedValues.projectPatientsMainPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectPatientsMainReason"
            initialValue={application.projectPatientsMainReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Пациенты контрольной группы</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectPatientsControlPlan"
          initialValue={application.projectPatientsControlPlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsControlPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectPatientsControlFact"
          initialValue={application.projectPatientsControlFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsControlFact', e)} />
        </Form.Item>
        {selectedValues.projectPatientsControlFact !== null && selectedValues.projectPatientsControlFact < selectedValues.projectPatientsControlPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectPatientsControlReason"
            initialValue={application.projectPatientsControlReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Пациенты группы сравнения</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectPatientsComparePlan"
          initialValue={application.projectPatientsComparePlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsComparePlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectPatientsCompareFact"
          initialValue={application.projectPatientsCompareFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectPatientsCompareFact', e)} />
        </Form.Item>
        {selectedValues.projectPatientsCompareFact !== null && selectedValues.projectPatientsCompareFact < selectedValues.projectPatientsComparePlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectPatientsCompareReason"
            initialValue={application.projectPatientsCompareReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Акты и протоколы испытаний (если таковые предусмотрены Проектом)</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectActsPlan"
          initialValue={application.projectActsPlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectActsPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectActsFact"
          initialValue={application.projectActsFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectActsFact', e)} />
        </Form.Item>
        {selectedValues.projectActsFact !== null && selectedValues.projectActsFact < selectedValues.projectActsPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectActsReason"
            initialValue={application.projectActsReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
        <span style={{ fontStyle: 'italic' }}>Загрузите разработанные акты и протоколы испытаний</span>
        <Dragger
          showUploadList={false}
          onChange={(e) => onUploadFile(e, 'projectActsFiles_ids')}
          disabled={isFormDisabled}
          multiple
          action={null}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб)</p>
        </Dragger>
        {projectActsFiles_ids.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile(f, 'projectActsFiles_ids')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div>
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Протоколы исследований регистрировались в реестрах/базах клинических исследований?</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="projectProtocols"
          initialValue={application.projectProtocols}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            onChange={(e) => setIsProjectProtocols(e.target.value)}
            disabled={isFormDisabled}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      </div>
      <div style={{ padding: '0px 20px' }}>
        <span>Планируемое</span>
        <Form.Item
          name="projectProtocolsPlan"
          initialValue={application.projectProtocolsPlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectProtocolsPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectProtocolsFact"
          initialValue={application.projectProtocolsFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectProtocolsFact', e)} />
        </Form.Item>
        {selectedValues.projectProtocolsFact !== null && selectedValues.projectProtocolsFact < selectedValues.projectProtocolsPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectProtocolsReason"
            initialValue={application.projectProtocolsReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>

      {isProjectProtocols === 'Да' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Перечислите названия зарегистрированных протоколов, названия реестров/баз, ID Протоколов</span>
        <Form.Item
          name="projectProtocolsNames"
          initialValue={application.projectProtocolsNames}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
        </Form.Item>
      </div>
      )}
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Наличие опытных образцов (если таковые предусмотрены Проектом)</span>
        <span>Планируемое</span>
        <Form.Item
          name="projectExamplesPlan"
          initialValue={application.projectExamplesPlan}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectExamplesPlan', e)} />
        </Form.Item>
        <span>Фактическое</span>
        <Form.Item
          name="projectExamplesFact"
          initialValue={application.projectExamplesFact}
        >
          <InputNumber disabled={isFormDisabled} style={{ width: '100%' }} onChange={(e) => onChangeFact('projectExamplesFact', e)} />
        </Form.Item>
        {selectedValues.projectExamplesFact !== null && selectedValues.projectExamplesFact < selectedValues.projectExamplesPlan && (
        <div>
          <span>Обоснование невыполнения плана</span>
          <Form.Item
            name="projectExamplesReason"
            initialValue={application.projectExamplesReason}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
        )}
      </div>
      {/* <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">18. Сведения о достигнутых конкретных научных результатах</span>
        <span>Опишите достигнутые результаты. Минимальный объем символов - 5000</span>
        <Form.Item
          name="projectResultsDescription"
          initialValue={application.projectResultsDescription}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
        </Form.Item>
        <span style={{ fontStyle: 'italic' }}>При необходимости представления дополнительных материалов по проекту загрузите файлы</span>
        <Dragger
          showUploadList={false}
          onChange={(e) => onUploadFile(e, 'projectResultsDescriptionFiles_ids')}
          disabled={isFormDisabled}
          multiple
          action={null}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб)</p>
        </Dragger>
        {projectResultsDescriptionFiles_ids.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile(f, 'projectResultsDescriptionFiles_ids')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div> */}
      {/* <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">18. Все запланированные в рамках реализации Проекта научные результаты достигнуты?</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="projectResultsAchieved"
          initialValue={application.projectResultsAchieved}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            onChange={(e) => setIsProjectResultsAchieved(e.target.value)}
            disabled={isFormDisabled}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        {isProjectResultsAchieved === 'Нет'
         && (
         <div className="application-main-box-content-wrapper">
           <span className="application-main-box-label">Приведите описание работ из Плана-графика реализации проекта, которые не были выполнены, с подробным пояснением о приведших к неисполнению обстоятельствах</span>
           <Form.Item
             name="projectResultsAchievedReason"
             initialValue={application.projectResultsAchievedReason}
           >
             <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
           </Form.Item>
         </div>
         )}
        <div className="application-main-box-content-wrapper">
          <span className="application-main-box-label">Укажите фактический % выполнения работ по Проекту</span>
          <Form.Item
            name="projectResultsAchievedFact"
            initialValue={application.projectResultsAchievedFact}
          >
            <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
          </Form.Item>
        </div>
      </div> */}
      {/* <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">19. Внедрены ли полученные в рамках реализации Проекта результаты в практическую деятельность?</span>
        <span style={{ fontStyle: 'italic' }}>Выберите вариант ответа</span>
        <Form.Item
          name="projectResultsExecuted"
          initialValue={application.projectResultsExecuted}
        >
          <Radio.Group
            options={[
              { label: 'Да', value: 'Да' },
              { label: 'Нет', value: 'Нет' },
            ]}
            onChange={(e) => setIsProjectResultsExecuted(e.target.value)}
            disabled={isFormDisabled}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      </div> */}
      {/* {isProjectResultsExecuted === 'Да' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Выберите формат внедрения</span>
        <Form.Item
          name="projectResultsExecutedType"
          initialValue={application.projectResultsExecutedType}
        >
          <Select
            disabled={isFormDisabled}
          >
            {list.map((u) => <Option key={u} value={u}>{u}</Option>)}
          </Select>
        </Form.Item>
      </div>
      )} */}
      {/* {isProjectResultsExecuted === 'Да' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Укажите подробную информацию о внедрении результатов Проекта в практическую деятельность (сроки внедрения, количество пользователей и другие имеющиеся особенности)</span>
        <Form.Item
          name="projectResultsExecutedDescription"
          initialValue={application.projectResultsExecutedDescription}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
        </Form.Item>
      </div>
      )}
      {isProjectResultsExecuted === 'Нет' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Укажите причины не внедрения результатов работ по Проекту в практическую деятельность</span>
        <Form.Item
          name="projectResultsExecutedReasons"
          initialValue={application.projectResultsExecutedReasons}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
        </Form.Item>
      </div>
      )}
      {isProjectResultsExecuted === 'Нет' && (
      <div className="application-main-box-content-wrapper">
        <span className="application-main-box-label">Опишите, что необходимо для внедрения результатов работ по Проекту в практическую деятельность</span>
        <Form.Item
          name="projectResultsExecutedRequired"
          initialValue={application.projectResultsExecutedRequired}
        >
          <TextArea disabled={isFormDisabled} autoSize={{ minRows: 2 }} />
        </Form.Item>
      </div>
      )} */}
    </Col>
  );
}

NewAnnotationReport.propTypes = {
  application: PropTypes.shape().isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  projectActsFiles_ids: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // projectResultsDescriptionFiles_ids: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectShortAnnotationFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  annotationFormRef: PropTypes.shape().isRequired,
};

export default NewAnnotationReport;
