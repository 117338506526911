/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Row, Col, Popconfirm, Button,
} from 'antd';
import getUserRole from '../../../../../../../utils/localeStorage/getUserRole';


const getMaterialsColumns = (onEditClick, onDeleteClick, onAddClick, isPaper, isHideAdd) => {
  const columns = [
    {
      title: 'Наименование расходов',
      dataIndex: 'name',
      key: 'name',
      render: (text, object) => (object.isAdd && !isHideAdd ? <Button type="primary" onClick={onAddClick}>Добавить</Button> : text),
    },
    {
      title: 'Исполнение гранта',
      children: [
        {
          title: 'Фактические расходы',
          dataIndex: 'actualCosts',
          key: 'actualCosts',
          render: (_, object) => (object.isAdd ? '' : <div>{`${object.actualCostsMajor.toString().length === 1 ? `0${object.actualCostsMajor}` : object.actualCostsMajor} руб. ${object.actualCostsMinor.toString().length === 1 ? `0${object.actualCostsMinor}` : object.actualCostsMinor} коп. `}</div>),
        },
        {
          title: 'Кассовые расходы',
          dataIndex: 'paperCosts',
          key: 'paperCosts',
          render: (_, object) => (object.isAdd ? '' : <div>{`${object.paperCostsMajor.toString().length === 1 ? `0${object.paperCostsMajor}` : object.paperCostsMajor} руб. ${object.paperCostsMinor.toString().length === 1 ? `0${object.paperCostsMinor}` : object.paperCostsMinor} коп. `}</div>),
        },
        {
          title: 'Отклонение',
          dataIndex: 'deviations',
          key: 'deviations',
          render: (_, object) => (object.isAdd ? '' : <div>{`${object.deviationsMajor.toString().length === 1 ? `0${object.deviationsMajor}` : object.deviationsMajor} руб. ${object.deviationsMinor.toString().length === 1 ? `0${object.deviationsMinor}` : object.deviationsMinor} коп. `}</div>),
        },
      ],
    },
    {
      title: 'Документы, подтверждающие расходы',
      dataIndex: 'docs',
      key: 'docs',
      render: (_, object) => {
        if (object.isAdd || object.isTotal) {
          return '';
        }
        const docsArray = [];
        if (object.materialContract) {
          docsArray.push({ name: `Договор (${object.materialContract.contractor}, №${object.materialContract.name} от ${object.materialContract.date?.format('DD.MM.YYYY')})`, urlLink: object.materialContract.file?.urlLink });
        }
        if (object.materialPP && object.materialPP.length > 0) {
          docsArray.push(...object.materialPP.map((p) => ({ name: `ПП (№${p.name} от ${p.date?.format('DD.MM.YYYY')})`, urlLink: p.file?.urlLink })));
        }
        if (object.materialDS && object.materialDS.length > 0) {
          docsArray.push(...object.materialDS.map((p) => ({ name: `ДС (№${p.name} от ${p.date?.format('DD.MM.YYYY')})`, urlLink: p.file?.urlLink })));
        }
        if (object.materialAct) {
          docsArray.push({ name: `Акт (№${object.materialAct.name} от ${object.materialAct.date?.format('DD.MM.YYYY')})`, urlLink: object.materialAct.file?.urlLink });
        }
        if (object.materialYPD) {
          docsArray.push({ name: `УПД (№${object.materialYPD.name} от ${object.materialYPD.date?.format('DD.MM.YYYY')})`, urlLink: object.materialYPD.file?.urlLink });
        }
        if (object.materialTN) {
          docsArray.push({ name: `ТН (№${object.materialTN.name} от ${object.materialTN.date?.format('DD.MM.YYYY')})`, urlLink: object.materialTN.file?.urlLink });
        }
        if (object.materialTovN) {
          docsArray.push({ name: `Тов. Накладная (№${object.materialTovN.name} от ${object.materialTovN.date?.format('DD.MM.YYYY')})`, urlLink: object.materialTovN.file?.urlLink });
        }
        if (object.materialSF) {
          docsArray.push({ name: `СФ (№${object.materialSF.name} от ${object.materialSF.date?.format('DD.MM.YYYY')})`, urlLink: object.materialSF.file?.urlLink });
        }

        if (getUserRole() === 'bigAdmin') {
          return (
            <Col>
              {docsArray.map((d) => <Row wrap="false"><a href={d.urlLink} target="_blank" rel="noreferrer">{d.name}</a></Row>)}
            </Col>
          );
        }
        return (
          <Col>
            {docsArray.map((d) => <Row wrap="false">{d.name}</Row>)}
          </Col>
        );
      },
    },
    {
      title: 'Кол-во листов',
      dataIndex: 'numberOfPages',
      key: 'numberOfPages',
      render: (text, object) => {
        if (object.isAdd) {
          return '';
        }
        if (object.isTotal) {
          return text;
        }
        const docsArray = [object.materialContract?.pages, ...object.materialPP.map((p) => p.pages)];
        if (object.materialDS && object.materialDS.length > 0) {
          docsArray.push(...object.materialDS.map((p) => p.pages));
        }
        if (object.materialAct) {
          docsArray.push(object.materialAct.pages);
        }
        if (object.materialYPD) {
          docsArray.push(object.materialYPD.pages);
        }
        if (object.materialTN) {
          docsArray.push(object.materialTN.pages);
        }
        if (object.materialTovN) {
          docsArray.push(object.materialTovN.pages);
        }
        if (object.materialSF) {
          docsArray.push(object.materialSF.pages);
        }

        return (
          <Col>
            {docsArray.map((d) => <Row wrap="false">{d}</Row>)}
          </Col>
        );
      },
    },
    {
      title: 'Комментарии',
      dataIndex: 'comments',
      key: 'comments',
      render: (_, object) => {
        if (object.isAdd || object.isTotal) {
          return '';
        }
        const docsArray = [object.materialContract?.comment, ...object.materialPP.map((p) => p.comment)];
        if (object.materialDS && object.materialDS.length > 0) {
          docsArray.push(...object.materialDS.map((p) => p.comment));
        }
        if (object.materialAct) {
          docsArray.push(object.materialAct.comment);
        }
        if (object.materialYPD) {
          docsArray.push(object.materialYPD.comment);
        }
        if (object.materialTN) {
          docsArray.push(object.materialTN.comment);
        }
        if (object.materialTovN) {
          docsArray.push(object.materialTovN.comment);
        }
        if (object.materialSF) {
          docsArray.push(object.materialSF.comment);
        }

        return (
          <Col>
            {docsArray.map((d) => <Row wrap="false">{d}</Row>)}
          </Col>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
      render: (_, object) => (
        <div>
          {!object.isTotal && !object.isAdd && !isHideAdd && (
          <Col gutter={5}>
            <Row style={{ cursor: 'pointer' }}><Button type="link" style={{ padding: 0 }} onClick={() => onEditClick(object)}>изменить</Button></Row>
            <Row style={{ cursor: 'pointer' }}>
              <Popconfirm
                title="Вы уверены, что хотите удалить?"
                onConfirm={() => onDeleteClick(object)}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="link" style={{ padding: 0 }}>удалить</Button>
              </Popconfirm>
            </Row>
          </Col>
          )}
        </div>
      ),
    },
  ];

  if (isPaper) {
    columns[1].children.push({
      title: 'За счет средств гранта, выделенных на выполнение задания',
      dataIndex: 'grantSum',
      key: 'grantSum',
      render: (_, object) => (object.isAdd ? '' : <div>{`${object.grantSumMajor.toString().length === 1 ? `0${object.grantSumMajor}` : object.grantSumMajor} руб. ${object.grantSumMinor.toString().length === 1 ? `0${object.grantSumMinor}` : object.grantSumMinor} коп. `}</div>),
    });
  }
  return columns;
};

export default getMaterialsColumns;
