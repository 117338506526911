/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, Input, InputNumber } from 'antd';

const years = ['2023', '2024', '2025'];
const { TextArea } = Input;
const getPlanRow = (index, applicationProp, disabled, total, agreementData) => {
  const documents = () => {
    switch (total) {
      case 1:
        switch (index) {
          case 1:
            return `
                Финансовый отчет<br>
                Аннотированный отчет о достижении значений результатов предоставления гранта на реализацию научно-практического проекта<br>
                Итоговый отчет о реализации научно-практического проекта в сфере медицины<br>
                Акт сдачи-приемки выполненных по Проекту работ
              `;
        }

      case 2:
        switch (index) {
          case 1:
            return `
                Финансовый отчет<br>
                Аннотированный отчет о достижении значений результатов предоставления гранта на реализацию научно-практического проекта
              `;
          case 2:
            return `
                 Финансовый отчет<br>
                Аннотированный отчет о достижении значений результатов предоставления гранта на реализацию научно-практического проекта<br>
                Итоговый отчет о реализации научно-практического проекта в сфере медицины<br>
                Акт сдачи-приемки выполненных по Проекту работ
              `;
        }

      case 3:
        switch (index) {
          case 1:
            return `
                Финансовый отчет<br>
                Аннотированный отчет о достижении значений результатов предоставления гранта на реализацию научно-практического проекта
              `;
          case 2:
            return `
              Финансовый отчет<br>
              Аннотированный отчет о достижении значений результатов предоставления гранта на реализацию научно-практического проекта
            `;
          case 3:
            return `
                 Финансовый отчет<br>
                Аннотированный отчет о достижении значений результатов предоставления гранта на реализацию научно-практического проекта<br>
                Итоговый отчет о реализации научно-практического проекта в сфере медицины<br>
                Акт сдачи-приемки выполненных по Проекту работ
              `;
        }
    }
  };

  const dates = () => {
    switch (index) {
      case 1:
        return `
          С даты заключения Соглашения до 20.12.2025 года (включительно)
        `;
      case 2:
        return `С даты предоставления Грантополучателем надлежащим образом оформленных Финансового отчета и Аннотированного отчета о достижении значений результатов предоставления гранта на реализацию научно-практического проекта за 1 (Первый) этап реализации Проекта
      до 20.12.2026 года (включительно)`;

      case 3:
        return `
         С даты предоставления Грантополучателем надлежащим образом оформленных Финансового отчета и Аннотированного отчета о достижении значений результатов предоставления гранта на реализацию научно-практического проекта за 2 (Второй) этап реализации Проекта
        до 20.12.2027 года (включительно)
      `;
    }
  };

  return (
    <div className="common-table-row">
      <div
        className="common-table-row-cell"
        style={{ width: '4%', textAlign: 'center' }}
      >
        {index}
      </div>
      <div
        className="common-table-row-cell"
        style={{ width: '16%', textAlign: 'center', fontWeight: 'bold' }}
      >{`Этап ${index}`}</div>
      <Form.Item
        style={{ width: '16%' }}
        name={`etap${index}_soderzanie`}
        initialValue={
          agreementData?.[`etap${index}_soderzanie`] ||
          applicationProp[`step${index}PlanWorkDescription`]
        }
      >
        <TextArea autoSize />
      </Form.Item>
      <div
        className="common-table-row-cell"
        style={{ width: '16%', textAlign: 'center' }}
      >
        {dates()}
      </div>
      <Form.Item
        style={{ width: '16%' }}
        name={`etap${index}_result`}
        initialValue={
          agreementData?.[`etap${index}_result`] ||
          applicationProp[`step${index}PlanWorkResult`]
        }
      >
        <TextArea autoSize />
      </Form.Item>
      <div
        className="common-table-row-cell"
        style={{ width: '16%', textAlign: 'center' }}
        dangerouslySetInnerHTML={{ __html: documents() }}
      ></div>
      <div
        className="common-table-row-cell"
        style={{
          width: '16%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRight: '1px solid #d9d9d9',
        }}
      >
        <Form.Item
          style={{ width: '55%' }}
          initialValue={applicationProp[`financeTotal${years[index - 1]}Major`]}
        >
          <InputNumber
            value={applicationProp[`financeTotal${years[index - 1]}Major`]}
            disabled
          />
        </Form.Item>
        <span>руб.</span>
        <Form.Item
          style={{ width: '25%' }}
          initialValue={applicationProp[`financeTotal${years[index - 1]}Minor`]}
        >
          <InputNumber
            value={applicationProp[`financeTotal${years[index - 1]}Minor`]}
            disabled
          />
        </Form.Item>
        <span>коп.</span>
      </div>
    </div>
  );
};

export default getPlanRow;
