/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Radio, DatePicker,
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { DeleteOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { editApplicationStep1Events, editApplicationStepReport, uploadFile } from '../../../../../../utils/http';


const { TextArea } = Input;
class ReportEventsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportInEditID: null,
      publications: this.props.publications,
      applicationId: this.props.applicationId,
      material_files_field: [],
      loading: false,
      isInView: false,
    };
    this.formRef = React.createRef();
  }

  onEditClick = (p) => {
    console.log(p.material_files_field);
    this.setState({
      reportInEditID: p.id,
      material_files_field: p.material_files_field || [],
    });
  }

  onAddClick = () => {
    const { applicationId, publications } = this.state;
    const { fieldToSave } = this.props;
    editApplicationStepReport({ id: applicationId, annotationIsPresented: 'Да' });
    editApplicationStep1Events({ applicationStepReport_id: applicationId, field: fieldToSave })
      .then((res) => {
        this.props.onChangeAmount(publications.length + 1);
        this.setState((prevState) => ({
          reportInEditID: res.result[0].id,
          publications: [...prevState.publications, { id: res.result[0].id }],
        }));
      });
  }

  onDeleteClick = (id) => {
    const { publications } = this.state;
    editApplicationStep1Events({ id, isDeleted: true })
      .then(() => {
        this.props.onChangeAmount(publications.length - 1);
        this.setState((prevState) => ({
          reportInEditID: null,
          publications: prevState.publications.filter((p) => p.id !== id),
        }));
      });
  }

  onCancelClick = () => {
    this.setState({ reportInEditID: null });
  }

  onSaveClick = () => {
    const { reportInEditID, material_files_field } = this.state;
    this.setState({ loading: true });
    const values = this.formRef.current.getFieldsValue();
    const material_files = material_files_field.map((f) => f.id).join(',');
    editApplicationStep1Events({
      id: reportInEditID, ...values, material_files_field: material_files,
    })
      .then(() => {
        this.setState((prevState) => ({
          loading: false,
          reportInEditID: null,
          material_files_field: [],
          publications: prevState.publications.map((p) => (p.id === reportInEditID ? {
            id: reportInEditID, ...values, materials_files_field: material_files,
          } : p)),
        }));
        notification.success({ message: 'Успешно' });
      });
  }

  onRemoveFile = (field, file) => {
    this.setState((prevState) => ({ [field]: prevState[field]?.filter((f) => f.id !== file.id) }));
  }

  onUploadFile = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => this.setState((prevState) => ({
          [field]: [...prevState[field], file],
        })));
    }
  }

  render() {
    const {
      reportInEditID, publications, loading, isInView, material_files_field,
    } = this.state;
    const { label } = this.props;
    return (
      <div style={{
        margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
      }}
      >
        <span style={{ fontSize: '24px', color: '#212121' }}>{label}</span>
        {publications.map((p, i) => (
          <Card
            key={p.id}
            style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
            bodyStyle={{
              borderLeft: reportInEditID === p.id ? '4px solid #1890FF' : '',
              borderRadius: reportInEditID === p.id ? '5px' : '',
            }}
          >
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  {`Мероприятие №${i + 1}`}
                </Col>
                <Col>
                  <Row align="middle" gutter={10}>
                    {!reportInEditID && (<Col><Button type="primary" onClick={() => this.onEditClick(p)}>Редактировать</Button></Col>)}
                    {!reportInEditID && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить мероприятие?"
                        onConfirm={() => this.onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger">Удалить</Button>
                      </Popconfirm>
                    </Col>
                    )}
                    {!reportInEditID && (<Col><Button onClick={() => this.setState({ reportInEditID: p.id, isInView: true, material_files_field: p.material_files_field })}>Развернуть</Button></Col>)}
                    {reportInEditID === p.id && isInView && (<Col><Button onClick={() => this.setState({ reportInEditID: null, isInView: false, material_files_field: null })}>Свернуть</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button type="primary" onClick={this.onSaveClick}>Сохранить</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button onClick={this.onCancelClick}>Отменить</Button></Col>)}
                  </Row>
                </Col>
              </Row>
              {reportInEditID === p.id
                && (
                <Form
                  initialValues={{ remember: true }}
                  ref={this.formRef}
                >
                  <Col>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.1. Наименование мероприятия</span>
                      <Form.Item
                        name="name"
                        initialValue={p.name}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.2 Дата начала мероприятия</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите дату начала мероприятия, используя календарь</span>
                      <Form.Item
                        name="dateStart"
                        initialValue={p.dateStart}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.3 Дата окончания мероприятия</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите дату окончания мероприятия, используя календарь</span>
                      <Form.Item
                        name="dateEnd"
                        initialValue={p.dateEnd}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.4 Город проведения мероприятия</span>
                      <Form.Item
                        name="city"
                        initialValue={p.city}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.5 Форма представления результатов, полученных при выполнении Проекта</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Пленарный доклад, стендовый доклад, устное выступление и т.п.</span>
                      <Form.Item
                        name="resultsForm"
                        initialValue={p.resultsForm}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">В случае личного представления результатов членом научного коллектива, укажите ФИО докладчика – члена коллектива специалистов</span>
                      <Form.Item
                        name="resultsFIO"
                        initialValue={p.resultsFIO}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.6 Мероприятие является международным</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isInternational"
                        initialValue={p.isInternational}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.7 Укажите примерное количество участников мероприятия, имевших возможность ознакомиться с результатами выполнения Проекта, представленными в ходе данного мероприятия</span>
                      <Form.Item
                        name="participants"
                        initialValue={p.participants}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">Ссылка на мероприятие</span>
                      <Form.Item
                        name="url_materials"
                        initialValue={p.url_materials}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">
                        Загрузите подтверждающие материалы (скан-копия опубликованной программы и (или) тезисов, диплом участника и прочее).
                      </span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>
                        Для международных мероприятий, если статус международного мероприятия не указан в наименования мероприятия, необходимо приложить документы, подтверждающие участие миним двух участников из иностранных государств.
                      </span>
                      <Dragger
                        showUploadList={false}
                        onChange={(file) => this.onUploadFile(file, 'material_files_field')}
                        disabled={loading}
                        multiple
                        action={null}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                      </Dragger>
                      {material_files_field.map((f) => (
                        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                          {!loading && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile('material_files_field', f)} /></Col>}
                          <Col>{f.name}</Col>
                        </Row>
                      ))}
                    </div>
                  </Col>
                </Form>
                )}
            </Col>
          </Card>
        ))}
        {!reportInEditID && <Button type="primary" onClick={this.onAddClick} style={{ marginTop: '20px' }}>Добавить мероприятие</Button>}
      </div>
    );
  }
}

ReportEventsModule.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  fieldToSave: PropTypes.string,
  onChangeAmount: PropTypes.func.isRequired,
  label: PropTypes.func,
};

ReportEventsModule.defaultProps = {
  fieldToSave: '',
  label: 'Научные мероприятия',
};

export default ReportEventsModule;
