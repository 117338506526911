/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
const units = ['', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'];
const unitsPlural = ['', 'одна', 'две', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'];
const teens = ['десять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'];
const tens = ['', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'];
const hundreds = ['', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'];
const thousands = ['тысяча', 'тысячи', 'тысяч'];
const millions = ['миллион', 'миллиона', 'миллионов'];

export function numberToWords(num, type) {
  if (num === 0) return 'ноль';

  let result = '';

  // Миллионы
  const millionPart = Math.floor(num / 1000000);
  if (millionPart > 0) {
    result += numberToWords(millionPart, 'plural') + ' ' + millions[millionPart % 10 == 1 ? 0 : (millionPart % 10 >= 2 && millionPart % 10 <= 4 ? 1 : 2)] + ' ';
    num %= 1000000;
  }

  // Тысячи
  const thousandPart = Math.floor(num / 1000);
  if (thousandPart > 0) {
    result += numberToWords(thousandPart, 'plural') + ' ' + thousands[thousandPart % 10 == 1 ? 0 : (thousandPart % 10 >= 2 && thousandPart % 10 <= 4 ? 1 : 2)] + ' ';
    num %= 1000;
  }

  // Сотни
  const hundredPart = Math.floor(num / 100);
  if (hundredPart > 0) {
    result += hundreds[hundredPart] + ' ';
    num %= 100;
  }

  // Десятки и единицы
  if (num < 20) {
    if (type === 'plural') {
      result += unitsPlural[num] || teens[num - 10] + '';
    } else {
      result += units[num] || teens[num - 10] + '';
    }
  } else {
    const tensPart = Math.floor(num / 10);
    result += tens[tensPart] + ' ';
    num %= 10;
    if (num > 0) {
      if (type === 'plural') {
        result += unitsPlural[num] + '';
      } else {
        result += units[num];
      }
    }
  }

  return result.trim();
}
