/* eslint-disable no-useless-escape */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const addFilesToFolder = async (files, folder) => {
  // eslint-disable-next-line no-unused-vars
  for (const [index, fileObj] of files.entries()) {
    const file = await fetch(fileObj.file.urlLink)
      .then((r) => r.blob()).then((blobFile) => new File([blobFile], fileObj.name, { type: blobFile.type }));
    folder.file(`${fileObj.name}.pdf`, file, { base64: true });
  }
};

const exportFinanceFiles = async (application) => {
  const parentZip = new JSZip();
  const zip = parentZip.folder(`ФО_${application.displayID}_${application.stepNumber}.zip`);
  const salaryFolder = zip.folder('Оплата труда членов коллектива специалистов');
  const salaryFiles = [...application.financialSalaryFiles, ...application.financialSalaryPPFiles]
    .filter((f) => !!f).map((f) => ({ file: f, name: f.name }));
  if (salaryFiles.length > 0) {
    await addFilesToFolder(salaryFiles, salaryFolder);
  }

  const folders = [
    {
      name: 'Расходы на приобретение материалов и комплектующих',
      filterValue: 'isMaterial',
    },
    {
      name: 'Расходы на приобретение оборудования и иного имущества',
      filterValue: 'isEquipment',
    },
    {
      name: 'Прочие прямые расходы',
      filterValue: 'isOther',
    },
    {
      name: 'Накладные и общехозяйственные расходы',
      filterValue: 'isPaper',
    },
  ];

  for (const folder of folders) {
    const zipFolder = zip.folder(folder.name);
    const subFolders = application.financialCosts_ids.filter((c) => c[folder.filterValue]);
    for (const subFolder of subFolders) {
      const zipSubFolder = zipFolder.folder(subFolder.name.replace(/\/ /g, ' '));
      if (subFolder) {
        const files = [
          { file: subFolder.materialAct?.file, name: `Акт (№${subFolder.materialAct?.name} от ${subFolder.materialAct?.date?.format('DD.MM.YYYY')})` },
          { file: subFolder.materialContract?.file, name: `Договор (${subFolder.materialContract?.contractor}, №${subFolder.materialContract?.name} от ${subFolder.materialContract?.date?.format('DD.MM.YYYY')})` },
          { file: subFolder.materialSF?.file, name: `СФ (№${subFolder.materialSF?.name} от ${subFolder.materialSF?.date?.format('DD.MM.YYYY')})` },
          { file: subFolder.materialTN?.file, name: `ТН (№${subFolder.materialTN?.name} от ${subFolder.materialTN?.date?.format('DD.MM.YYYY')})` },
          { file: subFolder.materialTovN?.file, name: `Тов. Накладная (№${subFolder.materialTovN?.name} от ${subFolder.materialTovN?.date?.format('DD.MM.YYYY')})` },
          { file: subFolder.materialYPD?.file, name: `УПД (№${subFolder.materialYPD?.name} от ${subFolder.materialYPD?.date?.format('DD.MM.YYYY')})` },
          ...subFolder.materialDS.map((e, i) => ({ file: e?.file, name: `ДС (№${e.name} от ${e?.date.format('DD.MM.YYYY')}) (${i + 1})` })),
          ...subFolder.materialPP.map((e, i) => ({ file: e?.file, name: `ПП (№${e.name} от ${e?.date.format('DD.MM.YYYY')}) (${i + 1})` })),
        ].filter((f) => !!f.file);
        if (files.length > 0) {
          await addFilesToFolder(files, zipSubFolder);
        }
      }
    }
    if (folder.filterValue === 'isPaper') {
      const paperFiles = [application.financialPaperPolicyFile, application.financialPaperAccountFile]
        .filter((f) => !!f).map((f) => ({ file: f, name: f.name }));
      if (paperFiles.length > 0) {
        await addFilesToFolder(paperFiles, zipFolder);
      }
    }
  }

  parentZip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `ФО_${application.displayID}_${application.stepNumber}.zip`);
  });
};

export default exportFinanceFiles;
